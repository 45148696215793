<template>
	<div class="view-listings">
		<listings-header :breadcrumb="breadcrumb" :political-area="politicalAreaId" @import="refresh = true"/>
		<listings-legend class="mb-4" />
		<listings-table-filters v-model="filters" :political-area="politicalAreaId" />
		<listings-table
			:political-area="politicalAreaId"
			:refresh.sync="refresh"
			:page.sync="page"
			:filters="filters"
		>
			<template v-slot:actions="item">
				<base-menu offset-y nudge-bottom="15">
					<template v-slot:activator="{ on, attrs }">
						<base-button
							v-if="can('realestate:listing-activity:*.read') || can('realestate:listing-note:*.read')"
							v-bind="attrs"
							color="secondary"
							small
							text
							v-on="on"
						>
							<base-icon>mdi-dots-horizontal</base-icon>
						</base-button>
					</template>
					<base-list>
						<base-list-item
							v-if="can('location:city:*.read') && can('location:area:*.read')"
							:disabled="item.status === 'new'"
							:to="{
								name: 'listingMap',
								params: { listing: item._id }
							}"
						>Map</base-list-item>
						<base-list-item
							v-if="can('realestate:listing-activity:*.read')"
							:disabled="item.status !== 'sent'"
							:to="{
								name: 'listingActivity',
								params: { listing: item._id }
							}"
						>Activity</base-list-item>
						<base-list-item
							v-if="can('realestate:listing-note:*.read')"
							:disabled="item.status !== 'sent'"
							:to="{
								name: 'listingNotes',
								params: { listing: item._id }
							}"
						>Notes</base-list-item>
					</base-list>
				</base-menu>
				<base-button
					v-if="`realestate:listing:${item._id}.send_email`"
					color="primary"
					text
					small
					:disabled="!canSend(item)"
					:loading="sendDialog.show && sendDialog.id === item._id"
					@click="showSendDialog(item._id, item.title, item.getRealtor && item.getRealtor.email)"
				>Send</base-button>
				<base-button
					v-if="`realestate:listing:${item._id}.update`"
					:to="{
						name: 'listing',
						params: { listing: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="`realestate:listing:${item._id}.${item.status === 'new' ? 'delete' : 'update'}`"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					:disabled="item.status === 'discarded'"
					@click="showDeletionDialog(item._id, item.title, item.status !== 'new')"
				>{{ item.status === 'new' ? 'Delete' : 'Discard' }}</base-button>
			</template>
		</listings-table>
		<listing-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			:discard="deletionDialog.discard"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
		<listing-send-modal
			:name="sendDialog.name"
			:email.sync="sendDialog.email"
			:id="sendDialog.id"
			v-model="sendDialog.show"
			@sent="refresh = true"
		/>
	</div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

import { useRoute } from '@/plugins/router'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useDataTableRouteState from '@/features/useDataTableRouteState'
import useSession from '@/features/useSession'

import ListingsHeader from '@/components/fragments/listing/ListingsHeader.vue'
import ListingsLegend from '@/components/fragments/listing/ListingsLegend.vue'
import ListingSendModal from '@/components/fragments/listing/ListingSendModal.vue'
import ListingsTable from '@/components/fragments/listing/ListingsTable.vue'
import ListingsTableFilters from '@/components/fragments/listing/ListingsTableFilters.vue'
import ListingDeletionModal from '@/components/fragments/listing/ListingDeletionModal.vue'

// Plugins
const route = useRoute()

// Data
const { filters, page } = useDataTableRouteState()
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
	discard: ref(false),
})

const to = ref()
const sendDialog = reactive({
	show: ref(false),
	id: ref(null),
	name: ref(''),
	email: to,
})

// Computed
const politicalAreaId = computed(() => route.params.location)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Listings',
}])

// Methods
const { can } = useSession()
function canSend(listing) {
	if (!listing.getArea
		|| !listing.getRealtor
		|| !['validated', 'sent'].includes(listing.status)
	) {
		return false
	}

	return true
}

function showDeletionDialog(id, text, discard) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.discard = discard
	deletionDialog.show = true
}

function showSendDialog(id, name, email) {
	sendDialog.id = id
	sendDialog.name = name
	sendDialog.email = email
	sendDialog.show = true
}

</script>
