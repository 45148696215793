<template>
	<div class="view-custom-rpa">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[1]">
			<base-expansion-panel title="Title, Location &amp; Details">
				<custom-rpa-form :id="rpaId" />
			</base-expansion-panel>
			<base-expansion-panel title="Rental Data">
				<h3>Reporting Data</h3>
				<custom-rpa-reporting-data class="mb-4" :id="rpaId" />
				<custom-rpa-rental-data-form :id="rpaId" />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import CustomRpaForm from '@/components/fragments/customRPA/CustomRpaForm'
import CustomRpaReportingData from '@/components/fragments/customRPA/CustomRpaReportingData'
import CustomRpaRentalDataForm from '@/components/fragments/customRPA/CustomRpaRentalDataForm'

import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const savedCustomRPAQuery = useSavedDataTableRouteState('customRPAs')
const rpaId = computed(() => route.params.rpa)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Custom Rental Projection Analyses',
	to: {
		name: 'customRPAs',
		query: savedCustomRPAQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getCustomRentalProjectionAnalysis',
	id: rpaId.value,
	field: 'title',
}])
</script>