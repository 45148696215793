<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.name`]="{ item }">
			<v-chip :color="activityTypes[item.name].color">{{activityTypes[item.name].text}}</v-chip>
		</template>
		<template v-slot:[`item.date`]="{ item }">
			{{formatDateTime(item.date)}}
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import { formatDateTime } from '@/utils/datetime'
import GenericDataTable from '../GenericDataTable.vue'

// Props
const props = defineProps({
	listing: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const activityTypes = {
	sent: {
		text: 'Sent',
	},
	delivered: {
		text: 'Delivered',
		color: 'secondary',
	},
	open: {
		text: 'Opened',
		color: 'primary',
	},
	click: {
		text: 'Clicked',
		color: 'primary',
	},
	spam: {
		text: 'SPAM',
		color: 'error',
	},
	unsubscribe: {
		text: 'Unsubscribed',
		color: 'error',
	},
	deferred: {
		text: 'Deferred',
		color: 'warning',
	},
	softbounce: {
		text: 'Soft bounce',
		color: 'warning',
	},
	hardbounce: {
		text: 'Hard bounce',
		color: 'error',
	},
}

const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Date',
	value: 'date',
}, {
	text: 'Reason',
	value: 'reason',
}]

// Computed
const filters = computed(() => ({
	listingId: props.listing,
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'date',
	sortDesc: true,
	headers,
	filters,
	getFn: 'getListingActivities',
	countFn: 'countListingActivities',
	fields: ['_id', 'name', 'date', 'reason'],
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>