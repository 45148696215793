<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.politicalArea`]="{ item }">
			{{item.getPoliticalArea.name}}
		</template>
		<template v-slot:[`item.listing`]="{ item }">
			{{item.getListing.address}}
		</template>
		<template v-slot:[`item.representative`]="{ item }">
			<div>{{item.getRepresentative.firstName}} {{item.getRepresentative.lastName}}</div>
			<div>{{item.getRepresentative.getMarket.name}}</div>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Listing',
	value: 'listing',
	sortable: false,
}, {
	text: 'Location',
	value: 'politicalArea',
	sortable: false,
}, {
	text: 'Representative/Market',
	value: 'representative',
	sortable: false,
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	headers,
	filters,
	getFn: 'getComplexRequests',
	getCb: (query) => {
		query.child('getRepresentative').fields('firstName', 'lastName').child('getMarket').fields('name')
		query.child('getListing').fields('address')
		query.child('getPoliticalArea').fields('name')
	},
	countFn: 'countComplexRequests',
	fields: ['_id', 'name'],
}))

</script>

<script>
export default {
	inheritAttrs: false
}
</script>