import { render, staticRenderFns } from "./ProfileForm.vue?vue&type=template&id=523a4792&scoped=true&"
import script from "./ProfileForm.vue?vue&type=script&setup=true&lang=js&"
export * from "./ProfileForm.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ProfileForm.vue?vue&type=style&index=0&id=523a4792&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523a4792",
  null
  
)

export default component.exports