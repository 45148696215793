<template>
	<div class="view-market-settings">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[0, 1]">
			<base-expansion-panel title="Basic Informations">
				<market-form :id="marketId" />
			</base-expansion-panel>
			<base-expansion-panel title="Areas">
				<market-areas-table :id="marketId" />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import MarketForm from '@/components/fragments/market/MarketForm'
import MarketAreasTable from '@/components/fragments/market/MarketAreasTable'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const marketId = computed(() => route.params.market)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Markets',
	to: { name: 'markets' },
}, {
	api: 'marketmath',
	fn: 'getMarket',
	id: marketId.value,
	field: 'name',
}, {
	text: 'Settings',
}])
</script>