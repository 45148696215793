<template>
	<div class="view-newrole">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<role-form new @create="onCreated" />
	</div>
</template>

<script setup>
import RoleForm from '@/components/fragments/role/RoleForm'

import { useRouter } from '@/plugins/router'

// Modules
const router = useRouter()

// Data
const breadcrumb = [{
	text: 'Admin',
}, {
	text: 'Roles',
	to: {
		name: 'roles',
	},
}, {
	text: 'New role',
}]

// Methods
async function onCreated() {
	router.push({
		name: 'roles',
	})
}
</script>