<template>
	<div class="view-historical-datasets">
		<generic-app-header
			:breadcrumb="breadcrumb"
		/>
		<datasets-table :political-area="politicalAreaId" :refresh.sync="refresh" />
	</div>
</template>

<script setup>
import { computed, ref } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import GenericAppHeader from '@/components/fragments/GenericAppHeader.vue'
import DatasetsTable from '@/components/fragments/historicalDataset/DatasetsTable.vue'

// Plugins
const route = useRoute()

// Data
const refresh = ref(false)

// Computed
const politicalAreaId = computed(() => route.params.location)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Historical Datasets',
}])
</script>
