function currencyMask(value) {
	const numbers = value.replace(/[^0-9]/g, '')
	if (!numbers.length) { return [] }
	const maskedValue = parseFloat(numbers).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')
	return ['$', ...maskedValue]
}

function percentMask(value, oldValue) {
	const numbers = value.replace(/[^0-9.]/g, '')
	const removeOne = oldValue
		&& oldValue.length
		&& oldValue.indexOf('%') !== -1
		&& value.indexOf('%') === -1

	if (!numbers.length) { return [] }
	const [number, rest] = numbers.substring(0, numbers.length - (removeOne ? 1 : 0)).split('.')
	return [...number.substring(0, 2), ...(typeof rest !== 'undefined' ? ['.', ...rest.substring(0, 2)] : []), '%']
}

/**
 * Mask
 * @param {String} type - Mask type [currency,percentage]
 */
export default function(type) {
	let oldPercentMaskValue = null

	switch (type) {
	case 'currency':
		return currencyMask
	case 'percentage':
		return (value) => {
			const maskedValue = percentMask(value, oldPercentMaskValue)
			oldPercentMaskValue = value
			return maskedValue
		}
	}
}