const state = {
	accessToken: null,
	refreshToken: null,
	accessExpires: null,
	id: null,
	firstName: '',
	lastName: '',
	permissions: {},
}

const TRESHOLD = 30000

const getters = {
	getAccessToken(currentState) {
		return currentState.accessToken
	},
	getRefreshToken(currentState) {
		return currentState.refreshToken
	},
	getExpirationSeconds(currentState) {
		return currentState.accessExpires - Date.now() - TRESHOLD
	},
	isExpired(currentState) {
		return !currentState.accessExpires || new Date(currentState.accessExpires - TRESHOLD) < new Date()
	},
	isAuth(currentState) {
		return currentState.accessToken !== null
	},
	isInit(currentState) {
		return currentState.accessToken !== null && currentState.id !== null
	},
	isAuthorized(currentState) {
		return (permission) => {
			const [resource, access] = permission.split('.')
			let found = currentState.permissions['*'] || currentState.permissions[resource]
			if (!found) {
				const parts = resource.split(':')
				do {
					parts.pop()
					found = currentState.permissions[`${parts.join(':')}:*`]
				} while (!found && parts.length > 1)
			}

			if (found === true) { return true }
			return (found && found[access]) || false
		}
	},
	getUserId(currentState) {
		return currentState.id
	},
	getFirstName(currentState) {
		return currentState.firstName
	},
	getLastName(currentState) {
		return currentState.lastName
	},
}

const actions = {
	login({ commit }, { accessToken, accessExpiration, refreshToken }) {
		commit('setRefreshToken', refreshToken)
		commit('setAccessToken', accessToken)
		commit('setExpiration', accessExpiration * 1000)
	},
	refresh({ commit }, { accessToken, accessExpiration }) {
		commit('setAccessToken', accessToken)
		commit('setExpiration', accessExpiration * 1000)
	},
	identify({ commit }, { permissions, id, firstName, lastName }) {
		commit('setPermissions', permissions)
		commit('setID', id)
		commit('setFirstName', firstName)
		commit('setLastName', lastName)
	},
	updateProfileName({ commit }, { firstName, lastName }) {
		commit('setFirstName', firstName)
		commit('setLastName', lastName)
	},
	logout({ commit }) {
		commit('setAccessToken', null)
		commit('setRefreshToken', null)
		commit('setExpiration', null)
		commit('setID', null)
		commit('setPermissions', {})
		commit('setFirstName', '')
		commit('setLastName', '')
	},
}

const mutations = {
	setAccessToken(currentState, accessToken) {
		currentState.accessToken = accessToken
	},
	setRefreshToken(currentState, refreshToken) {
		currentState.refreshToken = refreshToken
	},
	setExpiration(currentState, accessExpires) {
		currentState.accessExpires = accessExpires
	},
	setPermissions(currentState, permissions) {
		currentState.permissions = permissions
	},
	setID(currentState, id) {
		currentState.id = id
	},
	setFirstName(currentState, firstName) {
		currentState.firstName = firstName
	},
	setLastName(currentState, lastName) {
		currentState.lastName = lastName
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
