<template>
	<generic-app-header
		:breadcrumb="props.breadcrumb"
		color="primary"
		action="Add City"
		action-icon="mdi-plus-circle-outline"
		permission="location:city:*.create"
		:actions-loading="importing"
		:to="{ name: 'newCity' }"
	>
		<template v-slot:actions>
			<!--<base-list-item
				v-if="can('location:city:*.create')"
				prepend-icon="mdi-import"
				@click="showFileImport = true"
			>Import</base-list-item>-->
			<base-list-item prepend-icon="mdi-export" @click="onExport">Export</base-list-item>
			<base-file-reader :show.sync="showFileImport" type="json" @load="onImport" />
		</template>
	</generic-app-header>
</template>

<script setup>
import { ref } from 'vue'

import GenericAppHeader from '../GenericAppHeader.vue'

// import useSession from '@/features/useSession'
import useExport from '@/features/useExport'
import useImport from '@/features/useImport'

// props & Emits
const props = defineProps({
	breadcrumb: {
		type: Array,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['import'])

// Data
const importing = ref(false)
const showFileImport = ref(false)
const FIELDS = ['name', 'uriName', 'sources', 'lat', 'long']

// Methods
// const { can } = useSession()
const exportCities = useExport({
	fn: 'getCities',
	queryCb: (query) => {
		query.child('geolocation').fields('type', 'coordinates')
	},
	fields: ['_id', ...FIELDS],
})

const importCities = useImport({
	fn: 'createCity',
	fields: [...FIELDS, 'geolocation'],
	map: (input) => ({
		...input,
		politicalAreaId: props.politicalArea,
	}),
})

function onExport() {
	exportCities('cities', {
		filters: {
			politicalAreaId: props.politicalArea,
		}
	})
}

async function onImport(data) {
	importing.value = true
	const result = await importCities(data)
	importing.value = false
	emit('import', result)
}
</script>