import { getCurrentInstance } from 'vue'
import * as Vuex from 'vuex'

import modules from '@/store/modules'
import persistences from '@/store/persistences'

const keys = Object.keys(Vuex)

// To reduce the migration time later on to Vue 3
export function useStore() {
	return keys.includes('useStore') ? { ...Vuex }.useStore() : getCurrentInstance().proxy.$store
}

export default function createStore(app) {
	app.use(Vuex.default)
	return new Vuex.Store({
		strict: process.env.NODE_ENV !== 'production',
		modules,
		plugins: [...persistences],
	})
}
