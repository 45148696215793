<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.name`]="{ item }">
			<v-chip :color="activityTypes[item.name].color">{{activityTypes[item.name].text}}</v-chip>
		</template>
		<template v-slot:[`item._id`]="{ item }">
			{{formatDateTime(item._id)}}
		</template>
		<template v-slot:[`item.representativeName`]="{ item }">
			{{item.getRepresentative.firstName}} {{item.getRepresentative.lastName}}
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'
import { DateTime } from 'luxon'

import GenericDataTable from '../GenericDataTable.vue'

// Props
const props = defineProps({
	listing: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Note',
	value: 'note',
}, {
	text: 'Representantive',
	value: 'representativeName',
}, {
	text: 'Date',
	value: '_id',
}]

// Computed
const filters = computed(() => ({
	listingId: props.listing,
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: '_id',
	sortDesc: true,
	headers,
	filters,
	getFn: 'getListingNotes',
	getCb: (query) => {
		query.child('getRepresentative').fields('firstName', 'lastName')
	},
	countFn: 'countListingNotes',
	fields: ['_id', 'note'],
}))

// Methods
// @todo - Move it as a global directive
function formatDateTime(objectId) {
	const date = parseInt(objectId.substring(0, 8), 16) * 1000
	//DateTime.now().setZone('America/New_York');
	return DateTime.fromMillis(parseInt(date, 10)).toFormat('yyyy LLL dd HH:mm:ss ZZZZ')
}
</script>

<script>
export default {
	inheritAttrs: false
}
</script>