import { WritableStream } from 'web-streams-polyfill/ponyfill'
import streamSaver from 'streamsaver'
import { useApi } from '@/plugins/api'

const DEFAULT_BATCH_LIMIT = 100

export const EXTENSIONS = {
	json: 'json',
	csv: 'csv',
}

/**
 * Export feature
 * @param {object} settings - Settings
 * @param {string} settings.fn - GraphQL function name
 * @param {array<string>} settings.fields - GraphQL fields
 * @param {array<string|object>} [settings.headers] - File headers
 * @param {string} [settings.headers[].text] -Text
 * @param {string|function} [settings.headers[].value] -Value
 * @param {number} [limit=100] - Batch limit
 * @param {string} [api=default] - API name
 * @param {string} [format=json] - Format
 */
export default function({
	fn,
	queryCb = (query) => query,
	fields,
	headers,
	limit = DEFAULT_BATCH_LIMIT,
	api: apiName = 'default',
	format = 'json',
}) {
	if (!Object.keys(EXTENSIONS).includes(format)) {
		throw new Error('Export format is unsupported')
	}

	// Use polyfill if WritableStream is not available for the browser
	if (!window.WritableStream) {
		streamSaver.WritableStream = WritableStream
		window.WritableStream = WritableStream
	}

	// Data
	const api = useApi()

	// Methods
	function beginCsvStream(writer) {
		const text = (headers || fields).map((field) => {
			const fieldText = typeof field === 'object' ? field.text : field
			return `"${fieldText}"`
		}, []).join(',')

		const uInt8 = new TextEncoder().encode(text)
		writer.write(uInt8)
	}
	function beginStream(writer) {
		switch (format) {
		case 'json':
			writer.write(new TextEncoder().encode('['))
			break
		case 'csv':
			beginCsvStream(writer)
			break
		default:
		}
	}

	function transformEntriesWithHeaders(entries) {
		if (!headers) { return entries }
		return entries.map((entry) => {
			return headers.reduce((acc, header) => {
				const value = typeof header === 'object' ? header.value : header
				const text = typeof header === 'object' ? header.text : header

				if (typeof value === 'function') {
					acc[text] = value(entry)
				} else {
					acc[text] = entry[value]
				}
				return acc
			}, {})
		})
	}

	// @todo - Add support for headers in JSON
	function writeJson(writer, entries) {
		const text = JSON.stringify(transformEntriesWithHeaders(entries))
		const uInt8 = new TextEncoder().encode(text.substring(1, text.length - 1))
		writer.write(uInt8)
	}

	function writeCsv(writer, entries) {
		const text = transformEntriesWithHeaders(entries).map((entry) => {
			return (headers || fields).reduce((acc, field) => {
				const fieldText = typeof field === 'object' ? field.text : field
				const rawValue = entry[fieldText]

				if (!rawValue) {
					acc.push('""')
				} else {
					const value = typeof rawValue === 'object' ? JSON.stringify(rawValue) : rawValue
					acc.push(`"${typeof value !== 'string' ? value : value.replace(/"/g, '""')}"`)
				}

				return acc
			}, []).join(',')
		}).join("\n")
		writer.write(new TextEncoder().encode("\n"))
		const uInt8 = new TextEncoder().encode(text)
		writer.write(uInt8)
	}

	function write(writer, entries) {
		switch (format) {
		case 'json':
			writeJson(writer,entries)
			break
		case 'csv':
			writeCsv(writer, entries)
			break
		default:
		}
	}

	function endStream(writer) {
		switch (format) {
		case 'json':
			writer.write(new TextEncoder().encode(']'))
			break
		default:
		}
	}

	async function fetchEntries(filters, offset) {
		const request = api.graphql(apiName)
		const query = request.query(fn)
			.arg('query', {
				...filters,
				skip: offset,
				limit,
			}).fields(...fields)
		await queryCb(query)
		const result = await request.exec()
		const { data = [] } = result.get(fn)
		return data
	}

	async function processExport(filename, {
		filters = {}
	} = {}) {
		let offset = 0
		let entries = []
		const fileStream = streamSaver.createWriteStream(`${filename}.${EXTENSIONS[format]}`)
		const writer = fileStream.getWriter()
		beginStream(writer)
		do {
			entries = await fetchEntries(filters, offset)
			write(writer, entries)
			offset += DEFAULT_BATCH_LIMIT
		} while (entries.length === DEFAULT_BATCH_LIMIT)
		endStream(writer)
		await writer.close()
	}

	// Export
	return processExport
}