var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"user-form"},[_c('base-switch',{attrs:{"type":"password","label":"Is Enabled","disabled":_setup.loading},model:{value:(_setup.enabled),callback:function ($$v) {_setup.enabled=$$v},expression:"enabled"}}),(_setup.enabled && _setup.url)?_c('div',[_c('div',[_vm._v("Scan the QRCode using an Authenticator such as Google Authenticator or Microsoft Authenticator")]),_c('img',{attrs:{"src":_setup.url}}),_c('base-text-field',{attrs:{"label":"Token"},model:{value:(_setup.verificationToken),callback:function ($$v) {_setup.verificationToken=$$v},expression:"verificationToken"}}),_c('base-button',{attrs:{"block":"","color":"primary","disabled":!_setup.isFilled},on:{"click":function($event){return _setup.onConfirm()}}},[_vm._v(" Confirm ")])],1):_vm._e(),_c('base-modal',{attrs:{"title":"Deactivate 2FA","color":"warning","actions":[{
			text: 'Confirm',
			value: 'confirm',
			color: 'warning',
			loading: _setup.loading,
		}, {
			text: 'Cancel',
			value: 'cancel',
			disabled: _setup.loading,
		}],"value":_setup.showDeactivateModal},on:{"click":_setup.onDeactivate}},[_vm._v(" Are you sure that you want to deactivate the two-factor authentication? ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }