<template>
	<div class="city-form">
		<div class="city-form-data">
			<base-address-autocomplete
				v-if="country"
				only-name
				label="Name*"
				id="abc"
				:value="name"
				types="(cities)"
				:country="country"
				@placechanged="onPlaceChanged"
			/>
			<base-text-field v-else v-model="name" label="Name*" disabled/>
			<base-text-field v-model="uriName" label="Website URL" />
			<base-image-upload
				v-model="featuredImage"
				:src="featuredImageUrl"
				width="350"
				height="233"
				label="Featured Image (350x233)"
				resize
			/>
			<base-select v-model="sources" multiple :items="listingSources" label="Listing Sources" />
		</div>
		<div v-if="lat && long" class="map">
			<gmap-map :zoom="10" :center="center" style="width: 100%; height: 500px; max-width: 800px;" ref="map">
				<gmap-polygon
					v-if="paths.length > 0"
					ref="polygon"
					:options="{
						strokeColor: '#8ecb64',
						fillColor: '#8ecb64',
					}"
					:paths="paths"
					:editable="true"
					@paths_changed="updateEdited($event)"
					@rightclick="handleClickForDelete"
				>
				</gmap-polygon>
			</gmap-map>
		</div>
		<div class="city-form-data mt-4">
			<base-button
				block
				color="primary"
				:disabled="(props.new ? !isFilled : (!hasChanges && !coordinatesChanged)) || !coordinates.length"
				:loading="loading"
				@click="props.new ? onCreate() : onSaveChanges(props.id)"
			>
				{{ props.new ? 'Create' : 'Save Changes' }}
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useGeoJSON from '@/features/useGeoJSON'
import { useMap, usePolygon } from '@/features/googleMapsUtils'

const REQUIRED_FIELDS = ['name', 'lat', 'long']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	politicalArea: {
		type: String,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const listingSources = [{
	text: 'Zillow',
	value: 'zillow'	
}, {
	text: 'Realtor.ca/Centris',
	value: 'realtorca'	
}]

const map = ref()	// Map ref
const polygon = ref()	// Map polygon ref

const paths = ref([])
const country = ref()
const name = ref(null)
const uriName = ref()
const featuredImage = ref(null)
const featuredImageUrl = ref(null)
const sources = ref([])
const lat = ref()
const long = ref()
const coordinates = ref([])
const coordinatesChanged = ref(false)
const center = reactive({lat, lng: long })

const loading = ref(false)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	sources,
	lat,
	long,
	uriName,
	featuredImage,
}, REQUIRED_FIELDS)

// Methods
const {
	geoJSONCoordinatesToPaths,
	googlePathsToPolygonPaths,
	polygonPathsToGeoJSONCoordinates,
} = useGeoJSON()
const {
	generatePolygonPaths
} = useMap(map)
const {
	handleClickForDelete
} = usePolygon(polygon)

async function onCreate() {
	loading.value = true
	const request = api.graphql()
	request.mutation('createCity')
		.arg('input', {
			...form,
			geolocation: {
				type: 'Polygon',
				coordinates: coordinates.value,
			},
			politicalAreaId: props.politicalArea,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createCity')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateCity')
		.arg('id', id)
		.arg('input', {
			...changes.value,
			...(coordinatesChanged.value ? {
				geolocation: {
					type: 'Polygon',
					coordinates: coordinates.value,
				}
			} : {}),
		})
		.fields('name', 'uriName', 'sources', 'lat', 'long', 'featuredImageUrl')
	
	const result = await request.exec()
	const { success, data } = result.get('updateCity')
	if (success) {
		featuredImageUrl.value = data.featuredImageUrl
		featuredImage.value = null
	
		loadOriginalData({
			name: data.name,
			uriName: data.uriName,
			sources: data.sources,
			lat: data.lat,
			long: data.long,
			featuredImage: null,
		})
		sources.value = data.sources
		coordinatesChanged.value = false
		emit('save', data)
	}

	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	const query = request.query('getCity')
		.arg('id', id)
		.fields('name', 'uriName', 'sources', 'lat', 'long', 'featuredImageUrl')

	query.child('getPoliticalArea')
		.fields('country')
	query.child('geolocation')
		.fields('coordinates')

	const result = await request.exec()
	const { data } = result.get('getCity')
	
	country.value = data.getPoliticalArea.country
	coordinates.value = data.geolocation.coordinates
	featuredImageUrl.value = data.featuredImageUrl
	paths.value = geoJSONCoordinatesToPaths(data.geolocation.coordinates)

	loadOriginalData({
		name: data.name,
		uriName: data.uriName,
		sources: data.sources,
		lat: data.lat,
		long: data.long,
		featuredImage: null,
	})
}

async function fetchPoliticalArea(id) {
	const request = api.graphql()
	request.query('getPoliticalArea')
		.arg('id', id)
		.fields('country')

	const result = await request.exec()
	const { data } = result.get('getPoliticalArea')
	country.value = data.country
}

function updateCoordinates(polygonPaths) {
	coordinates.value = polygonPathsToGeoJSONCoordinates(polygonPaths)
	coordinatesChanged.value = true
}

function updateEdited(mvcPaths) {
	const polygonPaths = googlePathsToPolygonPaths(mvcPaths)
	updateCoordinates(polygonPaths)
}

async function setPaths() {
	paths.value = await generatePolygonPaths()
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	} else {
		fetchPoliticalArea(props.politicalArea)
	}
})

async function onPlaceChanged(_, place) {
	lat.value = place.geometry.location.lat()
	long.value = place.geometry.location.lng()
	name.value = place.name

	setPaths()
}
</script>

<style scoped>
	.city-form-data {
		max-width: 320px;
	}
</style>