<template>
	<v-app>
		<v-app-bar
			v-if="isAuth && isInit"
			app
			dense
			flat
			color="white"
		>
			<div class="d-flex align-center">
				<base-button
					icon
					v-if="$vuetify.breakpoint.xs"
					@click="drawerState = !drawerState"
				>
					<base-icon>mdi-menu</base-icon>
				</base-button>
			</div>

			<v-spacer></v-spacer>

			<v-menu tile offset-y>
				<template v-slot:activator="{ on, attrs }">
					<base-button
						:text="!$vuetify.breakpoint.xs"
						:icon="$vuetify.breakpoint.xs"
						v-bind="attrs"
						v-on="on"
					>
						<span v-if="!$vuetify.breakpoint.xs">{{firstName}}</span>
						<base-icon>mdi-dots-vertical</base-icon>
					</base-button>
				</template>
				<v-list>
					<v-list-item :to="{ name: 'me' }">
						<v-list-item-title>My Account</v-list-item-title>
					</v-list-item>
					<v-list-item @click="onLogout">
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-navigation-drawer v-model="drawerState" v-if="isAuth && isInit" app>
			<v-list-item>
				<v-list-item-content>
				<v-list-item-title>
					<v-img :src="require('@/assets/logo.png')" width="80%" style="margin: 0 auto;" />
				</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<base-button v-if="navButton" block :color="navButton.color || 'primary'" class="navigation-context-button" :to="navButton.to">
				<base-icon class="mr-8">mdi-chevron-left</base-icon>
				<v-progress-circular v-if="navButton.loading" small indeterminate size="24" width="2" />
				<span v-else>{{ navButton.text }}</span>
			</base-button>
			<base-list
				dense
				nav
			>
				<base-list-item
					v-for="item in authorizedMenu"
					:key="item.title"
					:to="item.to"
					link
					:color-active="item.activeColor"
					:color="item.color || (navButton && navButton.color) || 'primary'"
					:prepend-icon="item.icon"
				>
					{{ item.title }}
				</base-list-item>
			</base-list>
		</v-navigation-drawer>

		<v-main class="main" v-if="init">
			<base-container v-if="!isAuth || (isAuth && isInit)" fluid color="#eaeaea">
				<router-view></router-view>
			</base-container>
			<div v-else class="splashscreen">
				<div class="splashscreen-container">
					<div><img :src="require('@/assets/logo.png')" width="200px"/></div>
					<div class="splashscreen-loading">Loading...</div>
				</div>
			</div>
		</v-main>
	</v-app>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useApi } from '@/plugins/api'
import { useStore } from '@/plugins/store'
import { useRouter } from '@/plugins/router'

import useNavigationContext from '@/features/useNavigationContext'
import useSession from '@/features/useSession'

// Plugins
const api = useApi()
const store = useStore()
const router = useRouter()

// Data
const init = ref(false)
const drawerState = ref()
let expirationTimeout
const { menu, navButton, init: initMenuSwitcher } = useNavigationContext()

// Computed Properties
const { isAuth, isInit, accessToken, refreshToken, expirationSeconds, isExpired, can, firstName } = useSession()

const authorizedMenu = computed(() => {
	return menu.value.filter(({ permission }) => !permission || can.value(permission))
})

// Watchers
watch(accessToken, (value) => {
	authorizeApi(value)
	if (value) {
		fetchSessionInformation()
		setExpirationTimeout()
	}
})

// Methods
function authorizeApi(bearerToken) {
	if (isAuth.value) {
		api.setHeaders('marketmath', { Authorization: `Bearer ${bearerToken}` })
	} else {
		api.setHeaders('marketmath', { Authorization: undefined })
	}
}

async function fetchSessionInformation() {
	const request = api.graphql()
	request.query('getMe').fields('firstName', 'lastName', 'userId', 'permissions')
	const result = await request.exec()
	const { success, data } = result.get('getMe')

	if (!success) {
		onLogout()
		return
	}

	store.dispatch('session/identify', data)
}

async function refreshAccessToken() {
	const request = api.graphql()
	request.mutation('refreshLogin')
		.arg('input', {
			token: refreshToken.value,
		})
		.fields(['accessToken', 'accessExpiration'])
	const result = await request.exec()
	const { success, data } = result.get('refreshLogin')

	if (!success) {
		onLogout()
		return
	}

	store.dispatch('session/refresh', data)
}

function setExpirationTimeout() {
	expirationTimeout = setTimeout(() => {
		refreshAccessToken()
	}, expirationSeconds.value)
}

function clearExpirationTimeout() {
	if (expirationTimeout) {
		clearTimeout(expirationTimeout)
	}
}

function onLogout() {
	clearExpirationTimeout()
	store.dispatch('session/logout')
	router.push({ name: 'login' })
}

// Mounted
onMounted(async () => {
	api.register('marketmath', window.appConfigs.MARKETMATH_URL, {
		graphql: '/graphql',
		isDefault: true,
	})

	if (isAuth.value) {
		if (isExpired.value) {
			await refreshAccessToken()
		}
		else if (accessToken.value) {
			authorizeApi(accessToken.value)
			await fetchSessionInformation()
			setExpirationTimeout()
		}
	}

	initMenuSwitcher()
	init.value = true
})
</script>

<style scoped>
	.main {
		background-color: #fafafa;
	}
	.splashscreen {
		color: #a6a6a6;
		font-size: 24px;
	}

	.splashscreen .splashscreen-container {
		margin: 0 auto;
		width: 200px;
		margin-top: calc(50vh - 39px);
	}
</style>

<style>
	a {
		text-decoration: none;
	}

	.base-input .v-input, .v-application .v-dialog {
		border-radius: 0;
	}

	.maxed-width {
		max-width: 1200px;
		margin: 0 auto;
	}

	.navigation-context-button .v-btn__content {
		justify-content: left;
	}

	body.public nav.v-navigation-drawer {
		display: none;
	}

	body.public header.v-app-bar {
		display: none;
	}

	body.public main {
		padding: 0 !important;
	}

	@media print {
		.main {
			background-color: #fff !important;
		}
	}
</style>