<template>
	<div class="representative-form">
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="firstName" label="First Name*" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="lastName" label="Last Name*" />
				</base-col>
			</base-row>
		</base-container>
		<base-text-field v-model="email" label="*Email Address" />
		<base-text-field v-model="phone" label="Phone Number" />
		<base-switch v-model="active" label="Active" />
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

const REQUIRED_FIELDS = ['firstName', 'lastName', 'email']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	market: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const firstName = ref(null)
const lastName = ref(null)
const email = ref(null)
const phone = ref(null)
const active = ref(false)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	firstName,
	lastName,
	email,
	phone,
	active,
}, REQUIRED_FIELDS)

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createRepresentative')
		.arg('input', {
			...form,
			marketId: props.market,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createRepresentative')
	if (success) {
		emit('create', data._id)
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateRepresentative')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('firstName', 'lastName', 'email', 'phone', 'active')
	
	const result = await request.exec()
	const { success, data } = result.get('updateRepresentative')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getRepresentative')
		.arg('id', id)
		.fields('firstName', 'lastName', 'email', 'phone', 'active')

	const result = await request.exec()
	const { data } = result.get('getRepresentative')
	loadOriginalData(data)
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
	// @todo - Do a proper autocomplete feature with search
})
</script>

<style scoped>
	.representative-form {
		max-width: 320px;
	}
</style>