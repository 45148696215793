<template>
	<div class="contact-form">
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="firstName" label="First Name*" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="lastName" label="Last Name*" />
				</base-col>
			</base-row>
		</base-container>
		<base-text-field v-model="email" label="*Email Address" />
		<base-text-field v-model="phone" label="*Phone Number" />
		<div class="mb-2" v-if="can('location:political-area:*.read')">
			<h4>Location Phones</h4>
			<div class="grey lighten-3 pa-4">
				<base-autocomplete
					v-model="newLocationPhone.politicalAreaId"
					label="Location"
					clearable
					:loading="loadingPoliticalAreas"
					:items="availablePoliticalAreas"
				/>
				<base-text-field
					v-model="newLocationPhone.phone"
					label="Location Phone Number"
				/>
				<base-button
					block
					color="secondary"
					outlined
					:disabled="!newLocationPhone.politicalAreaId || !newLocationPhone.phone"
					@click="onAddLocationPhone"
				>
					Add Location Phone
				</base-button>
			</div>
			<base-row
				v-for="locationPhone in locationalPhones"
				:key="`location-phone-${locationPhone.politicalAreaId}`"
				class="mt-2"
			>
				<base-col>
					<base-text-field
						:label="getLocationName(locationPhone.politicalAreaId)"
						:value="locationPhone.phone"
						readonly
						clearable
						@click:clear="onClearLocationPhone(locationPhone.politicalAreaId)"
					/>
				</base-col>
			</base-row>
		</div>
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useSession from '@/features/useSession'
import useDataSource from '@/features/useDataSource'

const REQUIRED_FIELDS = ['firstName', 'lastName', 'email', 'phone']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()
const { can } = useSession()

const { entries: politicalAreas, loading: loadingPoliticalAreas } = useDataSource('getPoliticalAreas', {
	query: { politicalAreaId: props.politicalArea },
})

// Data
const firstName = ref(null)
const lastName = ref(null)
const email = ref(null)
const phone = ref(null)
const locationalPhones = ref([])
const newLocationPhone = ref({
	politicalAreaId: null,
	phone: null,
})

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	firstName,
	lastName,
	email,
	phone,
	locationalPhones,
}, REQUIRED_FIELDS)

// Computed
const takenPoliticalAreaIds = computed(() => locationalPhones.value.map(({ politicalAreaId }) => politicalAreaId))
const availablePoliticalAreas = computed(() => politicalAreas.value.filter((politicalArea) => {
	return !takenPoliticalAreaIds.value.includes(politicalArea.value)
}))

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createContact')
		.arg('input', {
			...form,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createContact')
	if (success) {
		emit('create', data._id)
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateContact')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('firstName', 'lastName', 'email', 'phone')
		.child('locationalPhones')
		.fields('politicalAreaId', 'phone')
	
	const result = await request.exec()
	const { success, data } = result.get('updateContact')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getContact')
		.arg('id', id)
		.fields('firstName', 'lastName', 'email', 'phone')
		.child('locationalPhones')
		.fields('politicalAreaId', 'phone')

	const result = await request.exec()
	const { data } = result.get('getContact')
	loadOriginalData(data)
}

function getLocationName(politicalAreaId) {
	const politicalArea = politicalAreas.value.find(({ value }) => politicalAreaId === value)
	return politicalArea ? politicalArea.text : '...'
}

function onAddLocationPhone() {
	locationalPhones.value = [...locationalPhones.value, { ...newLocationPhone.value }]
	newLocationPhone.value = {
		politicalAreaId: null,
		phone: null,
	}
}

function onClearLocationPhone(politicalAreaId) {
	const i = locationalPhones.value.findIndex((locationalPhone) => locationalPhone.politicalAreaId === politicalAreaId)
	if (i > -1) {
		locationalPhones.value.splice(i, 1)
		locationalPhones.value = [...locationalPhones.value]
	}
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
	// @todo - Do a proper autocomplete feature with search
})
</script>

<style scoped>
	.contact-form {
		max-width: 320px;
	}
</style>