import { computed } from 'vue'
import { useStore } from '@/plugins/store'

export default function() {
	const store = useStore()

	const isAuth = computed(() => store.getters['session/isAuth'])
	const isInit = computed(() => store.getters['session/isInit'])
	const accessToken = computed(() => store.getters['session/getAccessToken'])
	const isExpired = computed(() => store.getters['session/isExpired'])
	const expirationSeconds = computed(() => store.getters['session/getExpirationSeconds'])
	const refreshToken = computed(() => store.getters['session/getRefreshToken'])
	const firstName = computed(() => store.getters['session/getFirstName'])
	const lastName = computed(() => store.getters['session/getLastName'])
	const fullName = computed(() => `${firstName.value} ${lastName.value}`)
	const can = computed(() => store.getters['session/isAuthorized'])

	return {
		isAuth,
		isInit,
		accessToken,
		refreshToken,
		isExpired,
		expirationSeconds,
		can,
		firstName,
		lastName,
		fullName,
	}
}