<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.status`]="{ item }">
			<div :title="statusMap[item.status].text">
				<base-icon :color="statusMap[item.status].color">{{ statusMap[item.status].icon }}</base-icon>
			</div>
		</template>
		<template v-slot:[`item.address`]="{ item }">
			<div>{{item.address}}</div>
			<div v-if="item.getArea && (item.status === 'sent' || item.status === 'validated')">
				<a :href="getListingUrl(item)" target="_blank">{{item.uriName}}</a>
			</div>
			<div v-else>{{item.uriName}}</div>
		</template>
		<template v-slot:[`item.area`]="{ item }">
			<div v-if="item.getComplex" class="limit-text-size" :title="item.getComplex.name">{{item.getComplex.name}}</div>
			<div v-else class="warning--text"><i>No Complex Assigned</i></div>
			<div v-if="item.getArea">
				<div>{{item.getArea.name}}</div>
				<div>{{item.getArea.getCity.name}}</div>
			</div>
			<div v-else class="error--text">No Area assigned</div>
		</template>
		<template v-slot:[`item.realtorName`]="{ item }">
			<div>
				<div v-if="item.status === 'inprogress'">
					<i>In Progress...</i>
				</div>
				<div v-else-if="item.realtorId">
					<base-link :to="{ name: 'realtor', params: { realtor: item.realtorId } }" target="_blank">
						{{item.getRealtor.name}}
					</base-link>
				</div>
				<div v-else class="error--text">{{item.realtorName}}</div>
				<div class="limit-text-size" :title="item.brokerageName">{{item.brokerageName}}</div>
			</div>
		</template>
		<template v-slot:[`item.source`]="{ item }">
			<div>{{ item.source }}</div>
			<a :href="item.url" target="_blank">{{item.externalId}}</a>
		</template>
		<template v-slot:[`item.alerts`]="{ item }">
			<div class="listing-alert" v-for="(alert, i) in getAlerts(item)" :key="`alert-${i}`">
				<base-icon size="16" :color="alert.type">{{alert.icon}}</base-icon>
				<span :class="`ml-2 ${alert.type}--text`">{{alert.text}}</span>
			</div>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	politicalArea: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => {},
	},
})

const statusMap = {
	new: {
		text: 'New',
		icon: 'mdi-new-box',
		color: 'purple',
	},
	inprogress: {
		text: 'In Progress',
		icon: 'mdi-dots-horizontal-circle',
		color: 'purple',
	},
	validated: {
		text: 'Validated',
		icon: 'mdi-check-circle',
		color: 'success',
	},
	sent: {
		text: 'Sent',
		icon: 'mdi-send',
		color: 'success',
	},
	discarded: {
		text: 'Discarded',
		icon: 'mdi-cancel',
		color: 'error',
	},
}

// Computed
const headers = computed(() => [{
	text: '',
	value: 'status',
	sortable: false,
	width: '60px',
}, {
	text: 'Address/Website URL',
	value: 'address',
}, {
	text: 'Complex/Area/City',
	value: 'area',
	sortable: false,
}, {
	text: 'Realtor',
	value: 'realtorName',
	sortable: true,
}, {
	text: 'Source',
	value: 'source',
}, {
	text: props.filters.status === 'discarded' ? 'Reason' : 'Alerts',
	value: props.filters.status === 'discarded' ? 'declineReason' : 'alerts',
	sortable: false,
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}])

const filters = computed(() => ({
	politicalAreaId: props.politicalArea,
	sort: ['-_id'],
	status: ['new', 'inprogress', 'validated', 'sent'],
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	headers,
	filters,
	getFn: 'getListings',
	getCb: (query) => {
		query.child('reports').fields('revenue', 'service')
		query.child('getArea').fields('name', 'uriName').child('getCity').fields('name', 'uriName')
		query.child('getComplex').fields('name', 'uriName')
		query.child('getRealtor').fields('name', 'email', 'avatar')
		query.child('getPoliticalArea').fields('uriName', 'country')
	},
	countFn: 'countListings',
	fields: [
		'_id',
		'status',
		'address',
		'title',
		'uriName',
		'source',
		'externalId',
		'url',
		'brokerageName',
		'realtorName',
		'realtorId',
		'historicalDatasetId',
		'revenue',
		'declineReason',
	],
}))

function getListingUrl(listing) {
	return `https://www.marketmath.com/rental-analysis/${listing.getPoliticalArea.country}/${listing.getPoliticalArea.uriName}`
		+ `/${listing.getArea.getCity.uriName}` + `/${listing.getArea.uriName}`
		+ `/${listing.getComplex ? listing.getComplex.uriName : 'others'}/${listing.uriName}`
}

// Methods
function getAlerts(listing) {
	const alerts = []
	if (listing.status === 'discarded' || listing.status === 'new') { return }

	if (!listing.getArea) {
		alerts.push({
			type: 'error',
			icon: 'mdi-alert-octagon',
			text: 'Missing area',
		})
	}
	if (listing.status === 'validated' || listing.status === 'sent') {
		if (!listing.getComplex) {
			alerts.push({
				type: 'warning',
				icon: 'mdi-alert',
				text: 'Missing complex',
			})
		}
		if (!listing.getRealtor) {
			alerts.push({
				type: 'error',
				icon: 'mdi-alert-octagon',
				text: 'Missing realtor',
			})
		} else {
			if (!listing.getRealtor.email || listing.getRealtor.email === '') {
				alerts.push({
					type: 'error',
					icon: 'mdi-alert-octagon',
					text: 'Missing realtor email',
				})
			}
			if (!listing.getRealtor.avatar || listing.getRealtor.avatar === '') {
				alerts.push({
					type: 'warning',
					icon: 'mdi-alert',
					text: 'Missing realtor avatar',
				})
			}
		}
		if (!listing.historicalDatasetId) {
			alerts.push({
				type: 'error',
				icon: 'mdi-alert-octagon',
				text: 'Missing historical dataset',
			})
		}

		if (listing.reports.length && listing.revenue) {
			const aggregatedReport = listing.reports.find(({ service }) => service === 'aggregated')
			const revenueDiff = 100 * Math.abs( ( aggregatedReport.revenue - listing.revenue ) / ( (aggregatedReport.revenue+listing.revenue)/2 ) )
			if (revenueDiff >= 75) {
				alerts.push({
					type: 'warning',
					icon: 'mdi-alert',
					text: 'High revenue variance',
				})
			}
		}
	}

	return alerts
}
</script>

<script>
export default {
	inheritAttrs: false
}
</script>

<style scoped>
	.listing-alert {
		font-size: 14px;
	}
	.limit-text-size {
		max-width: 140px;
		overflow-x: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>