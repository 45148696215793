<template>
	<div class="listing-basic-form">
		<base-text-field v-model="brokerageName" label="Brokerage Name (from source)" readonly />
		<base-text-field v-model="realtorName" label="Realtor Name (from source)" readonly />
		<base-autocomplete v-model="realtorId" :loading="loadingRealtors" :items="realtors" label="Realtor" />
		<div class="button-wrapper">
			<base-button
				block
				color="primary"
				:disabled="!hasChanges"
				@click="onSaveChanges(props.id)"
			>
				Save Changes
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const brokerageName = ref(null)
const realtorName = ref(null)
const realtorId = ref(null)

const { entries: realtors, loading: loadingRealtors } = useDataSource('getRealtors', {
	query: { politicalAreaId: props.politicalArea },
})

const { changes, hasChanges, loadOriginalData } = useFormStates({
	brokerageName,
	realtorName,
	realtorId,
})

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateListing')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('brokerageName', 'realtorName', 'realtorId')
	
	const result = await request.exec()
	const { success, data } = result.get('updateListing')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getListing')
		.arg('id', id)
		.fields('brokerageName', 'realtorName', 'realtorId')

	const result = await request.exec()
	const { data } = result.get('getListing')
	loadOriginalData(data)
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.listing-basic-form {
		max-width: 600px;
	}
	.button-wrapper {
		max-width: 320px;
	}
</style>