<template>
	<div class="view-login">
		<login-card width="320" height="250" @login="onLogin" />
	</div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter, useRoute } from '@/plugins/router'
import { useStore } from '@/plugins/store'

import LoginCard from '@/components/fragments/login/LoginCard.vue'

// Data
const router = useRouter()
const route = useRoute()
const store = useStore()

// Computed
const isAuth = computed(() => store.getters['session/isAuth'])
const redirect = computed(() => route.query.redirect)

// Methods
function onLogin(success) {
	if (success) {
		router.push(redirect.value || { name: 'home' })
	}
}

onMounted(() => {
	if (isAuth.value) { router.push({ name: 'home' }) }
})
</script>

<style scoped>
	.login-card {
		left: 50%;
		top: 50%;
		margin-left: -180px;
		margin-top: -125px;
		position: absolute;
	}
</style>