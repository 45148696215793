<template>
	<base-modal
		title="Assign Complex"
		:value="show"
		:actions="actions"
		@click="onClick"
	>
		<base-autocomplete
			v-model="complexId"
			:loading="loadingComplexes"
			:items="complexes"
			:search-input.sync="searchValue"
			label="Complex"
		/>
	</base-modal>
</template>

<script setup>
import { ref, computed, watch, reactive } from 'vue'

import { useApi } from '@/plugins/api'
import useDataSource from '@/features/useDataSource'

// Props & Emits
const props = defineProps({
	value: {
		type: Boolean,
	},
	id: {
		type: String,
	},
})
const emit = defineEmits(['input', 'assign'])

// Data
const api = useApi()
const listing = ref()
const complexId = ref()
const searchValue = ref()

const disabled = computed(() => !complexId.value)

const actions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	text: 'Confirm',
	value: 'confirm',
	color: 'error',
	disabled,
})]

const { entries: complexes, loading: loadingComplexes, fetchEntries: fetchComplexes } = useDataSource('getComplexes', {
	manualOnly: true,
})

// Computed
const show = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

// Watchers
watch(show, (value) => {
	if (value) {
		init()
	}
})

async function assignComplex() {
	const request = api.graphql()
	request.mutation('updateListing').arg('id', listing.value).arg('input', {
		complexId: complexId.value,
	}).fields('_id')
	request.mutation('deleteComplexRequest').arg('id', props.id).fields('_id')
	await request.exec()
	emit('assign')
}

// Methods
async function onClick(value) {
	if (value === 'confirm') {
		await assignComplex()
	}

	show.value = false
}

async function init() {
	const request = api.graphql()
	request.query('getComplexRequest')
		.arg('id', props.id)
		.fields('name', 'listingId', 'politicalAreaId')
		.child('getListing').fields('areaId')

	const result = await request.exec()
	const { data } = result.get('getComplexRequest')
	const { name, listingId, politicalAreaId, getListing: { areaId } } = data
	listing.value = listingId
	
	await fetchComplexes({
		areaId,
		politicalAreaId,
	})

	const found = complexes.value.find(({ text }) => text === name)
	if (found) {
		complexId.value = found.value
	} else {
		complexId.value = null
	}
	setTimeout(() => {
		searchValue.value = name
	}, 0)
}
</script>