import { reactive } from 'vue'
import { useApi } from '@/plugins/api'

function base64Key(item) {
	return btoa(JSON.stringify(item))
}

const cache = {}

export default function(src) {
	// Data
	const api = useApi()
	const apiItems = src.filter(({ api: apiName }) => apiName)
	const callStack = {}
	const reactiveItems = apiItems.reduce((acc, { api: apiName, fn, id, field, ...rest }) => {
		const key = base64Key({ apiName, fn })
		if (cache[key] && cache[key].id === id) {
			acc[key] = {
				text: cache[key].text,
				...rest,
			}
			return acc
		}

		if (!callStack[apiName]) {
			callStack[apiName] = []
		}
		callStack[apiName].push({
			key,
			fn,
			id,
			field
		})

		acc[key] = reactive({
			text: '',
			loading: true,
			...rest,
		})

		return acc
	}, {})

	const breadcrumb = src.map((item) => {
		const { api: apiName, fn } = item
		if (!apiName) { return item }
		return reactiveItems[base64Key({ apiName, fn })]
	})

	// Methods
	async function fetchFromApi(name, calls) {
		const request = api.graphql(name)
		calls.forEach(({ fn, id, field }) => {
			request.query(fn).arg('id', id).fields(field)
		})
		const result = await request.exec()
		calls.forEach(({ fn, id, field, key }) => {
			const { data } = result.get(fn)
			if (data) {
				reactiveItems[key].text = data[field]
				reactiveItems[key].loading = false
			}

			cache[key] = {
				id,
				text: data[field],
			}
		})
	}

	function fetch() {
		Object.keys(callStack).forEach((name) => {
			const calls = callStack[name]
			fetchFromApi(name, calls)
		})
	}

	// Init
	fetch()

	return {
		breadcrumb,
	}
}
