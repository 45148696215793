<template>
	<div class="custom-rpa-form">
		<base-text-field v-model="title" label="Title*" />
		<base-text-field v-model="subtitle" label="Subtitle*" />
		<base-container>
			<base-row>
				<base-col cols="12" sm="4" class="pl-0">
					<base-text-field v-model="occupancy" label="Occupancy*" />
				</base-col>
				<base-col cols="12" sm="4">
					<base-text-field v-model="bedrooms" label="Bedrooms*" />
				</base-col>
				<base-col cols="12" sm="4" class="pr-0">
					<base-text-field v-model="bathrooms" label="Bathrooms*" />
				</base-col>
			</base-row>
		</base-container>
		<base-image-upload
			v-model="featuredImage"
			:src="featuredImageUrl"
			width="700"
			height="500"
			label="Featured Image* (700x500)"
			resize
		/>
		<base-address-autocomplete
			label="Address*"
			:value="address"
			@placechanged="onPlaceChanged"
		/>
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="lat" label="Latitude*" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="lng" label="Longitude*" />
				</base-col>
			</base-row>
		</base-container>
		<base-autocomplete v-model="contactId" :loading="loadingContacts" :items="contacts" label="Contact*" />
		<base-autocomplete v-model="politicalAreaId" :loading="loadingPoliticalAreas" :items="politicalAreas" label="Location*" />
		<base-autocomplete v-model="cityId" :loading="loadingCities" :items="cities" :disabled="!politicalAreaId" label="City*" />
		<base-autocomplete v-model="brandId" :loading="loadingBrands" :items="brands" :disabled="!politicalAreaId" label="Brand*" />
		<base-button
			block
			color="primary"
			:loading="loading"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

const REQUIRED_FIELDS = ['title', 'subtitle', 'occupancy', 'bathrooms', 'bedrooms', 'featuredImage', 'politicalAreaId', 'cityId', 'brandId']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const { entries: politicalAreas, loading: loadingPoliticalAreas } = useDataSource('getPoliticalAreas', {
	additionalFields: ['code', 'country']
})

const { entries: cities, loading: loadingCities, fetchEntries: fetchCities } = useDataSource('getCities', {
	manualOnly: true,
})
const { entries: brands, loading: loadingBrands, fetchEntries: fetchBrands } = useDataSource('getBrands', {
	manualOnly: true,
})
const { entries: contacts, loading: loadingContacts } = useDataSource('getContacts', {
	additionalFields: ['firstName', 'lastName'],
	text: ({ firstName, lastName }) => `${firstName} ${lastName}`,
})

const title = ref(null)
const subtitle = ref(null)
const address = ref(null)
const placeCity = ref(null)
const lat = ref(null)
const lng = ref(null)
const occupancy = ref(null)
const bathrooms = ref(null)
const bedrooms = ref(null)
const featuredImage = ref(null)
const featuredImageUrl = ref(null)
const politicalAreaId = ref(null)
const cityId = ref(null)
const brandId = ref(null)
const contactId = ref(null)
const loading = ref(false)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	title,
	subtitle,
	address,
	lat: computed({
		get: () => lat.value && parseFloat(lat.value),
		set: (value) => lat.value = value,
	}),
	lng: computed({
		get: () => lng.value && parseFloat(lng.value),
		set: (value) => lng.value = value,
	}),
	bathrooms: computed({
		get: () => bathrooms.value && parseFloat(bathrooms.value),
		set: (value) => bathrooms.value = value,
	}),
	bedrooms: computed({
		get: () => bedrooms.value && parseInt(bedrooms.value, 10),
		set: (value) => bedrooms.value = value,
	}),
	occupancy: computed({
		get: () => occupancy.value && parseInt(occupancy.value, 10),
		set: (value) => occupancy.value = value,
	}),
	featuredImage,
	politicalAreaId,
	cityId,
	brandId,
	contactId,
}, REQUIRED_FIELDS)

// Watchers
watch(politicalAreaId, async (value) => {
	if (!value) {
		return
	}
	fetchBrands({
		politicalAreaId: value,
	})
	await fetchCities({
		politicalAreaId: value,
	})

	if (placeCity.value) {
		linkCityFromPlace(placeCity.value)
	}
})

// Methods
async function onCreate() {
	loading.value = true
	const request = api.graphql()

	request.mutation('createCustomRentalProjectionAnalysis')
		.arg('input', form)
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createCustomRentalProjectionAnalysis')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateCustomRentalProjectionAnalysis')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('title', 'subtitle', 'address', 'lat', 'lng', 'occupancy', 'bedrooms', 'bathrooms', 'featuredImageUrl', 'politicalAreaId', 'cityId', 'brandId', 'contactId')
	
	const result = await request.exec()
	const { success, data } = result.get('updateCustomRentalProjectionAnalysis')
	if (success) {
		const { featuredImageUrl: updatedFeaturedImageUrl, ...rest } = data
		featuredImageUrl.value = updatedFeaturedImageUrl
		featuredImage.value = null
		loadOriginalData({
			...rest,
			featuredImage: null,
		})
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getCustomRentalProjectionAnalysis')
		.arg('id', id)
		.fields('title', 'subtitle', 'address', 'lat', 'lng', 'occupancy', 'bedrooms', 'bathrooms', 'featuredImageUrl', 'politicalAreaId', 'cityId', 'brandId', 'contactId')

	const result = await request.exec()
	const { data } = result.get('getCustomRentalProjectionAnalysis')

	const { featuredImageUrl: originalFeaturedImageUrl, ...rest } = data
	featuredImageUrl.value = originalFeaturedImageUrl
	loadOriginalData({
		...rest,
		featuredImage: null,
	})
}

function linkPoliticalAreaFromPlace(countryCode, politicalAreaCode) {
	const politicalArea = politicalAreas.value.find(({ code, country}) => code.toLowerCase() === politicalAreaCode.toLowerCase()
		&& country.toLowerCase() === countryCode.toLowerCase())
	if (politicalArea) {
		politicalAreaId.value = politicalArea.value
	}
}

function linkCityFromPlace(cityName) {
	const city = cities.value.find(({ text }) => text.toLowerCase() === cityName.toLowerCase())
	if (city) {
		cityId.value = city.value
	}
}

function onPlaceChanged(_, place) {
	const countryComponent = place.address_components.find(({ types }) => types.includes('country'))
	const politicalAreaComponent = place.address_components.find(({ types }) => types.includes('administrative_area_level_1'))
	const cityComponent = place.address_components.find(({ types }) => types.includes('locality'))

	lat.value = place.geometry.location.lat()
	lng.value = place.geometry.location.lng()
	address.value = place.formatted_address
	
	placeCity.value = cityComponent && cityComponent.short_name
	if (countryComponent && politicalAreaComponent) {
		linkPoliticalAreaFromPlace(countryComponent.short_name, politicalAreaComponent.short_name)
	}
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.custom-rpa-form {
		max-width: 600px;
	}
</style>