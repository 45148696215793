import BaseAddressAutocomplete from './BaseAddressAutocomplete.vue'
import BaseAutocomplete from './BaseAutocomplete.vue'
import BaseBreadcrumb from './BaseBreadcrumb.vue'
import BaseButton from './BaseButton.vue'
import BaseCard from './BaseCard.vue'
import BaseCardActions from './BaseCardActions.vue'
import BaseCardTitle from './BaseCardTitle.vue'
import BaseCardText from './BaseCardText.vue'
import BaseChip from './BaseChip.vue'
import BaseCol from './BaseCol.vue'
import BaseContainer from './BaseContainer.vue'
import BaseDataTable from './BaseDataTable.vue'
import BaseDialog from './BaseDialog.vue'
import BaseExpansionPanel from './BaseExpansionPanel.vue'
import BaseExpansionPanels from './BaseExpansionPanels.vue'
import BaseFileReader from './BaseFileReader.vue'
import BaseIcon from './BaseIcon.vue'
import BaseImageUpload from './BaseImageUpload.vue'
import BaseLink from './BaseLink.vue'
import BaseList from './BaseList.vue'
import BaseListItem from './BaseListItem.vue'
import BaseMenu from './BaseMenu.vue'
import BaseModal from './BaseModal.vue'
import BasePagination from './BasePagination.vue'
import BaseRadio from './BaseRadio.vue'
import BaseRadioGroup from './BaseRadioGroup.vue'
import BaseRow from './BaseRow.vue'
import BaseSelect from './BaseSelect.vue'
import BaseSkeleton from './BaseSkeleton.vue'
import BaseSnackbar from './BaseSnackbar.vue'
import BaseSwitch from './BaseSwitch.vue'
import BaseTextarea from './BaseTextarea.vue'
import BaseTextField from './BaseTextField.vue'

export default function addBaseComponents(app) {
	app.component('base-address-autocomplete', BaseAddressAutocomplete)
	app.component('base-autocomplete', BaseAutocomplete)
	app.component('base-breadcrumb', BaseBreadcrumb)
	app.component('base-button', BaseButton)
	app.component('base-card', BaseCard)
	app.component('base-card-actions', BaseCardActions)
	app.component('base-card-title', BaseCardTitle)
	app.component('base-card-text', BaseCardText)
	app.component('base-chip', BaseChip)
	app.component('base-col', BaseCol)
	app.component('base-container', BaseContainer)
	app.component('base-data-table', BaseDataTable)
	app.component('base-dialog', BaseDialog)
	app.component('base-expansion-panel', BaseExpansionPanel)
	app.component('base-expansion-panels', BaseExpansionPanels)
	app.component('base-file-reader', BaseFileReader)
	app.component('base-icon', BaseIcon)
	app.component('base-image-upload', BaseImageUpload)
	app.component('base-link', BaseLink)
	app.component('base-list', BaseList)
	app.component('base-list-item', BaseListItem)
	app.component('base-menu', BaseMenu)
	app.component('base-modal', BaseModal)
	app.component('base-pagination', BasePagination)
	app.component('base-radio', BaseRadio)
	app.component('base-radio-group', BaseRadioGroup)
	app.component('base-row', BaseRow)
	app.component('base-select', BaseSelect)
	app.component('base-skeleton', BaseSkeleton)
	app.component('base-snackbar', BaseSnackbar)
	app.component('base-switch', BaseSwitch)
	app.component('base-textarea', BaseTextarea)
	app.component('base-text-field', BaseTextField)
}
