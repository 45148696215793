<template>
	<base-card class="login-card" :width="props.width" :height="props.height">
		<base-card-title>
			<img
				:src="require('@/assets/logo.png')"
				style="margin: 10px auto;"
			/>
		</base-card-title>
		<base-card-text>
			<div v-if="!twoFactor">
				<base-text-field v-model="username" label="Username" />
				<base-text-field v-model="password" type="password" label="Password" />
				<base-button block color="primary" :loading="loading" @click="onLogin">Login</base-button>
			</div>
			<div v-else>
				<base-text-field v-model="verificationToken" label="2FA token" />
				<base-button block color="primary" :loading="loading" @click="onLogin">Confirm</base-button>
			</div>
		</base-card-text>
	</base-card>
</template>

<script setup>
import { ref } from 'vue'
import { useApi } from '@/plugins/api'
import { useStore } from '@/plugins/store'

const api = useApi()
const store = useStore()

// Props
const props = defineProps({
	width: {
		type: [Number, String],
	},
	height: {
		type: [Number, String],
	}
})

// Emits
const emit = defineEmits(['login'])

// Data
const username = ref()
const password = ref()
const verificationToken = ref()
const twoFactor = ref(false)
const loading = ref(false)

// Methods
async function onLogin() {
	loading.value = true

	const request = api.graphql()
	request.mutation('login')
		.arg('input', {
			username: username.value,
			password: password.value,
			...(twoFactor.value ? { verificationToken: verificationToken.value } : {}),
		})
		.fields(['refreshToken', 'accessToken', 'accessExpiration', 'requiresTwoFactor'])

	const results = await request.exec()
	const { success, data } = results.get('login')
	
	if (success) {
		if (data.requiresTwoFactor) {
			twoFactor.value = true
			loading.value = false
			return
		}
		store.dispatch('session/login', data)
	}

	emit('login', success)
	loading.value = false
}
</script>