<template>
	<div class="view-complex">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<complex-form :id="complexId" :political-area="politicalAreaId" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import ComplexForm from '@/components/fragments/complex/ComplexForm'

import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const savedListingQuery = useSavedDataTableRouteState('complexes')
const politicalAreaId = computed(() => route.params.location)
const complexId = computed(() => route.params.complex)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Complexes',
	to: {
		name: 'complexes',
		query: savedListingQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getComplex',
	id: complexId.value,
	field: 'name',
}])
</script>