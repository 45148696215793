<template>
	<base-data-table
		:headers="props.headers"
		:items="items"
		:items-per-page="25"
		:loading="loading"
		fixed-footer
		:options.sync="options"
		:server-items-length="total"
		:sort-by="props.sortBy"
		:sort-desc="props.sortDesc"
	>
		<template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
			<slot :name="scopedSlotName" v-bind="slotData" />
		</template>
		<template v-for="(_, slotName) in $slots" #[slotName]>
			<slot :name="slotName" />
		</template>
	</base-data-table>
</template>

<script setup>
import { computed, watch } from 'vue'

import useDataTableGraphQLRequest from '@/features/useDataTableGraphQLRequest'

const props = defineProps({
	refresh: {
		type: Boolean,
		default: false,
	},
	sortBy: {
		type: [Array, String],
	},
	sortDesc: {
		type: Boolean,
	},
	filters: {
		type: Object,
		default: () => {},
	},
	page: {
		type: Number,
	},
	getCb: {
		type: Function,
	},
	headers: {
		type: [Object, Array],
		required: true,
	},
	getFn: {
		type: String,
		required: true,
	},
	fields: {
		type: Array,
		required: true,
	},
	countFn: {
		type: String,
		required: true,
	},
	countCb: {
		type: Function,
	},
})
const emit = defineEmits(['update:refresh', 'update:page'])

// Data
const { items, loading, options, total, refreshData } = useDataTableGraphQLRequest({
	getFn: props.getFn,
	getCb: props.getCb,
	countFn: props.countFn,
	countCb: props.countCb,
	fields: props.fields,
	filters: computed(() => props.filters),
	page: props.page,
})

// Computed
const needsRefresh = computed(() => props.refresh)

// Watchers
watch(needsRefresh, (value, oldValue) => {
	if (value !== oldValue && value === true) {
		refreshData(true)
		emit('update:refresh', false)
	}
})
watch(options, (value, oldValue) => {
	if (!oldValue) { return }
	if (value.page !== oldValue.page) {
		emit('update:page', value.page)
	}
})
</script>