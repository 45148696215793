<template>
	<span class="base-pagination">
		<v-pagination v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners" />
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>







