<template>
	<div class="view-new-realtor">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<realtor-form new :political-area="politicalAreaId" @create="onCreated" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import RealtorForm from '@/components/fragments/realtor/RealtorForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute, useRouter } from '@/plugins/router'

// Modules
const route = useRoute()
const router = useRouter()

// Computed
const politicalAreaId = computed(() => route.params.location)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Realtors',
	to: {
		name: 'realtors',
	},
}, {
	text: 'New Realtor',
}])

function onCreated() {
	router.push({ name: 'realtors' })
}
</script>