import { ref } from 'vue'

import { useApi } from '@/plugins/api'

export default function(fn, {
	text = 'name',
	value = '_id',
	query: initQuery = {},
	manualOnly = false,
	usePagination = true,
	additionalFields = [],
} = {}) {
	// Data
	const api = useApi()

	const entries = ref([])
	const loading = ref(false)

	// Methods
	async function fetchEntries(query) {
		loading.value = true
		entries.value = []
		let batchEntries = []
		let skip = 0
	
		const fields = [...additionalFields]
		if (typeof text === 'string') {
			fields.push(text)
		}
		if (typeof value === 'string') {
			fields.push(value)
		}

		do {
			const request = api.graphql()
			request.query(fn)
				.arg('query', {
					...(usePagination ? {
						limit: 100,
						skip,
					} : {}),
					...query,
				})
				.fields(fields)
	
			const result = await request.exec()
			const { data } = result.get(fn)
			batchEntries = data.map((entry) => ({
				text: typeof text === 'function' ? text(entry) : entry[text],
				value: typeof value === 'function' ? value(entry) : entry[value],
				...additionalFields.reduce((acc, field) => ({
					...acc,
					[field]: entry[field],
				}), {}),
			}))
			entries.value = [...entries.value, ...batchEntries]
			skip += 100
		} while (usePagination && batchEntries.length === 100)
	
		loading.value = false
	}

	// Init if not manual calls only
	if (!manualOnly) {
		fetchEntries(initQuery)
	}
	

	return {
		entries,
		loading,
		fetchEntries,
	}
}
