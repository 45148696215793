<template>
	<div class="listings-validation">
		<div class="text-center">
			<base-link :to="{ name: 'listingsSent' }">Sent Listings Report</base-link>
		</div>
		<h1 class="text-center text-subtitle-2 text-sm-h4">Rental Revenue Validation</h1>
		<listings-validation-list :representative="representativeId" @failed="notFound" />
	</div>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount } from 'vue'

import { useRouter, useRoute } from '@/plugins/router'

import ListingsValidationList from '@/components/fragments/listing/ListingsValidationList'

// Data
const router = useRouter()
const route = useRoute()

// Computed
const representativeId = computed(() => route.params.representative)

function notFound() {
	router.replace('/404')
}

onMounted(() => {
	document.body.classList.add('public')
	if (!representativeId.value) {
		notFound()
	}
})

onBeforeUnmount(() => {
	document.body.classList.remove('public')
})
</script>