import Vue from 'vue'
import App from './App.vue'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

import vuetify from './plugins/vuetify'
import createStore from './plugins/store'
import createRouter from './plugins/router'
import createApi from './plugins/api'

import addBaseComponents from './components/base'

Vue.use(VueMask)

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyBldXe84hZXDYz3m3jk_a4Eo9EdFaO_ays',
		libraries: 'places',
	},
	installComponents: true
})

Vue.use(VuetifyGoogleAutocomplete, {
	vueGoogleMapsCompatibility: true,
})

Vue.config.productionTip = false

const store = createStore(Vue)
const router = createRouter(Vue)
createApi(Vue, {
	router,
	store,
	storeKey: 'session/isAuth',
})
addBaseComponents(Vue)

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
