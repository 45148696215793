<template>
	<div class="view-realtor">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[0]">
			<base-expansion-panel title="Contact Informations">
				<realtor-form :id="realtorId" :political-area="politicalAreaId" />
			</base-expansion-panel>
			<base-expansion-panel title="Aliases">
				<realtor-aliases-table :id="realtorId" />
			</base-expansion-panel>
			<base-expansion-panel title="Additional Emails">
				<realtor-additional-emails-table :id="realtorId" />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import RealtorForm from '@/components/fragments/realtor/RealtorForm'
import RealtorAliasesTable from '@/components/fragments/realtor/RealtorAliasesTable'
import RealtorAdditionalEmailsTable from '@/components/fragments/realtor/RealtorAdditionalEmailsTable'

import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const savedListingQuery = useSavedDataTableRouteState('realtors')
const politicalAreaId = computed(() => route.params.location)
const realtorId = computed(() => route.params.realtor)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Realtors',
	to: {
		name: 'realtors',
		query: savedListingQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getRealtor',
	id: realtorId.value,
	field: 'name',
}])
</script>