<template>
	<span class="base-skeleton">
		<v-skeleton-loader v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-skeleton-loader>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	tile: true,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>