<template>
	<div class="listing-metrics">
		<div class="listing-metrics-summary">
			<base-row>
				<base-col cols="6" sm="3">
					<base-card>
						<base-card-title>
							<div>Total Delivered</div>
						</base-card-title>
						<base-card-text>
							<div class="text-h4 primary--text">{{metrics.delivered || 0}}</div>
						</base-card-text>
					</base-card>
				</base-col>
				<base-col cols="6" sm="3">
					<base-card>
						<base-card-title>
							<div>Total Opens</div>
						</base-card-title>
						<base-card-text>
							<div class="text-h4 primary--text">{{metrics.open || 0}}</div>
						</base-card-text>
					</base-card>
				</base-col>
				<base-col cols="6" sm="3">
					<base-card>
						<base-card-title>
							<div>Total Clicks</div>
						</base-card-title>
						<base-card-text>
							<div class="text-h4 primary--text">{{metrics.click || 0}}</div>
						</base-card-text>
					</base-card>
				</base-col>
				<base-col cols="6" sm="3">
					<base-card>
						<base-card-title>
							<div>Unsubscribed</div>
						</base-card-title>
						<base-card-text>
							<div v-if="metrics.unsubscribe" class="text-h4 error--text">Yes</div>
							<div v-else class="text-h4 primary--text">No</div>
						</base-card-text>
					</base-card>
				</base-col>
			</base-row>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useApi } from '@/plugins/api'

// Props & Emit
const props = defineProps({
	id: {
		type: String,
		required: true,
	}
})

// Plugins
const api = useApi()

// Data
const metrics = ref({})

// Methods
async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getListing')
		.arg('id', id)
		.child('metrics')
		.fields('sent', 'delivered', 'open', 'click', 'spam', 'unsubscribe', 'softbounce', 'hardbounce', 'deferred')

	const result = await request.exec()
	const { data } = result.get('getListing')
	metrics.value = data.metrics
}

onMounted(async () => {
	fetchEntry(props.id)
})

</script>