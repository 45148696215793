<template>
	<base-dialog :value="props.value" persistent :max-width="props.maxWidth">
		<base-card>
			<base-card-title :class="titleClass">{{ props.title }}</base-card-title>
			<base-card-text class="pt-8">
				<slot></slot>
			</base-card-text>
			<base-card-actions right>
				<template v-for="(action, i) in actions">
					<base-button
						:key="`modal-action-${i}`"
						text
						:color="action.color"
						:disabled="action.disabled"
						:loading="action.loading"
						@click="onClick(action.value)"
					>{{action.text}}</base-button>
				</template>
			</base-card-actions>
		</base-card>
	</base-dialog>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	maxWidth: {
		type: [Number, String],
		default: 300,
	},
	title: {
		type: String,
		required: true,
	},
	value: {
		type: Boolean,
		required: true,
	},
	color: {
		type: String,
		default: 'primary'
	},
	textColor: {
		type: String,
		default: 'white',
	},
	actions: {
		type: Array,
		default: () => [],
	}
})

const emit = defineEmits(['click'])

const titleClass = computed(() => ({
	[props.color]: true,
	[`${props.textColor}--text`]: true,
}))

function onClick(value) {
	emit('click', value)
}
</script>
