export default class Rest {
	#repository 		// Repository

	/**
	 * @constructor
	 * @param {object} repository - Repository
	 */
	constructor(repository) {
		this.#repository = repository
	}

	/**
	 * Send request
	 * @param {string} method - Method
	 * @param {string} endpoint - Base URL
	 * @param {object} options - Options
	 * @returns {Promise<object>} - Success, status code and data
	 */
	async #sendRequest(method, endpoint, options) {
		try {
			const result = await this.#repository[method](endpoint, options)
			return {
				success: true,
				status: result.status,
				data: result.response,
			}
		} catch(err) {
			if (err.response) {
				return {
					success: false,
					status: err.status,
					data: err.response,
				}
			}

			throw err
		}
	}

	/**
	 * Get request
	 * @param {string} endpoint - Base URL
	 * @param {object} options - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @returns {Promise<object>} - Status code and response
	 */
	get(endpoint, options = {}) {
		return this.#sendRequest('get', endpoint, options)
	}

	/**
	 * Put request
	 * @param {string} endpoint - Base URL
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @param {object} [options.body] - Body/Payload/Data
	 * @returns {Promise<object>} - Status code and response
	 */
	put(endpoint, options = {}) {
		return this.#sendRequest('put', endpoint, options)
	}

	/**
	 * Post request
	 * @param {string} endpoint - Base URL
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @param {object} [options.body] - Body/Payload/Data
	 * @returns {Promise<object>} - Status code and response
	 */
	post(endpoint, options = {}) {
		return this.#sendRequest('post', endpoint, options)
	}

	/**
	 * Delete request
	 * @param {string} endpoint - Base URL
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @returns {Promise<object>} - Status code and response
	 */
	delete(endpoint, options = {}) {
		return this.#sendRequest('delete', endpoint, options)
	}
}
