var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"area-form"},[_c('div',{staticClass:"area-form-data"},[_c('base-text-field',{attrs:{"label":"Name*"},model:{value:(_setup.name),callback:function ($$v) {_setup.name=$$v},expression:"name"}}),_c('base-text-field',{attrs:{"label":"Website URL"},model:{value:(_setup.uriName),callback:function ($$v) {_setup.uriName=$$v},expression:"uriName"}}),_c('base-image-upload',{attrs:{"src":_setup.featuredImageUrl,"width":"350","height":"233","label":"Featured Image (350x233)","resize":""},model:{value:(_setup.featuredImage),callback:function ($$v) {_setup.featuredImage=$$v},expression:"featuredImage"}}),_c('base-autocomplete',{attrs:{"loading":_setup.loadingCities,"items":_setup.cities,"label":"City*"},model:{value:(_setup.cityId),callback:function ($$v) {_setup.cityId=$$v},expression:"cityId"}})],1),(_setup.center)?_c('div',{staticClass:"map"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"500px","max-width":"800px"},attrs:{"zoom":_setup.zoom,"center":_setup.center},on:{"update:zoom":function($event){_setup.zoom=$event}}},[(_setup.cityPaths.length > 0)?_c('gmap-polygon',{ref:"polygon",attrs:{"options":{
					strokeColor: '#8ecb64',
					fillColor: '#8ecb64',
				},"paths":_setup.cityPaths}}):_vm._e(),_vm._l((_setup.otherAreasPaths),function(areaPaths,i){return _c('gmap-polygon',{key:`area-polygon-${i}`,attrs:{"options":{
					strokeColor: '#6a6a6a',
					fillColor: '#6a6a6a',
				},"title":"ABC","paths":areaPaths}})}),(_setup.paths.length > 0)?_c('gmap-polygon',{ref:"polygon",attrs:{"options":{
					strokeColor: '#94348c',
					fillColor: '#94348c',
				},"paths":_setup.paths,"editable":true},on:{"mousedown":_setup.onAreaMouseDown,"paths_changed":function($event){return _setup.updateEdited($event)},"rightclick":_setup.handleClickForDelete}}):_vm._e()],2)],1):_vm._e(),_c('div',{staticClass:"area-form-data mt-4"},[_c('base-button',{attrs:{"block":"","color":"primary","loading":_setup.loading,"disabled":(_setup.props.new ? !_setup.isFilled : (!_setup.hasChanges && !_setup.coordinatesChanged)) || !_setup.coordinates.length},on:{"click":function($event){_setup.props.new ? _setup.onCreate() : _setup.onSaveChanges(_setup.props.id)}}},[_vm._v(" "+_vm._s(_setup.props.new ? 'Create' : 'Save Changes')+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }