<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.user`]="{ item }">
			{{ item.getUser.firstName }} {{ item.getUser.lastName }} ({{item.getUser.username}})
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'User',
	value: 'user',
	sortable: false,
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'name',
	headers,
	filters,
	getFn: 'getAccessKeys',
	getCb: (query) => {
		query.child('getUser').fields('firstName', 'lastName', 'username')
	},
	countFn: 'countAccessKeys',
	fields: ['_id', 'name'],
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>
