<template>
	<generic-app-header
		:breadcrumb="props.breadcrumb"
		color="primary"
		action="Add Complex"
		action-icon="mdi-plus-circle-outline"
		permission="location:complex:*.create"
		:actions-loading="importing"
		:to="{ name: 'newComplex' }"
	>
		<template v-slot:actions>
			<base-list-item
				v-if="can('location:complex:*.create') && can('location:complex:*.update')"
				prepend-icon="mdi-import"
				@click="showFileImport = true"
			>Import</base-list-item>
			<base-list-item prepend-icon="mdi-export" @click="onExport">Export</base-list-item>
			<base-file-reader :show.sync="showFileImport" type="csv" @load="onImport" />
		</template>
	</generic-app-header>
</template>

<script setup>
import { ref } from 'vue'

import GenericAppHeader from '../GenericAppHeader.vue'

import useExport from '@/features/useExport'
import useImport from '@/features/useImport'
import useSession from '@/features/useSession'

// props & Emits
const props = defineProps({
	breadcrumb: {
		type: Array,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['import'])

// Data
const importing = ref(false)
const showFileImport = ref(false)
const FIELDS = ['_id', 'name', 'uriName', 'h2', 'description', 'address', 'long', 'lat']

// Methods
const { can } = useSession()

const exportComplexes = useExport({
	fn: 'getComplexes',
	queryCb: (query) => {
		query.child('getArea').fields('name').child('getCity').fields('name')
	},
	fields: FIELDS,
	headers: ['_id', 'name', {
		text: 'city',
		value: (entry) => entry.getArea && entry.getArea.getCity && entry.getArea.getCity.name,
	}, {
		text: 'area',
		value: (entry) => entry.getArea && entry.getArea.name,
	}, ...FIELDS.filter((field) => !['areaId', 'name', '_id'].includes(field))],
	format: 'csv',
})

const importComplexes = useImport({
	fn: 'updateComplex',
	strategy: 'update',
	fields: FIELDS,
	format: 'csv',
})

function onExport() {
	exportComplexes('complexes', {
		filters: {
			politicalAreaId: props.politicalArea,
			sort: ['name'],
		},
	})
}

async function onImport(data) {
	importing.value = true
	const result = await importComplexes(data)
	importing.value = false
	emit('import', result)
}
</script>