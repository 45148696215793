<template>
	<div class="city-images-form">
		<base-image-upload
			v-model="marketGrade"
			:src="marketGradeUrl"
			width="870"
			height="115"
			label="Market Grade Image (870x115)"
			resize
		/>
		<base-image-upload
			v-model="rentalRevenue"
			:src="rentalRevenueUrl"
			width="870"
			height="310"
			label="Rental Revenue Image (870x310)"
			resize
		/>
		<base-image-upload
			v-model="rentalMap"
			:src="rentalMapUrl"
			width="870"
			height="470"
			label="Rental Map Image (870x470)"
			resize
		/>
		<base-image-upload
			v-model="historicalOccupancy"
			:src="historicalOccupancyUrl"
			width="870"
			height="310"
			label="Historical Occupancy Rates Image (870x310)"
			resize
		/>
		<base-image-upload
			v-model="activeListings"
			:src="activeListingsUrl"
			width="870"
			height="310"
			label="Active Listings Image (870x310)"
			resize
		/>
		<base-image-upload
			v-model="adr"
			:src="adrUrl"
			width="870"
			height="310"
			label="ADR Image (870x310)"
			resize
		/>
		<base-image-upload
			v-model="adrRange"
			:src="adrRangeUrl"
			width="870"
			height="310"
			label="ADR Range Image (870x310)"
			resize
		/>
		<base-image-upload
			v-model="avgDaysToBook"
			:src="avgDaysToBookUrl"
			width="870"
			height="200"
			label="Average Days To Book Image (870x200)"
			resize
		/>
		<base-image-upload
			v-model="seasonalityCalendar"
			:src="seasonalityCalendarUrl"
			width="870"
			height="780"
			label="Seasonality Calendar Image (870x780)"
			resize
		/>
		<base-button
			block
			color="primary"
			:loading="loading"
			:disabled="!hasChanges"
			@click="onSaveChanges(props.id)"
		>
			Save Changes
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	politicalArea: {
		type: String,
	}
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const marketGrade = ref(null)
const rentalRevenue = ref(null)
const rentalMap = ref(null)
const historicalOccupancy = ref(null)
const activeListings = ref(null)
const adr = ref(null)
const adrRange = ref(null)
const avgDaysToBook = ref(null)
const seasonalityCalendar = ref(null)

const marketGradeUrl = ref(null)
const rentalRevenueUrl = ref(null)
const rentalMapUrl = ref(null)
const historicalOccupancyUrl = ref(null)
const activeListingsUrl = ref(null)
const adrUrl = ref(null)
const adrRangeUrl = ref(null)
const avgDaysToBookUrl = ref(null)
const seasonalityCalendarUrl = ref(null)

const loading = ref(false)

const { changes, hasChanges, loadOriginalData } = useFormStates({
	marketGrade,
	rentalRevenue,
	rentalMap,
	historicalOccupancy,
	activeListings,
	adr,
	adrRange,
	avgDaysToBook,
	seasonalityCalendar,
})

// Methods
async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateCity')
		.arg('id', id)
		.arg('input', {
			marketImages: changes.value
		})
		.child('marketImages')
		.fields(
			'marketGradeUrl',
			'rentalRevenueUrl',
			'rentalMapUrl',
			'historicalOccupancyUrl',
			'activeListingsUrl',
			'adrUrl',
			'adrRangeUrl',
			'avgDaysToBookUrl',
			'seasonalityCalendarUrl',
		)
	
	const result = await request.exec()
	const { success, data } = result.get('updateCity')
	if (success) {
		const {
			marketImages
		} = data

		marketGradeUrl.value = marketImages.marketGradeUrl
		rentalRevenueUrl.value = marketImages.rentalRevenueUrl
		rentalMapUrl.value = marketImages.rentalMapUrl
		historicalOccupancyUrl.value = marketImages.historicalOccupancyUrl
		activeListingsUrl.value = marketImages.activeListingsUrl
		adrUrl.value = marketImages.adrUrl
		adrRangeUrl.value = marketImages.adrRangeUrl
		avgDaysToBookUrl.value = marketImages.avgDaysToBookUrl
		seasonalityCalendarUrl.value = marketImages.seasonalityCalendarUrl

		marketGrade.value = null
		rentalRevenue.value = null
		rentalMap.value = null
		historicalOccupancy.value = null
		activeListings.value = null
		adr.value = null
		adrRange.value = null
		avgDaysToBook.value = null
		seasonalityCalendar.value = null

		loadOriginalData({
			marketGrade: null,
			rentalRevenue: null,
			rentalMap: null,
			historicalOccupancy: null,
			activeListings: null,
			adr: null,
			adrRange: null,
			avgDaysToBook: null,
			seasonalityCalendar: null,
		})
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getCity')
		.arg('id', id)
		.child('marketImages')
		.fields(
			'marketGradeUrl',
			'rentalRevenueUrl',
			'rentalMapUrl',
			'historicalOccupancyUrl',
			'activeListingsUrl',
			'adrUrl',
			'adrRangeUrl',
			'avgDaysToBookUrl',
			'seasonalityCalendarUrl',
		)

	const result = await request.exec()
	const { data } = result.get('getCity')

	const {
		marketImages
	} = data

	marketGradeUrl.value = marketImages.marketGradeUrl
	rentalRevenueUrl.value = marketImages.rentalRevenueUrl
	rentalMapUrl.value = marketImages.rentalMapUrl
	historicalOccupancyUrl.value = marketImages.historicalOccupancyUrl
	activeListingsUrl.value = marketImages.activeListingsUrl
	adrUrl.value = marketImages.adrUrl
	adrRangeUrl.value = marketImages.adrRangeUrl
	avgDaysToBookUrl.value = marketImages.avgDaysToBookUrl
	seasonalityCalendarUrl.value = marketImages.seasonalityCalendarUrl

	marketGrade.value = null
	rentalRevenue.value = null
	rentalMap.value = null
	historicalOccupancy.value = null
	activeListings.value = null
	adr.value = null
	adrRange.value = null
	avgDaysToBook.value = null
	seasonalityCalendar.value = null

	loadOriginalData({
		marketGrade: null,
		rentalRevenue: null,
		rentalMap: null,
		historicalOccupancy: null,
		activeListings: null,
		adr: null,
		adrRange: null,
		avgDaysToBook: null,
		seasonalityCalendar: null,
	})
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.city-images-form {
		max-width: 870px;
	}
</style>