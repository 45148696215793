// Use in conjunction with data table
import { ref, watch } from 'vue'

import { useApi } from '@/plugins/api'

/**
 * Provide GraphQL requests logic and refs to use in data table
 * @param {object} settings - Settings
 * @param {string} settings.getFn - Getter function name
 * @param {function} [settings.getCb] - Getter callback to append request details
 * @param {string} settings.countFn - Count function name
 * @param {function} [settings.countCb] - Count function name
 * @param {Array<string>} settings.fields - Fields
 * @param {function} [settings.parentQuery] - Parent query function
 * @param {function} [settings.parentResult] - Parent result function [use with parentQuery]
 * @param {string} [settings.api='default'] - API to use
 * @param {object} [settings.filters] - Reactive Filters
 */
export default function({
	getFn,
	getCb,
	countFn,
	countCb,
	fields,
	parentQuery,
	parentResult,
	api: apiName = 'default',
	filters: settingsFilters,
	page,
}) {
	// Variables
	let disableOptionsWatcher = false

	// Data
	const api = useApi()
	const items = ref([])
	const loading = ref(false)
	const options = ref()
	const total = ref(-1)
	const filters = settingsFilters || ref({})

	// Watchers
	watch(options, async (value, oldValue) => {
		if (!disableOptionsWatcher) {
			if (!oldValue && page > 1) {
				disableOptionsWatcher = true
				await fetchItems({
					...value,
					page,
				}, filters.value, total.value === -1)
				options.value.page = page
				setTimeout(() => {
					disableOptionsWatcher = false
				}, 0)
			} else {
				await fetchItems(value, filters.value, total.value === -1)
			}
		}
	}, {
		deep: true,
	})
	watch(filters, async (value) => {
		disableOptionsWatcher = true
		options.value.page = 1
		await fetchItems(options.value, value, true)
		disableOptionsWatcher = false
	}, {
		deep: true,
	})

	// Methods
	function optionsToQuery({ itemsPerPage, page, sortBy, sortDesc }, filters) {
		return {
			limit: itemsPerPage,
			page,
			...filters,
			...(sortBy && sortBy.length ? {
				sort: sortBy.map((by, i) => `${sortDesc && sortDesc[i] ? '-' : ''}${by}`)
			}: {})
		}
	}

	async function fetchItems(options, filters, fetchCount = false) {
		loading.value = true

		const query = optionsToQuery(options, filters)
		const request = api.graphql(apiName)
		const parentRequest = parentQuery && parentQuery(request)
		const getQuery = (parentQuery ? parentRequest.child(getFn) : request.query(getFn))
			.arg('query', query)
			.fields(...fields)
		
		if (getCb) {
			getCb(getQuery)
		}

		if (fetchCount && !query.search) {
			const countQuery = (parentQuery ? parentRequest.child(countFn) : request.query(countFn))
				.arg('query', filters)
				.fields('count')
			if (countCb) {
				countCb(countQuery)
			}
		}
	
		const result = await request.exec()
		const { data } = parentResult ? parentResult(result, getFn) : result.get(getFn)
		let count
	
		if (fetchCount) {
			if (query.search) {
				count = data.length
			} else {
				({ data: { count }} = parentResult ? parentResult(result, countFn) : result.get(countFn))
			}
		}

		items.value = data
		if (typeof count !== 'undefined') {
			total.value = count
		}

		loading.value = false
	}

	function refreshData(refreshCount = false) {
		fetchItems(options.value, filters.value, refreshCount)
	}

	return {
		items,
		loading,
		options,
		total,
		refreshData,
	}
}
