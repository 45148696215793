import axios from 'axios'

export default class ApiRepository {
	#axios		// Axios instance
	#graphql	// GraphQL endpoint

	/**
	 * @constructor
	 * @param {string} baseURL - Base URL
	 * @param {string} [options] - Options
	 * @param {string} [options.graphql] - GraphQL endpoint
	 */
	constructor(baseURL, {
		graphql
	} = {}) {
		this.#axios = axios.create({
			baseURL,
		})
		this.#graphql = graphql
	}

	/**
	 * Get request
	 * @param {string} endpoint - Base URL
	 * @param {object} options - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @param {object} [options.body] - Body/Payload/Data
	 * @param {object} [options.format] - Format
	 * @param {object} [options.errorAsResponse] - Send error as normal response
	 */
	async #sendRequest(method, endpoint, {
		headers,
		query,
		body,
	} = {}) {
		const { data, status } = await this.#axios({
			method,
			url: endpoint,
			headers,
			params: query,
			data: body,
		})

		return {
			response: data,
			status,
		}
	}

	/**
	 * Get request
	 * @param {string} endpoint - Base URL
	 * @param {object} options - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 */
	get(endpoint, {
		headers,
		query,
	} = {}) {
		return this.#sendRequest('get', endpoint, {
			headers,
			query,
		})
	}

	/**
	 * Put request
	 * @param {string} endpoint - Base URL
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @param {object} [options.body] - Body/Payload/Data
	 */
	put(endpoint, {
		headers,
		query,
		body,
	} = {}) {
		return this.#sendRequest('put', endpoint, {
			headers,
			query,
			body,
		})
	}

	/**
	 * Post request
	 * @param {string} endpoint - Base URL
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 * @param {object} [options.body] - Body/Payload/Data
	 */
	post(endpoint, {
		headers,
		query,
		body,
	} = {}) {
		return this.#sendRequest('post', endpoint, {
			headers,
			query,
			body,
		})
	}

	/**
	 * Delete request
	 * @param {string} endpoint - Base URL
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 * @param {object} [options.query] - Query
	 */
	delete(endpoint, {
		headers,
		query,
	} = {}) {
		return this.#sendRequest('delete', endpoint, {
			headers,
			query,
		})
	}

	/**
	 * GraphQL request
	 * @param {string} payload - Payload
	 * @param {object} [options] - Options
	 * @param {object} [options.headers] - Headers
	 */
	graphql(payload, {
		headers
	} = {}) {
		if (!this.#graphql) {
			throw new Error('GraphQL endpoint not provided')
		}
		return this.#sendRequest('post', this.#graphql, {
			headers,
			body: payload,
		})
	}

	/**
	 * Set default header
	 * @param {string} header - Header name
	 * @param {string} [value] - Header value (not passing it will unset the default header)
	 */
	setDefaultHeader(header, value) {
		if (!value) {
			delete this.#axios.defaults.headers.common[header]
		} else {
			this.#axios.defaults.headers.common[header] = value
		}
	}
}
