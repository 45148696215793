var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"view-representatives"},[_c('base-card',{staticClass:"mb-4"},[_c('base-card-text',{staticClass:"pa-0"},[_c('base-container',{attrs:{"fluid":""}},[_c('base-row',[_c('base-col',{attrs:{"align-self":"center"}},[_c('base-breadcrumb',{attrs:{"items":_setup.breadcrumb}})],1),(_setup.can('realestate:representative:*.create'))?_c('base-col',{staticClass:"pa-0",attrs:{"align":"right"}},[_c('base-button',{staticClass:"ma-0",attrs:{"color":"primary","height":"46px","to":{ name: 'newRepresentative' }}},[_c('base-icon',[_vm._v("mdi-plus-circle-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Add Representative")])],1)],1):_vm._e()],1)],1)],1)],1),_c(_setup.RepresentativesTable,{attrs:{"market":_setup.marketId,"refresh":_setup.refresh},on:{"update:refresh":function($event){_setup.refresh=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('base-button',{attrs:{"target":"_blank","to":{
					name: 'listingsValidation',
					params: { representative: item._id }
				},"color":"primary","text":"","small":""}},[_vm._v("Access")]),(_setup.can(`realestate:representative:${item._id}.update`))?_c('base-button',{attrs:{"to":{
					name: 'representative',
					params: { representative: item._id }
				},"color":"primary","text":"","small":""}},[_vm._v("Edit")]):_vm._e(),(_setup.can(`realestate:representative:${item._id}.delete`))?_c('base-button',{attrs:{"color":"error","text":"","small":"","loading":_setup.deletionDialog.show && _setup.deletionDialog.id === item._id},on:{"click":function($event){return _setup.showDeletionDialog(item._id, `${item.firstName} ${item.lastName}`)}}},[_vm._v("Delete")]):_vm._e()]}}])}),_c(_setup.RepresentativeDeletionModal,{attrs:{"text":_setup.deletionDialog.text,"id":_setup.deletionDialog.id},on:{"delete":function($event){_setup.refresh = true}},model:{value:(_setup.deletionDialog.show),callback:function ($$v) {_vm.$set(_setup.deletionDialog, "show", $$v)},expression:"deletionDialog.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }