<template>
	<div class="area-form">
		<div class="area-form-data">
			<base-text-field v-model="name" label="Name*" />
			<base-text-field v-model="uriName" label="Website URL" />
			<base-image-upload
				v-model="featuredImage"
				:src="featuredImageUrl"
				width="350"
				height="233"
				label="Featured Image (350x233)"
				resize
			/>
			<base-autocomplete v-model="cityId" :loading="loadingCities" :items="cities" label="City*" />
		</div>
		<div v-if="center" class="map">
			<gmap-map
				:zoom.sync="zoom"
				:center="center"
				style="width: 100%; height: 500px; max-width: 800px;"
				ref="map"
			>
				<gmap-polygon
					v-if="cityPaths.length > 0"
					ref="polygon"
					:options="{
						strokeColor: '#8ecb64',
						fillColor: '#8ecb64',
					}"
					:paths="cityPaths"
				>
				</gmap-polygon>
				<gmap-polygon
					v-for="(areaPaths, i) in otherAreasPaths"
					:key="`area-polygon-${i}`"
					:options="{
						strokeColor: '#6a6a6a',
						fillColor: '#6a6a6a',
					}"
					title="ABC"
					:paths="areaPaths"
				>
				</gmap-polygon>
				<gmap-polygon
					v-if="paths.length > 0"
					ref="polygon"
					:options="{
						strokeColor: '#94348c',
						fillColor: '#94348c',
					}"
					:paths="paths"
					:editable="true"
					@mousedown="onAreaMouseDown"
					@paths_changed="updateEdited($event)"
					@rightclick="handleClickForDelete"
				>
				</gmap-polygon>
			</gmap-map>
		</div>
		<div class="area-form-data mt-4">
			<base-button
				block
				color="primary"
				:loading="loading"
				:disabled="(props.new ? !isFilled : (!hasChanges && !coordinatesChanged)) || !coordinates.length"
				@click="props.new ? onCreate() : onSaveChanges(props.id)"
			>
				{{ props.new ? 'Create' : 'Save Changes' }}
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'
import useGeoJSON from '@/features/useGeoJSON'
import { useMap, usePolygon } from '@/features/googleMapsUtils'

const REQUIRED_FIELDS = ['name', 'cityId']
const OTHER_AREAS_FETCH_LIMIT = 10
const SNAP_TRESHOLD = 1.5 // This value is based on the initial zoom level
const DEFAULT_ZOOM_LEVEL = 11
const NEW_ZOOM_LEVEL = 12

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const map = ref()	// Map ref
const polygon = ref()	// Map polygon ref

const name = ref(null)
const uriName = ref()
const currentCityId = ref()
const cityId = ref(null)
const featuredImage = ref(null)
const featuredImageUrl = ref(null)

const paths = ref([])
const cityPaths = ref([])
const otherAreasPaths = ref([])
const coordinates = ref([])
const coordinatesChanged = ref(false)
const center = ref()
const zoom = ref(DEFAULT_ZOOM_LEVEL)

const loading = ref(false)

const { entries: cities, loading: loadingCities } = useDataSource('getCities', {
	query: { politicalAreaId: props.politicalArea },
})
const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	uriName,
	cityId,
	featuredImage,
}, REQUIRED_FIELDS)

// Watchers
watch(cityId, (value) => {
	if (value !== currentCityId.value) {
		loadCity(value)
	}
})

// Methods
const {
	geoJSONCoordinatesToPaths,
	googlePathsToPolygonPaths,
	polygonPathsToGeoJSONCoordinates,
	calculateDistance,
	kmToLat,
} = useGeoJSON()

const {
	generatePolygonPaths
} = useMap(map)
const {
	handleClickForDelete
} = usePolygon(polygon)

async function loadCity(id) {
	const request = api.graphql()

	request.query('getCity').arg('id', id)
		.fields('lat', 'long')
		.child('geolocation').fields('coordinates')
	
	const result = await request.exec()
	const { data } = result.get('getCity')

	zoom.value = NEW_ZOOM_LEVEL
	center.value = { lat: data.lat, lng: data.long }
	cityPaths.value = geoJSONCoordinatesToPaths(data.geolocation.coordinates)
	paths.value = await generatePolygonPaths()
	currentCityId.value = id
	loadOtherAreas(id)
}

async function loadOtherAreas(id) {
	let count = 0
	let skip = 0
	let paths = []

	do {
		const request = api.graphql()
		const query = request.query('getAreas').arg('query', {
			politicalAreaId: props.politicalArea,
			cityId: id,
			limit: OTHER_AREAS_FETCH_LIMIT,
			skip,
		}).fields('_id')
		query.child('geolocation').fields('coordinates')
		
		const result = await request.exec()
		const { data } = result.get('getAreas')
		count = data && data.length
		data.forEach(({ _id: otherId, geolocation: { coordinates: areaCoordinates } }) => {
			if (props.id && props.id === otherId) { return }
			paths.push(geoJSONCoordinatesToPaths(areaCoordinates))
		})
		skip += OTHER_AREAS_FETCH_LIMIT
	} while (count === OTHER_AREAS_FETCH_LIMIT)

	otherAreasPaths.value = paths
}

async function onCreate() {
	loading.value = true
	const request = api.graphql()

	request.mutation('createArea')
		.arg('input', {
			...form,
			geolocation: {
				type: 'Polygon',
				coordinates: coordinates.value,
			},
			politicalAreaId: props.politicalArea,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createArea')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateArea')
		.arg('id', id)
		.arg('input', {
			...changes.value,
			...(coordinatesChanged.value ? {
				geolocation: {
					type: 'Polygon',
					coordinates: coordinates.value,
				}
			} : {}),
		})
		.fields('name', 'uriName', 'featuredImageUrl', 'cityId')
	
	const result = await request.exec()
	const { success, data } = result.get('updateArea')
	if (success) {
		const { featuredImageUrl: updatedFeaturedImageUrl, ...restData } = data
		featuredImageUrl.value = updatedFeaturedImageUrl
		featuredImage.value = null
		loadOriginalData({
			...restData,
			featuredImage: null,
		})
		coordinatesChanged.value = false
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	const query = request.query('getArea')
		.arg('id', id)
		.fields('name', 'uriName', 'featuredImageUrl', 'cityId')

	query.child('geolocation')
		.fields('coordinates')
	query.child('getCity')
		.fields('lat', 'long')
		.child('geolocation').fields('coordinates')

	const result = await request.exec()
	const { data } = result.get('getArea')
	coordinates.value = data.geolocation.coordinates
	center.value = { lat: data.getCity.lat, lng: data.getCity.long }
	paths.value = geoJSONCoordinatesToPaths(data.geolocation.coordinates)
	cityPaths.value = geoJSONCoordinatesToPaths(data.getCity.geolocation.coordinates)
	currentCityId.value = data.cityId
	featuredImageUrl.value = data.featuredImageUrl

	loadOriginalData({
		name: data.name,
		uriName: data.uriName,
		cityId: data.cityId,
		featuredImage: null,
	})
	loadOtherAreas(data.cityId)
}

function updateCoordinates(polygonPaths) {
	coordinates.value = polygonPathsToGeoJSONCoordinates(polygonPaths)
	coordinatesChanged.value = true
}

function iterateOtherAreasVertices(cb) {
	for (let i = 0; i < otherAreasPaths.value.length; ++i) {
		const areaPaths = otherAreasPaths.value[i]
		for (let j = 0; j < areaPaths.length; ++j) {
			const path = areaPaths[j]
			for (let k = 0; k < path.length; ++k) {
				const vertex = path[k]
				cb(vertex, k, path)
			}
		}
	}
}

function findNearbyVertex(updatedVertex, treshold) {
	let nearestVertex
	let nearestDistance

	iterateOtherAreasVertices((vertex) => {
		const distance = calculateDistance(vertex, updatedVertex)
		if (!nearestVertex || nearestDistance > distance) {
			nearestDistance = distance
			nearestVertex = vertex 
		}
	})

	if (nearestDistance < treshold) {
		return nearestVertex
	}
	return null
}

function nearSegment(vertexA, vertexB, targetVertex, treshold) {
	const tresholdKm = kmToLat(treshold)

	if (targetVertex.lng >= Math.min(vertexA.lng, vertexB.lng) - tresholdKm &&
		targetVertex.lng <= Math.max(vertexA.lng, vertexB.lng) + tresholdKm &&
		targetVertex.lat >= Math.min(vertexA.lat, vertexB.lat) - tresholdKm &&
		targetVertex.lat <= Math.max(vertexA.lat, vertexB.lat) + tresholdKm
	) {
		return true
	}
}

function translateVertexInSegment(a, b, vertex) {
	const x1=a.lng + 180
	const y1=a.lat + 90
	const x2=b.lng + 180
	const y2=b.lat + 90
	const x3=vertex.lng + 180
	const y3=vertex.lat + 90

	const px = x2-x1
	const py = y2-y1
	const dAB = Math.pow(px, 2) + Math.pow(py, 2)
	const u = ((x3 - x1) * px + (y3 - y1) * py) / dAB
	const x = x1 + u * px
	const y = y1 + u * py

	return { lng: x - 180, lat: y - 90 }
}

function findNearbySegmentVertex(updatedVertex, treshold) {
	let nearestVertex
	let nearestDistance

	iterateOtherAreasVertices((vertex, i, path) => {
		if (path.length < 2) { return }
		const nextVertex = path[i + 1] ? path[i + 1] : path[0]

		if (!nearSegment(vertex, nextVertex, updatedVertex, treshold)) { return }
		const translatedVertex = translateVertexInSegment(vertex, nextVertex, updatedVertex)

		const distance = calculateDistance(translatedVertex, updatedVertex)
		if (!nearestVertex || nearestDistance > distance) {
			nearestDistance = distance
			nearestVertex = translatedVertex 
		}
	})

	if (nearestDistance < treshold) {
		return nearestVertex
	}

	return null
}

function updatePaths(polygonPaths, point) {
	const updatedPaths = JSON.parse(JSON.stringify(polygonPaths))
	updatedPaths[updatedPathIndex][updatedVertexIndex] = {
		lng: point.lng,
		lat: point.lat,
	}

	paths.value = updatedPaths

	return updatedPaths
}

function snapToMapEntity(polygonPaths, treshold) {
	if (typeof updatedPathIndex === 'undefined') { return polygonPaths }

	const updatedVertex = polygonPaths[updatedPathIndex][updatedVertexIndex]

	// Snap to nearby vertex if found
	const nearbyVertex = findNearbyVertex(updatedVertex, treshold)
	if (nearbyVertex) { return updatePaths(polygonPaths, nearbyVertex) }

	// Snap to nearby segment vertex if found
	const nearbySegmentPoint = findNearbySegmentVertex(updatedVertex, treshold)
	if (nearbySegmentPoint) { return updatePaths(polygonPaths, nearbySegmentPoint) }

	return polygonPaths
}

function adjustedSnapTreshold() {
	const currentZoom = map.value.$mapObject.zoom
	return SNAP_TRESHOLD / Math.pow(2, currentZoom - DEFAULT_ZOOM_LEVEL)
}

function updateEdited(mvcPaths) {
	const polygonPaths = snapToMapEntity(googlePathsToPolygonPaths(mvcPaths), adjustedSnapTreshold())
	updateCoordinates(polygonPaths)
}

// Snapping feature
//let isUpdatingPath = false
let updatedPathIndex
let updatedVertexIndex
function onAreaMouseDown({ path, edge, vertex, domEvent }) {
	if (typeof path !== 'undefined' && domEvent.which === 1) {
		updatedPathIndex = path
		updatedVertexIndex = typeof vertex !== 'undefined' ? vertex : (edge + 1)
	} else {
		updatedPathIndex = undefined
		updatedVertexIndex = undefined
	}
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
	// @todo - Do a proper autocomplete feature with search
})
</script>

<style scoped>
	.area-form-data {
		max-width: 320px;
	}
</style>