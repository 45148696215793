<template>
	<div class="realtor-additional-emails">
		<div class="text-right">
			<base-button
				color="primary"
				small
				:loading="additionDialog.show"
				@click="showAdditionDialog"
			>
				<base-icon small>mdi-plus-circle-outline</base-icon>
				<span class="ml-2">Add Email</span>
			</base-button>
		</div>
		<base-data-table
			:headers="headers"
			:items="items"
			:options.sync="options"
			:items-per-page="25"
			:loading="loading"
		>
			<template v-slot:[`item.actions`]="{ item, index }">
				<base-button
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.index === index"
					@click="showDeletionDialog(index, item.name)"
				>Delete</base-button>
			</template>
		</base-data-table>
		<base-modal
			title="New Email"
			:value="additionDialog.show"
			:actions="newEmailActions"
			@click="onAddEmail"
		>
			<base-text-field v-model="additionDialog.text" label="Email" />
		</base-modal>
		<base-modal
			title="Delete Email"
			:value="deletionDialog.show"
			:actions="deletionEmailActions"
			@click="onDeleteEmail"
		>
			<span>Are you sure you want to remove email <b>{{deletionDialog.text}}</b>?</span>
		</base-modal>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, reactive } from 'vue'

import { useApi } from '@/plugins/api'

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	refresh: {
		type: Boolean,
		default: false,
	}
})
const emit = defineEmits(['update:refresh'])

// Variables & Data
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

const loadingAddition = ref(false)
const loadingDeletion = ref(false)

const newEmailActions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	text: 'Create',
	value: 'create',
	color: 'primary',
	loading: loadingAddition,
	disabled: computed(() => additionDialog.text === ''),
})]

const deletionEmailActions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	loading: loadingDeletion,
	text: 'Delete',
	value: 'delete',
	color: 'error',
})]

const api = useApi()
const emails = ref([])
const loading = ref(true)
const options = ref({})
const deletionDialog = reactive({
	show: ref(false),
	index: ref(null),
	text: ref(''),
})
const additionDialog = reactive({
	show: ref(false),
	text: ref(''),
})

// Computed
const needsRefresh = computed(() => props.refresh)
const items = computed(() => emails.value.map((email) => ({ name: email })))

// Watchers
watch(needsRefresh, (value, oldValue) => {
	if (value !== oldValue && value === true) {
		fetchEmails()
		emit('update:refresh', false)
	}
})

// Methods
async function fetchEmails() {
	loading.value = true
	const request = api.graphql()
	request.query('getRealtor').arg('id', props.id).fields('alternateEmails')
	const result = await request.exec()
	const { data } = result.get('getRealtor')
	if (data) {
		emails.value = data.alternateEmails
	}
	loading.value = false
}

async function updateEmails() {
	const request = api.graphql()

	request.mutation('updateRealtor').arg('id', props.id).arg('input', {
		alternateEmails: emails.value,
	}).fields('alternateEmails')

	await request.exec()
}

function showDeletionDialog(index, text) {
	deletionDialog.index = index
	deletionDialog.text = text
	deletionDialog.show = true
}

function showAdditionDialog() {
	additionDialog.text = ''
	additionDialog.show = true
}



async function onAddEmail(value) {
	if (value == 'create') {
		loadingAddition.value = true
		emails.value = [...emails.value, additionDialog.text]
		await updateEmails()
	}
	additionDialog.show = false
	loadingAddition.value = false
}

async function onDeleteEmail(value) {
	if (value == 'delete') {
		loadingDeletion.value = true
		const i = deletionDialog.index + (options.value.itemsPerPage * (options.value.page - 1))
		emails.value.splice(i, 1)
		await updateEmails()
	}
	deletionDialog.show = false
	loadingDeletion.value = false
}

onMounted(() => {
	fetchEmails()
})
</script>
