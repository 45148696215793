<template>
	<generic-deletion-modal v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners" />
</template>

<script setup>
import { computed } from 'vue'

import GenericDeletionModal from '../GenericDeletionModal.vue'

const defaultAttrs = computed(() => ({
	deleteFn: 'deleteRole',
	component: 'Role',
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>