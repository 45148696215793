<template>
	<div class="city-form">
		<base-text-field v-model="name" label="Name*" />
		<base-autocomplete v-model="areaId" :loading="loadingAreas" :items="areas" label="Area*" />
		<base-text-field v-model="uriName" label="Website URL" />
		<base-image-upload
			v-model="featuredImage"
			:src="featuredImageUrl"
			width="350"
			height="233"
			label="Featured Image (350x233)"
			resize
		/>
		<base-text-field v-model="h2" label="Website H2" />
		<base-textarea v-model="description" label="Description" />
		<base-address-autocomplete
			v-if="country"
			label="Address"
			:value="address"
			:country="country"
			@placechanged="onPlaceChanged"
		/>
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="lat" label="Latitude" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="long" label="Longitude" />
				</base-col>
			</base-row>
		</base-container>
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			:loading="loading"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

const REQUIRED_FIELDS = ['name', 'areaId']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	name: {
		type: String,
	},
	areaId: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const name = ref(props.name)
const uriName = ref(null)
const h2 = ref(null)
const description = ref(null)
const country = ref(null)
const areaId = ref(props.areaId)
const address = ref(null)
const lat = ref(null)
const long = ref(null)
const featuredImage = ref(null)
const featuredImageUrl = ref(null)

const loading = ref(false)

const { entries: areas, loading: loadingAreas } = useDataSource('getAreas', {
	query: { politicalAreaId: props.politicalArea },
})
const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	uriName,
	h2,
	description,
	areaId,
	address,
	lat: computed({
		get: () => lat.value && parseFloat(lat.value),
		set: (value) => lat.value = value,
	}),
	long: computed({
		get: () => long.value && parseFloat(long.value),
		set: (value) => long.value = value,
	}),
	featuredImage,
}, REQUIRED_FIELDS)

// Methods
async function onCreate() {
	loading.value = true
	const request = api.graphql()

	request.mutation('createComplex')
		.arg('input', {
			...form,
			politicalAreaId: props.politicalArea,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createComplex')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateComplex')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('name', 'uriName', 'featuredImageUrl', 'h2', 'description', 'areaId', 'address', 'lat', 'long')
	
	const result = await request.exec()
	const { success, data } = result.get('updateComplex')
	if (success) {
		const { featuredImageUrl: updatedFeaturedImageUrl, ...restData } = data
		featuredImageUrl.value = updatedFeaturedImageUrl
		featuredImage.value = null

		loadOriginalData({
			...restData,
			featuredImage: null,
		})
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getComplex')
		.arg('id', id)
		.fields('name', 'uriName', 'featuredImageUrl', 'h2', 'description', 'areaId', 'address', 'lat', 'long')

	const result = await request.exec()
	const { data } = result.get('getComplex')
	const { featuredImageUrl: originalFeaturedImageUrl, ...restData } = data

	featuredImageUrl.value = originalFeaturedImageUrl
	loadOriginalData({
		...restData,
		featuredImage: null,
	})
}

async function fetchCountry() {
	const request = api.graphql()
	request.query('getPoliticalArea')
		.arg('id', props.politicalArea)
		.fields('country')

	const result = await request.exec()
	const { data } = result.get('getPoliticalArea')
	country.value = data.country
}

function onPlaceChanged(_, place) {
	lat.value = place.geometry.location.lat()
	long.value = place.geometry.location.lng()
	address.value = place.formatted_address
}

onMounted(async () => {
	fetchCountry()
	if (!props.new) {
		fetchEntry(props.id)
	}
	// @todo - Do a proper autocomplete feature with search
})
</script>

<style scoped>
	.city-form {
		max-width: 320px;
	}
</style>