<template>
	<div class="view-complex-requests">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<complex-requests-table :refresh.sync="refresh">
			<template v-slot:actions="item">
				<base-button
					:to="{
						name: 'complexRequest',
						params: { request: item._id }
					}"
					color="primary"
					text
					small
				>Create</base-button>
				<base-button
					color="primary"
					text
					small
					:loading="assignDialog.show && assignDialog.id === item._id"
					@click="showAssignDialog(item._id)"
				>Assign</base-button>
				<base-button
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, item.name)"
				>Delete</base-button>
			</template>
		</complex-requests-table>
		<assign-complex-modal
			:id="assignDialog.id"
			v-model="assignDialog.show"
			@assign="refresh = true"
		/>
		<complex-request-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue'

import AssignComplexModal from '@/components/fragments/complexRequest/AssignComplexModal.vue'
import ComplexRequestsTable from '@/components/fragments/complexRequest/ComplexRequestsTable.vue'
import ComplexRequestDeletionModal from '@/components/fragments/complexRequest/ComplexRequestDeletionModal.vue'

// Data
const breadcrumb = [{
	text: 'Complex Requests',
}]
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

const assignDialog = reactive({
	show: ref(false),
	id: ref(null),
})

// Methods
function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}

function showAssignDialog(id) {
	assignDialog.id = id
	assignDialog.show = true
}
</script>
