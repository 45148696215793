<template>
	<div class="view-representative">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<representative-form :id="representativeId" :market="marketId" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import RepresentativeForm from '@/components/fragments/representative/RepresentativeForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const marketId = computed(() => route.params.market)
const representativeId = computed(() => route.params.representative)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Markets',
	to: { name: 'markets' },
}, {
	api: 'marketmath',
	fn: 'getMarket',
	id: marketId.value,
	field: 'name',
}, {
	text: 'Representatives',
	to: {
		name: 'representatives',
	},
}, {
	api: 'marketmath',
	fn: 'getRepresentative',
	id: representativeId.value,
	field: 'email',
}])
</script>