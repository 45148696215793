<template>
	<generic-app-header
		:breadcrumb="props.breadcrumb"
		color="primary"
		:actions-loading="importing"
	>
		<template v-slot:actions>
			<!--<base-list-item
				v-if="can('realestate:listing:*.create')"
				prepend-icon="mdi-import"
				@click="showFileImport = true"
			>Import</base-list-item>-->
			<base-list-item prepend-icon="mdi-export" @click="onExport">Export</base-list-item>
			<base-file-reader :show.sync="showFileImport" type="json" @load="onImport" />
		</template>
	</generic-app-header>
</template>

<script setup>
import { ref } from 'vue'

import { useApi } from '@/plugins/api'

import GenericAppHeader from '../GenericAppHeader.vue'

import useExport from '@/features/useExport'
import useImport from '@/features/useImport'
// import useSession from '@/features/useSession'

// props & Emits
const props = defineProps({
	breadcrumb: {
		type: Array,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['import'])

// Data
const api = useApi()
const importing = ref(false)
const showFileImport = ref(false)
const FIELDS = ['title', 'uriName', 'address', 'long', 'lat', 'bedrooms', 'bathrooms', 'source', 'url', 'price',
	'featuredImage', 'propertyType', 'description', 'realtorName', 'brokerageName', 'externalId', 'mls', 'listedSrc',
	'listedSince', 'status', 'occupancyRate', 'revenue', 'adr', 'realtorId', 'areaId', 'complexId', 'historicalDatasetId']

// Methods
// const { can } = useSession()
async function getMapsByLegacyId(fns, ids) {
	const request = api.graphql()

	fns.forEach((fn, i) => {
		if (!ids[i].length) { return }
		request.query(fn).arg('query', {
			legacyId: ids[i],
			politicalAreaId: props.politicalArea,
			limit: ids[i].length,
		}).fields('_id', 'legacyId')
	})
	
	const result = await request.exec()
	return fns.map((fn) => {
		const { data = [] } = result.get(fn)
		return data.reduce((acc, value) => ({
			...acc,
			[value.legacyId]: value._id
		}), {})
	})
}

const exportListings = useExport({
	fn: 'getListings',
	fields: ['_id', ...FIELDS],
})

const importListings = useImport({
	fn: 'createListing',
	fields: [...FIELDS, 'legacyId'],
	map: (input) => ({
		...input,
		politicalAreaId: props.politicalArea,
	}),
	transform: async (inputs) => {
		const filteredInputs = inputs.filter(({ status }) => status !== 'declined')
		if (!filteredInputs.length) { return [] }
		const [realtorsMap, areasMap, complexesMap] = await getMapsByLegacyId(['getRealtors', 'getAreas', 'getComplexes'], [
			filteredInputs.map(({ legacyRealtor }) => legacyRealtor).filter((value) => value),
			filteredInputs.map(({ legacyArea }) => legacyArea).filter((value) => value),
			filteredInputs.map(({ legacyComplex }) => legacyComplex).filter((value) => value),
		])
	
		return filteredInputs.map((input) =>({
			...input,
			adr: input.occupancyRate ? Math.round(parseFloat((input.revenue / (Math.ceil(365 * (input.occupancyRate / 100)))).toFixed(2))) : undefined,
			source: !input.externalId ? 'legacy' : input.source,
			externalId: input.externalId ? input.externalId.toString() : undefined,
			realtorId: realtorsMap[input.legacyRealtor],
			areaId: areasMap[input.legacyArea],
			complexId: complexesMap[input.legacyComplex],
		}))
	}
})

function onExport() {
	exportListings('listings', {
		filters: {
			politicalAreaId: props.politicalArea,
		}
	})
}

async function onImport(data) {
	importing.value = true
	const result = await importListings(data)
	importing.value = false
	emit('import', result)
}
</script>