var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"view-custom-rpas"},[_c(_setup.CustomRpasHeader,{attrs:{"breadcrumb":_setup.breadcrumb}}),_c(_setup.CustomRpasTableFilters,{model:{value:(_setup.filters),callback:function ($$v) {_setup.filters=$$v},expression:"filters"}}),_c(_setup.CustomRpasTable,{attrs:{"refresh":_setup.refresh,"page":_setup.page,"filters":_setup.filters},on:{"update:refresh":function($event){_setup.refresh=$event},"update:page":function($event){_setup.page=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('base-button',{attrs:{"to":{
					name: 'publicRPA',
					params: { rpa: item._id }
				},"target":"_blank","color":"primary","text":"","small":"","disabled":item.status !== 'ready'}},[_vm._v("Access")]),(_setup.can(`realestate:custom-rental-projection-analysis:${item._id}.update`))?_c('base-button',{attrs:{"to":{
					name: 'customRPA',
					params: { rpa: item._id }
				},"color":"primary","text":"","small":""}},[_vm._v("Edit")]):_vm._e(),(_setup.can(`realestate:custom-rental-projection-analysis:${item._id}.delete`))?_c('base-button',{attrs:{"color":"error","text":"","small":"","loading":_setup.deletionDialog.show && _setup.deletionDialog.id === item._id},on:{"click":function($event){return _setup.showDeletionDialog(item._id, item.name)}}},[_vm._v("Delete")]):_vm._e()]}}])}),_c(_setup.customRpaDeletionModal,{attrs:{"text":_setup.deletionDialog.text,"id":_setup.deletionDialog.id},on:{"delete":function($event){_setup.refresh = true}},model:{value:(_setup.deletionDialog.show),callback:function ($$v) {_vm.$set(_setup.deletionDialog, "show", $$v)},expression:"deletionDialog.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }