<template>
	<div class="realtor-form">
		<base-text-field v-model="name" label="Name*" />
		<base-autocomplete v-model="brokerageId" :loading="loadingBrokerages" :items="brokerages" label="Brokerage*" />
		<base-text-field v-model="email" label="Email Address" />
		<base-text-field v-model="phone" label="Phone Number" />
		<base-text-field v-model="officePhone" label="Office Phone Number" />
		<base-text-field v-model="avatar" label="Avatar" />
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="yearsOfExperience" label="Years of experience" />
				</base-col>
				<base-col class="pr-0">
				</base-col>
			</base-row>
		</base-container>
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

const REQUIRED_FIELDS = ['name', 'brokerageId']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const name = ref(null)
const brokerageId = ref(null)
const email = ref(null)
const phone = ref(null)
const officePhone = ref(null)
const avatar = ref(null)
const yearsOfExperience = ref(null)

const { entries: brokerages, loading: loadingBrokerages } = useDataSource('getBrokerages', {
	query: { politicalAreaId: props.politicalArea },
})
const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	brokerageId,
	email,
	phone,
	officePhone,
	avatar,
	yearsOfExperience: computed({
		get: () => yearsOfExperience.value && parseInt(yearsOfExperience.value, 10),
		set: (value) => yearsOfExperience.value = value,
	}),
}, REQUIRED_FIELDS)

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createRealtor')
		.arg('input', {
			...form,
			politicalAreaId: props.politicalArea,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createRealtor')
	if (success) {
		emit('create', data._id)
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateRealtor')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('name', 'brokerageId', 'email', 'phone', 'officePhone', 'avatar', 'yearsOfExperience')
	
	const result = await request.exec()
	const { success, data } = result.get('updateRealtor')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getRealtor')
		.arg('id', id)
		.fields('name', 'brokerageId', 'email', 'phone', 'officePhone', 'avatar', 'yearsOfExperience')

	const result = await request.exec()
	const { data } = result.get('getRealtor')
	loadOriginalData(data)
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
	// @todo - Do a proper autocomplete feature with search
})
</script>

<style scoped>
	.realtor-form {
		max-width: 320px;
	}
</style>