<template>
	<base-modal
		title="Send Listing As Email"
		color="primary"
		:actions="actions"
		:value="show"
		@click="onModalClick"
	>
		<div class="mb-4">Are you sure you want to send listing <b>{{name}}</b>?</div>
		<base-text-field v-if="show" v-model="to" label="Recipient Email" />
	</base-modal>
</template>

<script setup>
import { computed, ref, reactive, watch } from 'vue'

import { useApi } from '@/plugins/api'

// Props & Emits
const props = defineProps({
	value: {
		type: Boolean,
	},
	id: {
		type: String,
	},
	email: {
		type: String,
	},
	name: {
		type: String,
	},
})
const emit = defineEmits(['input', 'email:update', 'sent'])

// Data
const api = useApi()
const loading = ref(false)
const to = ref()

const actions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	text: 'Send',
	value: 'send',
	color: 'primary',
	loading,
	disabled: computed(() => !to.value || to.value === '')
})]

// Computed
const show = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

const email = computed({
	get: () => props.email,
	set: (value) => emit('email:update', value)
})

watch(email, (value) => {
	to.value = value
})

async function sendListing(id) {
	loading.value = true
	const request = api.graphql()
	request.mutation('sendListingAsEmail').arg('id', id).arg('input', { to: to.value }).fields('success')

	const result = await request.exec()
	const { success } = result
	if (success) {
		emit('sent', true)
	}
}

// Methods
async function onModalClick(value) {
	if (value === 'send') {
		await sendListing(props.id)
	}
	to.value = email.value
	show.value = false
	loading.value = false
}
</script>