var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"view-listings"},[_c(_setup.ListingsHeader,{attrs:{"breadcrumb":_setup.breadcrumb,"political-area":_setup.politicalAreaId},on:{"import":function($event){_setup.refresh = true}}}),_c(_setup.ListingsLegend,{staticClass:"mb-4"}),_c(_setup.ListingsTableFilters,{attrs:{"political-area":_setup.politicalAreaId},model:{value:(_setup.filters),callback:function ($$v) {_setup.filters=$$v},expression:"filters"}}),_c(_setup.ListingsTable,{attrs:{"political-area":_setup.politicalAreaId,"refresh":_setup.refresh,"page":_setup.page,"filters":_setup.filters},on:{"update:refresh":function($event){_setup.refresh=$event},"update:page":function($event){_setup.page=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('base-menu',{attrs:{"offset-y":"","nudge-bottom":"15"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_setup.can('realestate:listing-activity:*.read') || _setup.can('realestate:listing-note:*.read'))?_c('base-button',_vm._g(_vm._b({attrs:{"color":"secondary","small":"","text":""}},'base-button',attrs,false),on),[_c('base-icon',[_vm._v("mdi-dots-horizontal")])],1):_vm._e()]}}],null,true)},[_c('base-list',[(_setup.can('location:city:*.read') && _setup.can('location:area:*.read'))?_c('base-list-item',{attrs:{"disabled":item.status === 'new',"to":{
							name: 'listingMap',
							params: { listing: item._id }
						}}},[_vm._v("Map")]):_vm._e(),(_setup.can('realestate:listing-activity:*.read'))?_c('base-list-item',{attrs:{"disabled":item.status !== 'sent',"to":{
							name: 'listingActivity',
							params: { listing: item._id }
						}}},[_vm._v("Activity")]):_vm._e(),(_setup.can('realestate:listing-note:*.read'))?_c('base-list-item',{attrs:{"disabled":item.status !== 'sent',"to":{
							name: 'listingNotes',
							params: { listing: item._id }
						}}},[_vm._v("Notes")]):_vm._e()],1)],1),(`realestate:listing:${item._id}.send_email`)?_c('base-button',{attrs:{"color":"primary","text":"","small":"","disabled":!_setup.canSend(item),"loading":_setup.sendDialog.show && _setup.sendDialog.id === item._id},on:{"click":function($event){return _setup.showSendDialog(item._id, item.title, item.getRealtor && item.getRealtor.email)}}},[_vm._v("Send")]):_vm._e(),(`realestate:listing:${item._id}.update`)?_c('base-button',{attrs:{"to":{
					name: 'listing',
					params: { listing: item._id }
				},"color":"primary","text":"","small":""}},[_vm._v("Edit")]):_vm._e(),(`realestate:listing:${item._id}.${item.status === 'new' ? 'delete' : 'update'}`)?_c('base-button',{attrs:{"color":"error","text":"","small":"","loading":_setup.deletionDialog.show && _setup.deletionDialog.id === item._id,"disabled":item.status === 'discarded'},on:{"click":function($event){return _setup.showDeletionDialog(item._id, item.title, item.status !== 'new')}}},[_vm._v(_vm._s(item.status === 'new' ? 'Delete' : 'Discard'))]):_vm._e()]}}])}),_c(_setup.ListingDeletionModal,{attrs:{"text":_setup.deletionDialog.text,"id":_setup.deletionDialog.id,"discard":_setup.deletionDialog.discard},on:{"delete":function($event){_setup.refresh = true}},model:{value:(_setup.deletionDialog.show),callback:function ($$v) {_vm.$set(_setup.deletionDialog, "show", $$v)},expression:"deletionDialog.show"}}),_c(_setup.ListingSendModal,{attrs:{"name":_setup.sendDialog.name,"email":_setup.sendDialog.email,"id":_setup.sendDialog.id},on:{"update:email":function($event){return _vm.$set(_setup.sendDialog, "email", $event)},"sent":function($event){_setup.refresh = true}},model:{value:(_setup.sendDialog.show),callback:function ($$v) {_vm.$set(_setup.sendDialog, "show", $$v)},expression:"sendDialog.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }