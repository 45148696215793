<template>
	<div :class="classes">
		<v-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
				<slot :name="scopedSlotName" v-bind="slotData" />
			</template>
			<template v-for="(_, slotName) in $slots" #[slotName]>
				<slot :name="slotName" />
			</template>
		</v-data-table>
	</div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	fixedFooter: {
		type: Boolean,
		default: false,
	}
})

const classes = computed(() => ({
	'base-data-table': true,
	'fixed-footer': props.fixedFooter,
}))

const defaultAttrs = computed(() => ({
	footerProps: {
		'items-per-page-options': [10, 25, 50, 100],
		'show-current-page': true,
		'show-first-last-page': true,
	}
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>

<style scoped>
	.base-data-table.fixed-footer :deep(.v-data-footer) {
		position: sticky;
		bottom: 0;
		background-color: inherit;
	}
</style>