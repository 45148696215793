import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#76c043',
				secondary: '#94348c',
				accent: '#76c043',
				error: '#f44336',
				success: '#76C043',
				warning: '#fb8c00',
				info: '#2196f3',
			},
		},
	},
})
