<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.status`]="{ item }">
			<div :class="item.status === 'ready' ? 'success--text' : 'secondary--text'">{{item.status.toUpperCase()}}</div>
		</template>
		<template v-slot:[`item.title`]="{ item }">
			<div>{{item.title}}</div>
			<div>{{item.subtitle}}</div>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Status',
	value: 'status',
}, {
	text: 'Title/Subtitle',
	value: 'title',
}, {
	text: 'Address',
	value: 'address',
}, {
	text: 'Revenue',
	value: 'revenue',
}, {
	text: 'Occupancy Rate',
	value: 'occupancyRate',
}, {
	text: 'ADR',
	value: 'adr',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'name',
	headers,
	filters,
	getFn: 'getCustomRentalProjectionAnalyses',
	countFn: 'countCustomRentalProjectionAnalyses',
	fields: ['_id', 'status', 'title', 'subtitle', 'address', 'revenue', 'occupancyRate', 'adr'],
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>