const currencies = [{
	text: 'US Dollar',
	value: 'USD',
}, {
	text: 'Canadian Dollar',
	value: 'CAD',
}]

export function getCurrencies() {
	return currencies.map(currency => ({ ...currency}))
}
