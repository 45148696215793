<template>
	<div class="view-newlocation">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<location-form new @create="onCreated" />
	</div>
</template>

<script setup>
import LocationForm from '@/components/fragments/location/LocationForm'

import { useRouter } from '@/plugins/router'

// Modules
const router = useRouter()

// Data
const breadcrumb = [{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	text: 'New Location',
}]

// Methods
async function onCreated(id) {
	router.push({
		name: 'cities',
		params: { location: id },
	})
}
</script>