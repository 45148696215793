<template>
	<div class="view-listing-map">
		<listing-map-header :breadcrumb="breadcrumb" />
		<listing-map :listing="listingId" :politicalArea="politicalAreaId"  />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'

import ListingMapHeader from '@/components/fragments/listingMap/ListingMapHeader.vue'
import ListingMap from '@/components/fragments/listingMap/ListingMap.vue'

// Plugins
const route = useRoute()

// Computed
const savedListingQuery = useSavedDataTableRouteState('listings')
const politicalAreaId = computed(() => route.params.location)
const listingId = computed(() => route.params.listing)

// Breadcrumb
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Listings',
	to: {
		name: 'listings',
		query: savedListingQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getListing',
	id: listingId.value,
	field: 'title',
}, {
	text: 'Map',
}])
</script>
