<template>
	<base-expansion-panels>
		<base-expansion-panel class="white" title="Filters">
			<base-card>
				<base-card-text>
					<base-row>
						<base-col cols="12" sm="4" md="3" lg="2">
							<base-autocomplete
								v-model="cityId"
								:items="cities"
								:loading="loadingCities"
								:search-input.sync="searchCities"
								hide-no-data
								cache-items
								label="City"
								clearable
							/>
						</base-col>
					</base-row>
				</base-card-text>
				<base-card-actions>
					<base-button color="primary" :disabled="!hasChanges" @click="onApply">Apply</base-button>
				</base-card-actions>
			</base-card>
		</base-expansion-panel>
	</base-expansion-panels>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

// import useDataSource from '@/features/useDataSource'
import useFilters from '@/features/useFilters'
import useAutocompleteSearch from '@/features/useAutocompleteSearch'

// Props & Emits
const props = defineProps({
	value: {
		type: Object,
		default: () => {},
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['input'])

// Data
const cityId = ref(props.value.cityId)
const form = reactive({
	cityId
})

const { search: searchCities, loading: loadingCities, items: cities } = useAutocompleteSearch('getCities', {
	query: computed(() => ({
		politicalAreaId: props.politicalArea,
	})),
	init: {
		fn: 'getCity',
		value: cityId.value,
	}
})

// Computed
const filters = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

const { hasChanges, onApply } = useFilters(form, filters)
</script>