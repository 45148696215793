<template>
	<div class="base-input base-switch">
		<v-switch v-model="checked" v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	value: {
		type: Boolean,
	},
})
const emit = defineEmits('input')

const defaultAttrs = computed(() => ({
	inset: true,
	ripple: false,
	persistentHint: true,
}))
const checked = computed({
	get: () => props.value,
	set: (value) => emit('input', value),
})
</script>

<script>
export default {
	inheritAttrs: false
}
</script>

<style scoped>
	.base-input :deep(.v-input--selection-controls) {
		margin-top: 0;
		padding-top: 0;
	}
	.base-input {
		padding-bottom: 16px;
	}
</style>