<template>
	<div class="view-markets">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
						<base-col v-if="can('realestate:market:*.create')" class="pa-0" align="right">
							<base-button
								class="ma-0"
								color="primary"
								:to="{ name: 'newMarket' }"
								height="46px"
							>
								<base-icon>mdi-plus-circle-outline</base-icon>
								<span class="ml-2">Add Market</span>
							</base-button>
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<markets-table>
			<template v-slot:actions="item">
				<base-button
					:to="{
						name: 'marketListings',
						params: { market: item._id }
					}"
					color="primary"
					text
					small
				>Manage</base-button>
			</template>
		</markets-table>
	</div>
</template>

<script setup>
import useSession from '@/features/useSession'

import MarketsTable from '@/components/fragments/market/MarketsTable.vue'

const breadcrumb = [{
	text: 'Markets',
}]

const { can } = useSession()
</script>
