<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.firstName`]="{ item }">
			{{ item.firstName }} {{ item.lastName }}
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Name',
	value: 'firstName',
}, {
	text: 'Email Address',
	value: 'email',
}, {
	text: 'Phone Number',
	value: 'phone',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: ['firstName', 'lastName'],
	headers,
	filters,
	getFn: 'getContacts',
	countFn: 'countContacts',
	fields: ['_id', 'firstName', 'lastName', 'email', 'phone'],
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>
