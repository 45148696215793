<template>
	<v-expansion-panel v-bind="$attrs" v-on="$listeners">
		<v-expansion-panel-header>
			<template v-slot:actions>
				<v-icon :class="iconClasses">$expand</v-icon>
			</template>
			<span :class="headerClasses">
				<span v-if="props.title">{{ props.title }}</span>
				<slot name="header" />
			</span>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<div class="pt-4">
				<slot />
			</div>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	title: {
		type: String,
	},
	iconRight: {
		type: Boolean,
		default: false,
	}
})

const iconClasses = computed(() => ({
	'base-expansion-panel-icon': true,
	'base-expansion-panel-icon-left': !props.iconRight,
}))

const headerClasses = computed(() => ({
	'base-expansion-panel-header': true,
	'base-expansion-panel-header-left': !props.iconRight,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>

<style>
    .base-expansion-panel-icon-left {
        order: 0;
		margin-right: 16px;
    }

    .base-expansion-panel-header-left {
        order: 1;
    }
</style>