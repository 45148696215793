import ViewLogin from '@/views/ViewLogin.vue'
import ViewAccessKeys from '@/views/ViewAccessKeys.vue'
import ViewAreas from '@/views/ViewAreas.vue'
import ViewArea from '@/views/ViewArea.vue'
import ViewBrands from '@/views/ViewBrands.vue'
import ViewBrand from '@/views/ViewBrand.vue'
import ViewBrokerages from '@/views/ViewBrokerages.vue'
import ViewBrokerage from '@/views/ViewBrokerage.vue'
import ViewCities from '@/views/ViewCities.vue'
import ViewCity from '@/views/ViewCity.vue'
import ViewComplexes from '@/views/ViewComplexes.vue'
import ViewComplex from '@/views/ViewComplex.vue'
import ViewContacts from '@/views/ViewContacts.vue'
import ViewContact from '@/views/ViewContact.vue'
import ViewComplexRequests from '@/views/ViewComplexRequests.vue'
import ViewCustomRentalProjectionAnalyses from '@/views/ViewCustomRentalProjectionAnalyses.vue'
import ViewCustomRentalProjectionAnalysis from '@/views/ViewCustomRentalProjectionAnalysis.vue'
import ViewHistoricalDatasets from '@/views/ViewHistoricalDatasets.vue'
import ViewListings from '@/views/ViewListings.vue'
import ViewListing from '@/views/ViewListing.vue'
import ViewListingActivity from '@/views/ViewListingActivity.vue'
import ViewListingMap from '@/views/ViewListingMap.vue'
import ViewListingNotes from '@/views/ViewListingNotes.vue'
import ViewListingsValidation from '@/views/ViewListingsValidation.vue'
import ViewListingsValidationSent from '@/views/ViewListingsValidationSent.vue'
import ViewLocations from '@/views/ViewLocations.vue'
import ViewLocationSettings from '@/views/ViewLocationSettings.vue'
import ViewMarkets from '@/views/ViewMarkets.vue'
import ViewMarketListings from '@/views/ViewMarketListings.vue'
import ViewMarketSettings from '@/views/ViewMarketSettings.vue'
import ViewMe from '@/views/ViewMe.vue'
import ViewNewAccessKey from '@/views/ViewNewAccessKey.vue'
import ViewNewArea from '@/views/ViewNewArea.vue'
import ViewNewBrand from '@/views/ViewNewBrand.vue'
import ViewNewBrokerage from '@/views/ViewNewBrokerage.vue'
import ViewNewCity from '@/views/ViewNewCity.vue'
import ViewNewComplex from '@/views/ViewNewComplex.vue'
import ViewNewComplexFromRequest from '@/views/ViewNewComplexFromRequest.vue'
import ViewNewContact from '@/views/ViewNewContact.vue'
import ViewNewCustomRentalProjectionAnalysis from '@/views/ViewNewCustomRentalProjectionAnalysis.vue'
import ViewNewLocation from '@/views/ViewNewLocation.vue'
import ViewNewMarket from '@/views/ViewNewMarket.vue'
import ViewNewRealtor from '@/views/ViewNewRealtor.vue'
import ViewNewRepresentative from '@/views/ViewNewRepresentative.vue'
import ViewNewRole from '@/views/ViewNewRole.vue'
import ViewNewUser from '@/views/ViewNewUser.vue'
import ViewNotFound from '@/views/ViewNotFound.vue'
import ViewPublicRentalProjectionAnalysis from '@/views/ViewPublicRentalProjectionAnalysis.vue'
import ViewRealtors from '@/views/ViewRealtors.vue'
import ViewRealtor from '@/views/ViewRealtor.vue'
import ViewRepresentatives from '@/views/ViewRepresentatives.vue'
import ViewRepresentative from '@/views/ViewRepresentative.vue'
import ViewRoles from '@/views/ViewRoles.vue'
import ViewRole from '@/views/ViewRole.vue'
import ViewSessions from '@/views/ViewSessions.vue'
import ViewUsers from '@/views/ViewUsers.vue'
import ViewUser from '@/views/ViewUser.vue'

export default [{
	path: '/',
	name: 'home',
	redirect: '/locations',
	meta: { requiresAuth: true },
}, {
	path: '/admin/access-keys',
	name: 'accessKeys',
	component: ViewAccessKeys,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/access-keys/new',
	name: 'newAccessKey',
	component: ViewNewAccessKey,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/roles',
	name: 'roles',
	component: ViewRoles,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/roles/new',
	name: 'newRole',
	component: ViewNewRole,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/roles/:role',
	name: 'role',
	component: ViewRole,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/sessions',
	name: 'sessions',
	component: ViewSessions,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/users',
	name: 'users',
	component: ViewUsers,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/users/new',
	name: 'newUser',
	component: ViewNewUser,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/admin/users/:user',
	name: 'user',
	component: ViewUser,
	meta: {
		requiresAuth: true,
		menu: 'admin',
	},
}, {
	path: '/complex-requests',
	name: 'complexRequests',
	component: ViewComplexRequests,
	meta: { requiresAuth: true },
}, {
	path: '/complex-requests/:request',
	name: 'complexRequest',
	component: ViewNewComplexFromRequest,
	meta: { requiresAuth: true },
}, {
	path: '/contacts',
	name: 'contacts',
	component: ViewContacts,
	meta: {
		requiresAuth: true,
	},
}, {
	path: '/contacts/new',
	name: 'newContact',
	component: ViewNewContact,
	meta: {
		requiresAuth: true,
	},
}, {
	path: '/contacts/:contact',
	name: 'contact',
	component: ViewContact,
	meta: {
		requiresAuth: true,
	},
}, {
	path: '/custom-rental-projection-analyses',
	name: 'customRPAs',
	component: ViewCustomRentalProjectionAnalyses,
	meta: { requiresAuth: true },
}, {
	path: '/custom-rental-projection-analyses/new',
	name: 'newCustomRPA',
	component: ViewNewCustomRentalProjectionAnalysis,
	meta: { requiresAuth: true },
}, {
	path: '/custom-rental-projection-analyses/:rpa',
	name: 'customRPA',
	component: ViewCustomRentalProjectionAnalysis,
	meta: { requiresAuth: true },
}, {
	path: '/rental-projection-analysis/:rpa',
	name: 'publicRPA',
	component: ViewPublicRentalProjectionAnalysis,
}, {
	path: '/listings-validation/:representative',
	name: 'listingsValidation',
	component: ViewListingsValidation,
}, {
	path: '/listings-validation-sent/:representative',
	name: 'listingsSent',
	component: ViewListingsValidationSent,
}, {
	path: '/login',
	name: 'login',
	component: ViewLogin,
}, {
	path: '/locations',
	name: 'locations',
	component: ViewLocations,
	meta: { requiresAuth: true },
}, {
	path: '/locations/new',
	name: 'newLocation',
	component: ViewNewLocation,
	meta: { requiresAuth: true },
}, {
	path: '/locations/:location/areas',
	name: 'areas',
	component: ViewAreas,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/areas/new',
	name: 'newArea',
	component: ViewNewArea,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/areas/:area',
	name: 'area',
	component: ViewArea,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/brands',
	name: 'brands',
	component: ViewBrands,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/brands/new',
	name: 'newBrand',
	component: ViewNewBrand,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/brands/:brand',
	name: 'brand',
	component: ViewBrand,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/brokerages',
	name: 'brokerages',
	component: ViewBrokerages,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/brokerages/new',
	name: 'newBrokerage',
	component: ViewNewBrokerage,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/brokerages/:brokerage',
	name: 'brokerage',
	component: ViewBrokerage,
	meta: {
		requiresAuth: true,
		menu: 'location',
	},
}, {
	path: '/locations/:location/cities',
	name: 'cities',
	component: ViewCities,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/cities/new',
	name: 'newCity',
	component: ViewNewCity,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/cities/:city',
	name: 'city',
	component: ViewCity,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/complexes',
	name: 'complexes',
	component: ViewComplexes,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/complexes/new',
	name: 'newComplex',
	component: ViewNewComplex,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/complexes/:complex',
	name: 'complex',
	component: ViewComplex,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/historical-datasets',
	name: 'historicalDatasets',
	component: ViewHistoricalDatasets,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/listings',
	name: 'listings',
	component: ViewListings,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/listings/:listing',
	name: 'listing',
	component: ViewListing,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/listings/:listing/activity',
	name: 'listingActivity',
	component: ViewListingActivity,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/listings/:listing/map',
	name: 'listingMap',
	component: ViewListingMap,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/listings/:listing/notes',
	name: 'listingNotes',
	component: ViewListingNotes,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/realtors',
	name: 'realtors',
	component: ViewRealtors,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/realtors/new',
	name: 'newRealtor',
	component: ViewNewRealtor,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/realtors/:realtor',
	name: 'realtor',
	component: ViewRealtor,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/locations/:location/settings',
	name: 'locationSettings',
	component: ViewLocationSettings,
	meta: {
		requiresAuth: true,
		menu: 'location'
	},
}, {
	path: '/markets',
	name: 'markets',
	component: ViewMarkets,
	meta: {
		requiresAuth: true,
	},
}, {
	path: '/markets/new',
	name: 'newMarket',
	component: ViewNewMarket,
	meta: {
		requiresAuth: true,
	},
}, {
	path: '/markets/:market/listings',
	name: 'marketListings',
	component: ViewMarketListings,
	meta: {
		requiresAuth: true,
		menu: 'market',
	},
}, {
	path: '/markets/:market/representatives',
	name: 'representatives',
	component: ViewRepresentatives,
	meta: {
		requiresAuth: true,
		menu: 'market',
	},
}, {
	path: '/markets/:market/representatives/new',
	name: 'newRepresentative',
	component: ViewNewRepresentative,
	meta: {
		requiresAuth: true,
		menu: 'market',
	},
}, {
	path: '/markets/:market/representatives/:representative',
	name: 'representative',
	component: ViewRepresentative,
	meta: {
		requiresAuth: true,
		menu: 'market',
	},
}, {
	path: '/markets/:market/settings',
	name: 'marketSettings',
	component: ViewMarketSettings,
	meta: {
		requiresAuth: true,
		menu: 'market',
	},
}, {
	path: '/my-account',
	name: 'me',
	component: ViewMe,
	meta: {
		requiresAuth: true,
	},
}, {
	path: '/404',
	name: '404',
	component: ViewNotFound,
}, {
	path: '*',
	redirect: '404',
}]

export const navigation = {
	main: {
		menu: [{
			icon: 'mdi-map-marker-multiple',
			title: 'Locations',
			to: { name: 'locations' },
			permission: 'location:political-area:*.read',
		}, {
			icon: 'mdi-store',
			title: 'Markets',
			to: { name: 'markets' },
			permission: 'realestate:market:*.read',
		}, {
			icon: 'mdi-comment-alert',
			title: 'Complex Requests',
			to: {name: 'complexRequests' },
			permission: 'location:complex-request:*.read',
		}, {
			title: 'Contacts',
			icon: 'mdi-alphabetical-variant',
			to: { name: 'contacts' },
			permission: 'realestate:contact:*.read',
		}, {
			icon: 'mdi-home-analytics',
			title: 'Custom RPA',
			to: {name: 'customRPAs' },
			permission: 'realestate:custom-rental-projection-analysis:*.read',
		}, {
			icon: 'mdi-shield-crown',
			title: 'Admin',
			color: 'warning',
			activeColor: true,
			to: {name: 'users' },
			permission: 'location:user:*.read',
		}]
	},
	admin: {
		menu: [{
			icon: 'mdi-account',
			title: 'Users',
			to: { name: 'users' },
			permission: 'auth:user:*.read',
		}, {
			icon: 'mdi-lock',
			title: 'Roles',
			to: { name: 'roles' },
			permission: 'auth:role:*.read',
		}, {
			icon: 'mdi-key',
			title: 'Access Keys',
			to: { name: 'accessKeys' },
			permission: 'auth:access-key:*.read',
		}, {
			icon: 'mdi-lock',
			title: 'Sessions',
			to: { name: 'sessions' },
			permission: 'auth:session:*.read',
		}],
		navButton: {
			text: 'Admin',
			to: {
				name: 'home',
			},
			color: 'warning',
		},
	},
	location: {
		menu: [{
			title: 'Cities',
			icon: 'mdi-city-variant',
			to:  { name: 'cities' },
			permission: 'location:city:*.read',
		}, {
			title: 'Areas',
			icon: 'mdi-selection-marker',
			to: { name: 'areas' },
			permission: 'location:area:*.read',
		}, {
			title: 'Complexes',
			icon: 'mdi-domain',
			to: { name: 'complexes' },
			permission: 'location:complex:*.read',
		}, {
			title: 'Listings',
			icon: 'mdi-home-city',
			to: { name: 'listings' },
			permission: 'realestate:listing:*.read',
		}, {
			title: 'Brokerages',
			icon: 'mdi-briefcase',
			to: { name: 'brokerages' },
			permission: 'realestate:brokerage:*.read',
		}, {
			title: 'Realtors',
			icon: 'mdi-briefcase-account',
			to: { name: 'realtors' },
			permission: 'realestate:realtor:*.read',
		}, {
			title: 'Historical Datasets',
			icon: 'mdi-chart-timeline-variant',
			to: { name: 'historicalDatasets' },
			permission: 'realestate:historical-dataset:*.read',
		}, {
			title: 'Brands',
			icon: 'mdi-alphabetical-variant',
			to: { name: 'brands' },
			permission: 'realestate:brand:*.read',
		}, {
			title: 'Settings',
			icon: 'mdi-cogs',
			to:  { name: 'locationSettings' },
			permission: 'location:political-area:*.update',
		}],
		navButton: {
			api: 'default',
			fn: 'getPoliticalArea',
			id: 'location',
			field: 'name',
			to: {
				name: 'locations',
			}
		},
	},
	market: {
		menu: [{
			title: 'Listings',
			icon: 'mdi-home-city',
			to: { name: 'marketListings' },
		}, {
			title: 'Representatives',
			icon: 'mdi-account-tie',
			to: { name: 'representatives' },
			permission: 'realestate:representative:*.read',
		}, {
			title: 'Email Report',
			icon: 'mdi-chart-line',
		}, {
			title: 'Settings',
			icon: 'mdi-cogs',
			to: { name: 'marketSettings' },
			permission: 'realestate:market:*.update',
		}],
		navButton: {
			api: 'default',
			fn: 'getMarket',
			id: 'market',
			field: 'name',
			to: {
				name: 'markets',
			}
		},
	},
}
