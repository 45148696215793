<template>
	<base-card class="listing-sent-card" max-width="1200">
		<base-card-text>
			<base-row>
				<base-col cols="12" md="4" class="pa-0 pa-sm-4">
					<v-img :src="listing.featuredImage" :alt="`${listing.title} Featured Image`" />
				</base-col>
				<base-col cols="12" md="8" align="left">
					<base-row>
						<base-col cols="12" md="8">
							<a v-if="$vuetify.breakpoint.xs" :href="`https://www.marketmath.com/rental-analysis/${listing.uriName}`" target="_blank">
								<h2 class="text-subtitle-2 primary--text">
									{{listing.title}}
									<base-icon size="20" color="primary" class="ml-1">mdi-open-in-new</base-icon>
								</h2>
							</a>
							<h2 v-else class="primary--text">
								{{listing.title}}
							</h2>
							<div>{{listing.area}}</div>
						</base-col>
						<base-col cols="12" md="4" class="text-right d-none d-sm-block">
							<base-button
								:href="`https://www.marketmath.com/rental-analysis/${listing.uriName}`"
								target="_blank"
								color="primary"
								text
							>
								View full report
							</base-button>
						</base-col>
					</base-row>
					<div class="mt-2">
						<b>Realtor Name:&nbsp;</b>
						<span v-if="listing.realtor">{{listing.realtor.name}}</span>
						<span v-else><i>Removed</i></span>
					</div>
					<div>
						<b>Phone:&nbsp;</b>
						<span v-if="listing.realtor && listing.realtor.phone">
							<a :href="`tel:${listing.realtor.phone}`">{{phoneFormatted}}</a>
						</span>
						<span v-else><i>None</i></span>
					</div>
					<div>
						<b>Email:&nbsp;</b>
						<span v-if="listing.realtor">
							<a :href="`mailto:${listing.realtor.email}`">{{listing.realtor.email}}</a>
						</span>
					</div>
					<div>
						<b>Address:&nbsp;</b> 
						<span>{{listing.address}}</span>
					</div>
					<base-row>
						<base-col class="pt-4 pb-1 pb-sm-4" sm="4" align-self="end">
							<div><b>Revenue:</b></div>
							<div>{{revenueFormatted}}</div>
						</base-col>
						<base-col class="py-1 py-sm-4" sm="4" align-self="end">
							<div><b>Occ. rate:</b></div>
							<div>{{occupancyFormatted}}</div>
						</base-col>
						<base-col class="py-1 py-sm-4" sm="4" align-self="end">
							<div><b>ADR:</b></div>
							<div>{{adrFormatted}}</div>
						</base-col>
					</base-row>
					<v-divider class="my-2 my-sm-4" />
					<base-row>
						<base-col class="pt-4 pb-1 pb-sm-4" sm="4" align-self="end">
							<div><b>Delivered</b></div>
							<div v-if="listing.metrics">{{listing.metrics.delivered ? 'Yes' : 'No'}}</div>
							<div v-else>Not Tracked</div>
						</base-col>
						<base-col class="py-1 py-sm-4" sm="4" align-self="end">
							<div><b>Opens</b></div>
							<div v-if="listing.metrics">{{listing.metrics.open || 0}}</div>
							<div v-else>Not Tracked</div>
						</base-col>
						<base-col class="py-1 py-sm-4" sm="4" align-self="end">
							<div><b>Clicks</b></div>
							<div v-if="listing.metrics">{{listing.metrics.click || 0}}</div>
							<div v-else>Not Tracked</div>
						</base-col>
					</base-row>
					<div class="text-right mt-sm-8 mt-4">
						<base-button
							v-if="!showNotes"
							class="mr-2"
							color="primary"
							@click="showNotes = true; showActivity = false"
						>
								View Notes
						</base-button>
						<base-button
							v-else
							class="mr-2"
							color="secondary"
							@click="showNotes = false"
						>
								Hide Notes
						</base-button>
						<base-button
							v-if="!showActivity"
							:disabled="!listing.metrics"
							color="primary"
							@click="showActivity = true; showNotes = false"
						>
								View Activity
						</base-button>
						<base-button
							v-else
							color="secondary"
							@click="showActivity = false"
						>
								Hide Activity
						</base-button>
					</div>
				</base-col>
			</base-row>
			<base-row v-if="showActivity">
				<base-col>
					<v-divider class="my-2 my-sm-4" />
					<h4 class="text-subtitle-2 text-sm-h6">Activity Logs</h4>
					<sent-listing-activity-logs-table :representative="props.representative" :listing="props.listing._id" />
				</base-col>
			</base-row>
			<base-row v-if="showNotes">
				<base-col>
					<v-divider class="my-2 my-sm-4" />
					<h4 class="text-subtitle-2 text-sm-h6">Notes</h4>
					<base-row class="my-1">
						<v-spacer v-if="!$vuetify.breakpoint.xs" />
						<base-col class="text-right">
							<base-button
								:block="$vuetify.breakpoint.xs"
								color="primary"
								small
								@click="addNoteDialog = true"
							>Add Note</base-button>
						</base-col>
					</base-row>
					<sent-listing-notes-table
						:representative="props.representative"
						:listing="props.listing._id"
						:refresh.sync="refreshNotes"
					/>
				</base-col>

				<sent-listing-add-note-modal
					v-model="addNoteDialog"
					:representative="props.representative"
					:listing="props.listing._id"
					@add="refreshNotes = true"
				/>
			</base-row>
		</base-card-text>
	</base-card>
</template>

<script setup>
import { ref, toRef, computed } from 'vue'

import SentListingActivityLogsTable from '@/components/fragments/listingActivity/SentListingActivityLogsTable'
import SentListingNotesTable from '@/components/fragments/listingNote/SentListingNotesTable'
import SentListingAddNoteModal from '@/components/fragments/listingNote/SentListingAddNoteModal'

// Props
const props = defineProps({
	representative: {
		type: String,
		required: true,
	},
	listing: {
		type: Object,
		required: true,
	}
})

// Data
const listing = toRef(props, 'listing')
const showActivity = ref(false)
const showNotes = ref(false)
const addNoteDialog = ref(false)
const refreshNotes = ref(false)

// Computed
const revenueFormatted = computed(() => `$${listing.value.revenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`)
const adrFormatted = computed(() => `$${listing.value.adr.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`)
const occupancyFormatted = computed(() => `${listing.value.occupancyRate.toFixed(2)}%`)
const phoneFormatted = computed(() => {
	const phone = listing.value.realtor && listing.value.realtor.phone
	if (!phone || phone == '') { return '' }
	const match = /1?([0-9]{3})([0-9]{3})([0-9]{4})/.exec(phone)
	if (!match) { return phone }
	return `(${match[1]}) ${match[2]}-${match[3]}`
})
</script>

<style scoped>
	.validated-text {
		padding: 0px 16px;
		text-transform: uppercase;
	}
</style>