export default class GraphQLResponse {
	#data
	#errors

	/**
	 * @constructor
	 * @param {object} response - GraphQL JSON response
	 * @param {object} [response.errors=[]] - GraphQL errors
	 * @param {object} [response.data={}] - GraphQL data
	 */
	constructor({ data = {}, errors = []}) {
		this.#data = data
		this.#errors = errors.reduce((acc, error) => {
			
			const { path, ...values } = error
			const key = path.join('.')
			if (!acc[key]) {
				acc[key] = []
			}
			acc[key].push(values)

			return acc
		}, {})
	}

	/**
	 * Get function data
	 * @param {string} name - Function name
	 * @returns {object|array} - Function data
	 */
	data(name) {
		return this.#data[name]
	}

	/**
	 * Whether function has been successful
	 * @param {string} name - Function name
	 * @returns {boolean} - Whether function has been successful
	 */
	success(name) {
		return !this.#errors[name] || !this.#errors[name].length
	}

	/**
	 * Get errors
	 * @param {string} name - Function name
	 * @returns {array} - Function errors
	 */
	errors(name) {
		return this.#errors[name] || []
	}

	/**
	 * Get function response
	 * @param {string} name - Function name
	 * @returns {object} - Function response
	 */
	get(name) {
		return {
			data: this.data(name),
			success: this.success(name),
			errors: this.errors(name),
		}
	}
}