<template>
	<div class="market-form">
		<base-text-field v-model="name" label="Name*" />
		<base-autocomplete v-model="timezone" :items="timezones" label="Timezone*" />
		<base-select v-model="validationExpirationTime" :items="expirationTimes" label="Validation Expiration Time*" />
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'
import { getTimezones } from '@/utils/timezone'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['create', 'save'])

// Constants
const REQUIRED_FIELDS = ['name', 'timezone']

// Modules
const api = useApi()

// Data
const expirationTimes = [{
	text: 'Never',
	value: -1,
}, {
	text: '24h',
	value: 24,
}, {
	text: '48h',
	value: 48,
}, {
	text: '72h',
	value: 72,
}]

const name = ref(null)
const timezone = ref(null)
const validationExpirationTime = ref(24)
const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	timezone,
	validationExpirationTime,
}, REQUIRED_FIELDS)

// Computed
const timezones = computed(() => getTimezones())

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createMarket')
		.arg('input', form)
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createMarket')
	if (success) {
		emit('create', data._id)
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateMarket')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('name', 'timezone', 'validationExpirationTime')
	
	const result = await request.exec()
	const { success, data } = result.get('updateMarket')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getMarket')
		.arg('id', id)
		.fields('name', 'timezone', 'validationExpirationTime')

	const result = await request.exec()
	const { data } = result.get('getMarket')

	loadOriginalData(data)
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.market-form {
		max-width: 320px;
	}
</style>