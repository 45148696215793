<template>
	<div class="view-new-complex-fromrequest">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<complex-form :id="id" @create="onCreated" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import ComplexForm from '@/components/fragments/complexRequest/ComplexForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute, useRouter } from '@/plugins/router'

// Modules
const route = useRoute()
const router = useRouter()

// Computed
const id = computed(() => route.params.request)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Complex requests',
	to: { name: 'complexRequests' },
}, {
	api: 'marketmath',
	fn: 'getComplexRequest',
	id: id.value,
	field: 'name',
}])

function onCreated() {
	router.push({ name: 'complexRequests' })
}
</script>