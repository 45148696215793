<template>
	<base-modal
		:title="title"
		color="error"
		:actions="actions"
		:value="show"
		@click="onModalClick"
	>
		<span v-html="text" />
	</base-modal>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

import { useApi } from '@/plugins/api'

// Props & Emits
const props = defineProps({
	value: {
		type: Boolean,
	},
	id: {
		type: String,
	},
	component: {
		type: String,
		required: true,
	},
	deleteFn: {
		type: String,
		required: true,
	},
	deleteCb: {
		type: Function,
	},
	actionName: {
		type: String,
		default: 'Delete',
	},
	text: {
		type: String,
		default: '',
	}
})
const emit = defineEmits(['input', 'delete'])

// Data
const api = useApi()
const loading = ref(false)

const actions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	text: 'Confirm',
	value: 'confirm',
	color: 'error',
	loading,
})]

// Computed
const show = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

const title = computed(() => `${props.actionName} ${props.component}`)
const text = computed(() => `Are you sure you want to ${props.actionName.toLowerCase()} ${props.component.toLocaleLowerCase()} <b>${props.text}</b>?`)

async function deleteEntry(id) {
	loading.value = true
	const request = api.graphql()
	
	if (props.deleteCb) { props.deleteCb(request, id) }
	else request.mutation(props.deleteFn).arg('id', id).fields('_id')

	const result = await request.exec()
	const { success } = result.get(props.deleteFn)
	if (success) {
		emit('delete', true)
	}
}

// Methods
async function onModalClick(value) {
	if (value === 'confirm') {
		await deleteEntry(props.id)
	}
	show.value = false
	loading.value = false
}
</script>