<template>
	<span class="base-input base-textarea">
		<v-textarea v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners" />
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	outlined: true,
	dense: true,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>