<template>
	<div class="base-file-reader">
		<input
			ref="file"
			type="file"
			:multiple="props.multiple"
			:accept="accept"
			@change="onChange($event.target.files)"
		/>
	</div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

// Props & Emit
const props = defineProps({
	type: {
		type: String,
		default: 'all',
		validator: val => ['image', 'autio', 'video', 'text', 'json', 'csv', 'all'].includes(val),
	},
	multiple: {
		type: Boolean,
		default: false,
	},
	show: {
		type: Boolean,
		default: false,
	},
	files: {
		type: [Array, FileList],
		default: () => [],
	},
})

const emit = defineEmits(['load', 'update:show', 'update:files'])

// Data
const file = ref()
let files = []
let total = 0

// Computed
const show = computed({
	get: () => props.show,
	set: (value) => emit('update:show', value),
})

const externalFiles = computed({
	get: () => props.files,
	set: (files) => emit('update:files', files),
})

const accept = computed(() => {
	switch (props.type) {
	case 'image':
		return 'image/*'
	case 'audio':
		return 'audio/*'
	case 'video':
		return 'video/*'
	case 'json':
		return 'application/json'
	case 'csv':
		return '.csv'
	case 'all':
		return '*'
	default:
		return 'text/*'
	}
})

const readerMethod = computed(() => {
	switch (props.type) {
	case 'image':
	case 'audio':
	case 'video':
	case 'all':
		return 'readAsDataURL'
	default:
		return 'readAsText'
	}
})

// Watcher
watch(show, (value) => {
	if (value) {
		file.value.click()
		show.value = false
	}
})

watch(externalFiles, (value) => {
	if (value && value.length) {
		onChange([...externalFiles.value])
		externalFiles.value = []
	}
})

// Methods
function formatContent(content) {
	switch (props.type) {
	case 'json':
		return JSON.parse(content)
	case 'csv':
		/* return csvtojson({
			delimiter: 'auto',
		}).fromString(content) */
		return content
	default:
		return content
	}
}

function handleResult(content) {
	const res = formatContent(content)
	files.push(res)

	if (files.length === total) {
		emit('load', props.multiple ? files : files[0])
		file.value.value = null
	}
}

function onChange(eventFiles) {
	const inputFiles = [...eventFiles]
	files = []
	total = inputFiles.length
	const reader = new FileReader()
	reader.onload = e => handleResult(e.target.result)

	inputFiles.forEach((file) => {
		reader[readerMethod.value](file)
	})
}
</script>

<style scoped>
	.base-file-reader {
		display: none;
	}
</style>