<template>
	<div class="brand-images-form">
		<base-image-upload
			v-model="presentationBackground"
			:src="presentationBackgroundUrl"
			width="800"
			height="1200"
			label="Presentation Background Image* (800x1200)"
			resize
		/>
		<base-image-upload
			v-model="dataProviders"
			:src="dataProvidersUrl"
			width="800"
			height="320"
			label="Data Providers Image* (800x320)"
			resize
		/>
		<base-button
			block
			color="primary"
			:loading="loading"
			:disabled="!hasChanges"
			@click="onSaveChanges(props.id)"
		>
			Save Changes
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	politicalArea: {
		type: String,
	}
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const presentationBackground = ref(null)
const dataProviders = ref(null)
const presentationBackgroundUrl = ref(null)
const dataProvidersUrl = ref(null)
const loading = ref(false)

const { changes, hasChanges, loadOriginalData } = useFormStates({
	presentationBackground,
	dataProviders,
})

// Methods
async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateBrand')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('presentationBackgroundUrl', 'dataProvidersUrl')
	
	const result = await request.exec()
	const { success, data } = result.get('updateBrand')
	if (success) {
		const {
			presentationBackgroundUrl: updatedPresentationBackgroundUrl,
			dataProvidersUrl: updatedDataProvidersUrl,
		} = data
		presentationBackgroundUrl.value = updatedPresentationBackgroundUrl
		presentationBackground.value = null
		dataProvidersUrl.value = updatedDataProvidersUrl
		dataProviders.value = null

		loadOriginalData({
			presentationBackground: null,
			dataProviders: null,
		})
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getBrand')
		.arg('id', id)
		.fields('presentationBackgroundUrl', 'dataProvidersUrl')

	const result = await request.exec()
	const { data } = result.get('getBrand')

	const {
		presentationBackgroundUrl: originalPresentationBackgroundUrl,
		dataProvidersUrl: originalDataProvidersUrl,
	} = data
	presentationBackgroundUrl.value = originalPresentationBackgroundUrl
	presentationBackground.value = null
	dataProvidersUrl.value = originalDataProvidersUrl
	dataProviders.value = null

	loadOriginalData({
		presentationBackground: null,
		dataProviders: null,
	})
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.brand-images-form {
		max-width: 800px;
	}
</style>