import { ref, reactive, computed } from 'vue'

function checkChange(value, originalValue) {
	const normalizedValue = typeof value === 'object' ? JSON.stringify(value) : value
	const normalizedOriginalValue = typeof originalValue === 'object' ? JSON.stringify(originalValue) : originalValue

	return normalizedValue !== normalizedOriginalValue
}

export default function(formData, requiredFields = []) {
	const original = ref({})
	const form = reactive(formData)

	const changes = computed(() => {
		return Object.keys(form).reduce((acc, field) => {
			const value = form[field]
			if (checkChange(value, original.value[field])) {
				acc[field] = value
			}
			return acc
		}, {})
	})
	const isFilled = computed(() => {
		return requiredFields.every((field) => form[field])
	})
	const hasChanges = computed(() => {
		return Object.keys(original.value).some((field) => checkChange(form[field], original.value[field]))
	})

	function loadOriginalData(data) {
		const firstLoad = !Object.keys(original.value).length
		original.value = data

		if (firstLoad) {
			Object.keys(form).forEach((field) => {
				form[field] = typeof data[field] === 'object' ? JSON.parse(JSON.stringify(data[field])) : data[field]
			})
		}
	}

	return {
		form,
		changes,
		isFilled,
		hasChanges,
		loadOriginalData,
	}
}
