<template>
	<base-expansion-panels>
		<base-expansion-panel class="white">
			<template v-slot:header>
				<base-row no-gutters>
					<base-col cols="6" md="8" align-self="center">Filters</base-col>
					<base-col cols="6" md="4" align="right">
						<base-text-field
							v-model="searchInput"
							no-borders
							prepend-inner-icon="mdi-magnify"
							hide-details
							placeholder="Search"
							clearable
							width="400"
							@click.stop
							@keyup.space.prevent
						/>
					</base-col>
				</base-row>
			</template>
			<base-card>
				<base-card-text>
					<base-row>
						<base-col cols="12" sm="4" md="3" lg="2">
							<base-autocomplete v-model="brokerageId" :items="brokerages" :loading="loadingBrokerages" label="Brokerage" clearable />
						</base-col>
					</base-row>
				</base-card-text>
				<base-card-actions>
					<base-button color="primary" :disabled="!hasChanges" @click="onApply">Apply</base-button>
				</base-card-actions>
			</base-card>
		</base-expansion-panel>
	</base-expansion-panels>
</template>

<script setup>
import { watch, computed, ref, reactive } from 'vue'

import useDataSource from '@/features/useDataSource'
import useFilters from '@/features/useFilters'
import useDebounce from '@/features/useDebounce'

// Props & Emits
const props = defineProps({
	value: {
		type: Object,
		default: () => ({}),
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['input'])

// Data
const searchInput = ref(props.value.search)
const search = ref(props.value.search)
const brokerageId = ref(props.value.brokerageId)
const form = reactive({
	brokerageId,
	search,
})

const { entries: brokerages, loading: loadingBrokerages } = useDataSource('getBrokerages', {
	query: { politicalAreaId: props.politicalArea },
	init: {
		fn: 'getBrokerage',
		value: brokerageId.value,
	}
})

// Computed
const filters = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

// Watchers
watch(searchInput, (value) => {
	onSearch(value)
})

const { hasChanges, onApply } = useFilters(form, filters)

// Methods
const onSearch = useDebounce((value) => {
	search.value = value
	onApply()
}, 500)
</script>