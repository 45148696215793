<template>
	<div class="listing-map">
		<base-card>
			<base-card-title>Location</base-card-title>
			<base-card-text>
				<base-row>
					<base-col cols="12" sm="4" md="3">
						<base-autocomplete v-model="cityId" :loading="loadingCities" :items="cities" label="City" />
					</base-col>
				</base-row>
			</base-card-text>
		</base-card>
		<div v-if="center" class="map mt-4">
			<gmap-map :zoom="zoom" :center="center" style="width: 100%; height: 500px; max-width: 800px;" ref="map">
				<gmap-marker
					:position="center"
					@click="listingInfoWindow = true"
				/>
				<gmap-info-window
					:options="{
						pixelOffset: {
							width: 0,
							height: -35,
						},
					}"
					:position="center"
					:opened="listingInfoWindow"
					@closeclick="listingInfoWindow = false"
				>
					{{title}}
				</gmap-info-window>
				<gmap-polygon
					v-for="(areaPaths, i) in areasPaths"
					:key="`area-polygon-${i}`"
					:options="{
						strokeColor: i === selectedArea ? '#8ecb64' : '#6a6a6a',
						fillColor: i === selectedArea ? '#8ecb64' : '#6a6a6a',
					}"
					title="ABC"
					:paths="areaPaths"
					@click="selectArea(i, areaPaths)"
				>
				</gmap-polygon>
				<gmap-info-window
					:options="{
						pixelOffset: {
							width: 0,
							height: 0,
						},
					}"
					:position="areaInfoPosition"
					:opened="areaInfoWindow"
					@closeclick="unselectArea()"
				>
					{{areaInfoText}}
				</gmap-info-window>
			</gmap-map>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useDataSource from '@/features/useDataSource'
import useGeoJSON from '@/features/useGeoJSON'

// Constants
const AREAS_FETCH_LIMIT = 25
const DEFAULT_ZOOM_LEVEL = 12

// Props & Emits
const props = defineProps({
	listing: {
		type: String,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})

// Modules
const api = useApi()

// Data
const selectedArea = ref()
const areaInfoText = ref()
const areaInfoPosition = ref()
const areaInfoWindow = ref(false)
const listingInfoWindow = ref(true)
const title = ref()
const center = ref()
const cityId = ref(null)
const areasPaths = ref([])
const areasNames = ref([])
const zoom = ref(DEFAULT_ZOOM_LEVEL)

const { entries: cities, loading: loadingCities } = useDataSource('getCities', {
	query: { politicalAreaId: props.politicalArea },
})

// Watchers
watch(cityId, (value) => {
	if (value) {
		loadAreas(value)
	}
})

// Methods
const {
	geoJSONCoordinatesToPaths,
} = useGeoJSON()

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getListing')
		.arg('id', id)
		.fields('title', 'lat', 'long')
		.child('getArea').fields('cityId')

	const result = await request.exec()
	const { data } = result.get('getListing')
	title.value = data.title
	center.value = { lat: data.lat, lng: data.long }
	if (data.getArea) {
		cityId.value = data.getArea.cityId
	}
}

async function loadAreas(id) {
	let count = 0
	let skip = 0
	let paths = []
	let names = []

	do {
		const request = api.graphql()
		const query = request.query('getAreas').arg('query', {
			politicalAreaId: props.politicalArea,
			cityId: id,
			limit: AREAS_FETCH_LIMIT,
			skip,
		}).fields('_id', 'name')
		query.child('geolocation').fields('coordinates')
		
		const result = await request.exec()
		const { data } = result.get('getAreas')
		count = data && data.length
		data.forEach(({ name, geolocation: { coordinates: areaCoordinates } }) => {
			paths.push(geoJSONCoordinatesToPaths(areaCoordinates))
			names.push(name)
		})
		skip += AREAS_FETCH_LIMIT
	} while (count === AREAS_FETCH_LIMIT)

	areasPaths.value = paths
	areasNames.value = names
}

function selectArea(i, areaPaths) {
	let topCoordinates = null

	areaPaths.forEach((path) => {
		path.forEach(({ lng, lat }) => {
			if (!topCoordinates || topCoordinates.lat < lat) {
				topCoordinates = { lng, lat }
			}
		})
	})

	if (topCoordinates) {
		selectedArea.value = i
		areaInfoPosition.value = topCoordinates
		areaInfoText.value = areasNames.value[i]
		areaInfoWindow.value = true
	}
}

function unselectArea() {
	selectedArea.value = null
	areaInfoWindow.value = false
}

onMounted(async () => {
	fetchEntry(props.listing)
})
</script>

<style scoped>
	.listing-basic-form {
		max-width: 600px;
	}
	.button-wrapper {
		max-width: 320px;
	}
</style>