<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.area`]="{ item }">
			<div>{{item.getArea.name}}</div>
			<div>{{item.getArea.getCity.name}}</div>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	politicalArea: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Website URL',
	value: 'uriName',
}, {
	text: 'Address',
	value: 'address',
	sortable: false,
}, {
	text: 'Area/City',
	value: 'area',
	sortable: false,
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	politicalAreaId: props.politicalArea,
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'name',
	headers,
	filters,
	getFn: 'getComplexes',
	getCb: (query) => {
		query.child('getArea').fields('name').child('getCity').fields('name')
	},
	countFn: 'countComplexes',
	fields: ['_id', 'name', 'address', 'uriName'],
}))

</script>

<script>
export default {
	inheritAttrs: false
}
</script>