<template>
	<div class="user-form">
		<base-text-field v-model="password" type="password" label="Old password" />
		<base-text-field v-model="newPassword" type="password" label="New password" />
		<base-text-field v-model="confirmPassword" type="password" label="Confirm new password" />
		<base-button
			block
			color="primary"
			:disabled="!isFilled"
			@click="onUpdate()"
		>
			Update
		</base-button>
	</div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'

import { useApi } from '@/plugins/api'

// Props & Emits
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const password = ref()
const newPassword = ref()
const confirmPassword = ref()
const form = reactive({ password, newPassword, confirmPassword })

// Computed
const isFilled = computed(() => password.value && newPassword.value && confirmPassword.value)

// Methods
async function onUpdate() {
	const request = api.graphql()

	request.mutation('updateMyPassword')
		.arg('input', form)
		.fields('success')
	
	const result = await request.exec()
	const { success } = result.get('updateMyPassword')
	if (success) {
		password.value = undefined
		newPassword.value = undefined
		confirmPassword.value = undefined
		emit('save', true)
	}
}
</script>

<style scoped>
	.user-form {
		max-width: 320px;
	}
</style>