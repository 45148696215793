<template>
	<generic-deletion-modal v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners" />
</template>

<script setup>
import { computed } from 'vue'

import GenericDeletionModal from '../GenericDeletionModal.vue'

const props = defineProps({
	discard: {
		type: Boolean,
		default: false,
	},
})

const defaultAttrs = computed(() => ({
	deleteFn: props.discard ? 'updateListing' : 'deleteListing',
	deleteCb: props.discard ? discardListing : undefined,
	actionName: props.discard ? 'Discard' : undefined,
	component: 'Listing',
}))

function discardListing(request, id) {
	request.mutation('updateListing').arg('id', id).arg('input', { status: 'discarded' }).fields('_id')
}
</script>

<script>
export default {
	inheritAttrs: false
}
</script>