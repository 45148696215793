var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"listing-map"},[_c('base-card',[_c('base-card-title',[_vm._v("Location")]),_c('base-card-text',[_c('base-row',[_c('base-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('base-autocomplete',{attrs:{"loading":_setup.loadingCities,"items":_setup.cities,"label":"City"},model:{value:(_setup.cityId),callback:function ($$v) {_setup.cityId=$$v},expression:"cityId"}})],1)],1)],1)],1),(_setup.center)?_c('div',{staticClass:"map mt-4"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"500px","max-width":"800px"},attrs:{"zoom":_setup.zoom,"center":_setup.center}},[_c('gmap-marker',{attrs:{"position":_setup.center},on:{"click":function($event){_setup.listingInfoWindow = true}}}),_c('gmap-info-window',{attrs:{"options":{
					pixelOffset: {
						width: 0,
						height: -35,
					},
				},"position":_setup.center,"opened":_setup.listingInfoWindow},on:{"closeclick":function($event){_setup.listingInfoWindow = false}}},[_vm._v(" "+_vm._s(_setup.title)+" ")]),_vm._l((_setup.areasPaths),function(areaPaths,i){return _c('gmap-polygon',{key:`area-polygon-${i}`,attrs:{"options":{
					strokeColor: i === _setup.selectedArea ? '#8ecb64' : '#6a6a6a',
					fillColor: i === _setup.selectedArea ? '#8ecb64' : '#6a6a6a',
				},"title":"ABC","paths":areaPaths},on:{"click":function($event){return _setup.selectArea(i, areaPaths)}}})}),_c('gmap-info-window',{attrs:{"options":{
					pixelOffset: {
						width: 0,
						height: 0,
					},
				},"position":_setup.areaInfoPosition,"opened":_setup.areaInfoWindow},on:{"closeclick":function($event){return _setup.unselectArea()}}},[_vm._v(" "+_vm._s(_setup.areaInfoText)+" ")])],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }