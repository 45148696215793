<template>
	<div class="view-cities">
		<cities-header :breadcrumb="breadcrumb" :political-area="politicalAreaId" @import="refresh = true"/>
		<cities-table :political-area="politicalAreaId" :refresh.sync="refresh">
			<template v-slot:actions="item">
				<base-button
					v-if="can(`location:city:${item._id}.update`)"
					:to="{
						name: 'city',
						params: { city: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="can(`location:city:${item._id}.delete`)"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, item.name)"
				>Delete</base-button>
			</template>
		</cities-table>
		<city-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useSession from '@/features/useSession'

import CitiesHeader from '@/components/fragments/city/CitiesHeader.vue'
import CitiesTable from '@/components/fragments/city/CitiesTable.vue'
import CityDeletionModal from '@/components/fragments/city/CityDeletionModal.vue'

// Plugins
const route = useRoute()

// Data
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

// Computed
const politicalAreaId = computed(() => route.params.location)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Cities',
}])

// Methods
const { can } = useSession()

function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}

</script>
