<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.user`]="{ item }">
			{{ item.getUser.firstName }} {{ item.getUser.lastName }} ({{item.getUser.username}})
		</template>
		<template v-slot:[`item.lastRefresh`]="{ item }">
			{{formatDateTime(item.lastRefresh)}}
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

import { formatDateTime } from '@/utils/datetime'

const props = defineProps({
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Last Refresh',
	value: 'lastRefresh',
}, {
	text: 'User',
	value: 'user',
	sortable: false,
}, {
	text: 'IP',
	value: 'ip',
}, {
	text: 'Country',
	value: 'country',
}, {
	text: 'Region',
	value: 'region',
}, {
	text: 'City',
	value: 'city',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'lastRefresh',
	sortDesc: true,
	headers,
	filters,
	getFn: 'getSessions',
	getCb: (query) => {
		query.child('getUser').fields('firstName', 'lastName', 'username')
	},
	countFn: 'countSessions',
	fields: ['_id', 'ip', 'lastRefresh', 'country', 'region', 'city'],
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>
