<template>
	<v-list-item v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<v-list-item-icon v-if="props.prependIcon">
			<base-icon :color="props.colorActive ? $attrs.color : undefined">{{props.prependIcon}}</base-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title>
				<slot />
			</v-list-item-title>
		</v-list-item-content>
		<v-list-item-icon v-if="props.appendIcon">
			<base-icon :color="props.colorActive ? $attrs.color : undefined">{{props.appendIcon}}</base-icon>
		</v-list-item-icon>
	</v-list-item>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	prependIcon: {
		type: String,
	},
	appendIcon: {
		type: String,
	},
	colorActive: {
		type: Boolean,
		default: false,
	},
})

const defaultAttrs = computed(() => ({
	inputValue: props.colorActive ? true : undefined,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>