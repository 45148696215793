import GraphQLFunction from "./function"
import GraphQLResponse from "./response"

export default class GraphQL {
	#repository 		// Repository
	#queries = []		// Queries
	#mutations = []		// Mutations

	/**
	 * @constructor
	 * @param {object} repository - Repository
	 */
	constructor(repository) {
		this.#repository = repository
	}

	/**
	 * Instanciate new GraphQL query function
	 * @param {string} name - Function name
	 * @returns {GraphQLFunction} - GraphQL function
	 */
	query(name) {
		const fn = new GraphQLFunction(name)
		this.#queries.push(fn)

		return fn
	}

	/**
	 * Instanciate new GraphQL mutation function
	 * @param {string} name - Function name
	 * @returns {GraphQLFunction} - GraphQL function
	 */
	mutation(name) {
		const fn = new GraphQLFunction(name)
		this.#mutations.push(fn)

		return fn
	}

	async exec() {
		const payload = []
		if (this.#queries.length) {
			payload.push(`query {${this.#queries.map((query) => query.toString()).join(' ')}}`)
		}
		if (this.#mutations.length) {
			payload.push(`mutation {${this.#mutations.map((mutation) => mutation.toString()).join(' ')}}`)
		}

		try {
			const result = await this.#repository.graphql({ query: payload.join(' ') })
			return new GraphQLResponse(result.response)
		} catch(err) {
			if (err.response) {
				return new GraphQLResponse(err.response)
			}

			throw err
		}
		
	}
}