<template>
	<base-data-table
		:headers="headers"
		:items="items"
		:items-per-page="25"
		:loading="loading"
		:options.sync="options"
		:server-items-length="total"
		fixed-footer
		:sort-by="['firstName', 'lastName']"
	>
		<template v-slot:[`item.firstName`]="{ item }">
			{{item.firstName}} {{item.lastName}}
		</template>
		<template v-slot:[`item.active`]="{ item }">
			<div v-if="item.active" class="success--text">Yes</div>
			<div v-else class="error--text">No</div>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</base-data-table>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import useDataTableGraphQLRequest from '@/features/useDataTableGraphQLRequest'

const props = defineProps({
	market: {
		type: String,
		required: true,
	},
	refresh: {
		type: Boolean,
		default: false,
	}
})
const emit = defineEmits(['update:refresh'])

// Variables
const headers = [{
	text: 'Name',
	value: 'firstName',
}, {
	text: 'Email Address',
	value: 'email',
}, {
	text: 'Phone Number',
	value: 'phone',
}, {
	text: 'Is Active?',
	value: 'active',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Data
const filters = ref({
	marketId: props.market,
})

const { items, loading, options, total, refreshData } = useDataTableGraphQLRequest({
	getFn: 'getRepresentatives',
	countFn: 'countRepresentatives',
	fields: ['_id', 'firstName', 'lastName', 'email', 'phone', 'active'],
	filters,
})

// Computed
const needsRefresh = computed(() => props.refresh)

// Watchers
watch(needsRefresh, (value, oldValue) => {
	if (value !== oldValue && value === true) {
		refreshData(true)
		emit('update:refresh', false)
	}
})
</script>
