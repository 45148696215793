<template>
	<div class="view-custom-rpas">
		<custom-rpas-header :breadcrumb="breadcrumb" />
		<custom-rpas-table-filters v-model="filters" />
		<custom-rpas-table :refresh.sync="refresh" :page.sync="page" :filters="filters">
			<template v-slot:actions="item">
				<base-button
					:to="{
						name: 'publicRPA',
						params: { rpa: item._id }
					}"
					target="_blank"
					color="primary"
					text
					small
					:disabled="item.status !== 'ready'"
				>Access</base-button>
				<base-button
					v-if="can(`realestate:custom-rental-projection-analysis:${item._id}.update`)"
					:to="{
						name: 'customRPA',
						params: { rpa: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="can(`realestate:custom-rental-projection-analysis:${item._id}.delete`)"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, item.name)"
				>Delete</base-button>
			</template>
		</custom-rpas-table>
		<custom-rpa-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useDataTableRouteState from '@/features/useDataTableRouteState'
import useSession from '@/features/useSession'

import CustomRpasHeader from '@/components/fragments/customRPA/CustomRpasHeader.vue'
import CustomRpasTable from '@/components/fragments/customRPA/CustomRpasTable.vue'
import CustomRpasTableFilters from '@/components/fragments/customRPA/CustomRpasTableFilters.vue'
import customRpaDeletionModal from '@/components/fragments/customRPA/CustomRpaDeletionModal.vue'

// Data
const { page, filters } = useDataTableRouteState()
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

// Computed
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Custom Rental Projection Analyses',
}])

// Methods
const { can } = useSession()
function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}
</script>
