var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-modal',{attrs:{"color":"primary","title":"Add Note","actions":[{
		text: 'Cancel',
		value: 'cancel',
	}, {
		color: 'secondary',
		text: 'Add',
		value: 'add',
		loading: _setup.loading,
	}]},on:{"click":_setup.onAddNote},model:{value:(_setup.dialog),callback:function ($$v) {_setup.dialog=$$v},expression:"dialog"}},[_c('base-text-field',{attrs:{"color":"primary","label":"Note"},model:{value:(_setup.note),callback:function ($$v) {_setup.note=$$v},expression:"note"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }