<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	politicalArea: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Address',
	value: 'address',
}, {
	text: 'Phone Number',
	value: 'phone',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	politicalAreaId: props.politicalArea,
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'name',
	headers,
	filters,
	getFn: 'getBrokerages',
	countFn: 'countBrokerages',
	fields: ['_id', 'name', 'address', 'phone'],
}))

</script>

<script>
export default {
	inheritAttrs: false
}
</script>