import * as Vue from 'vue'
import { default as plugin, PluginSymbol } from './plugin'

const keys = Object.keys(Vue)
export function useApi() {
	// Detect whether to use Vue 3 or fall back to Vue 2
	const VueApi = keys.includes('createApp') ? Vue.inject(PluginSymbol) : Vue.default.$api
	
	if (!VueApi) throw new Error('No VueAPI provided')
	return VueApi
}

export default function createApi(app, settings) {
	app.use(plugin, settings)
}