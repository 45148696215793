<template>
	<div>
		<base-row>
			<base-col cols="12" sm="4">
				<base-text-field v-model="name" label="Name*" />
			</base-col>
		</base-row>
		<base-card>
			<base-card-title>Permissions</base-card-title>
			<base-card-text>
				<base-skeleton v-if="!domains.length" type="heading, table-row@4" />
				<div v-else>
					<div class="permissions" v-for="domain in domains" :key="`domain-${domain.value}`">
						<div class="text-h6 mb-2">{{ domain.text }}</div>
						<base-row>
							<base-col cols="12" sm="4" md="3" v-for="permission in getDomainPermissions(domain.value)" :key="`permission-${permission.value}`">
								<base-select
									:value="formPermissions[permission.value]"
									multiple
									:label="permission.text"
									:items="getAccessItems(permission.access)"
									@input="updateFormPermission(permission.value, $event)"
								/>
							</base-col>
						</base-row>
					</div>
				</div>
				<div class="role-form-section">
					<div class="role-form-content">
						<base-button
							block
							color="primary"
							:loading="loading"
							:disabled="props.new ? !isFilled : !hasChanges"
							@click="props.new ? onCreate() : onSaveChanges(props.id)"
						>
							{{ props.new ? 'Create' : 'Save Changes' }}
						</base-button>
					</div>
				</div>
			</base-card-text>
		</base-card>
	</div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['create', 'save'])

// Constants
const REQUIRED_FIELDS = ['name', 'permissions']
const VALUE_TO_TEXT_OVERRIDES = {
	auth: 'Authentication',
	realestate: 'Real Estate',
	sync: 'Synchronize',
}

// Modules
const api = useApi()

// Data
const name = ref()
const permissions = ref({})
const domains = ref([])
const availablePermissions = ref([])
const formPermissions = ref({})
const loading = ref(false)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	permissions,
}, REQUIRED_FIELDS)

// Watchers
watch(formPermissions, (value, oldValue) => {
	if (Object.keys(oldValue).length) {
		updatePermissions(value)
	}
}, { deep: true})

// Methods
async function onCreate() {
	loading.value = true
	const request = api.graphql()

	request.mutation('createRole')
		.arg('input', {
			...form,
			permissions: JSON.stringify(form.permissions),
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createRole')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateRole')
		.arg('id', id)
		.arg('input', {
			...changes.value,
			...(changes.value.permissions ? {
				permissions: JSON.stringify(changes.value.permissions)
			} : {}),
		})
		.fields('name', 'permissions')
	
	const result = await request.exec()
	const { success, data } = result.get('updateRole')
	if (success) {
		loadOriginalData(data)
		permissions.value = data.permissions
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getRole')
		.arg('id', id)
		.fields('name', 'permissions')

	const result = await request.exec()
	const { data } = result.get('getRole')

	loadOriginalData(data)
	buildFormPermissions()
}

function permissionValueToText(value) {
	if (VALUE_TO_TEXT_OVERRIDES[value]) { return VALUE_TO_TEXT_OVERRIDES[value] }
	const words = value.split(/-|_/)
	return words.map((word) => `${word.charAt(0).toLocaleUpperCase()}${word.substring(1)}`).join(' ')
}

async function fetchPermissions() {
	const request = api.rest()
	const result = await request.get('permissions')
	const { data } = result

	availablePermissions.value = Object.keys(data)
		.reduce((acc, domain) => [
			...acc,
			...Object.values(data[domain]).map((permission) => {
				return {
					...permission,
					domain,
					text: permissionValueToText(permission.shortValue),
					access: permission.access.sort((a, b) => a.localeCompare(b)).map((accessValue) => ({
						value: accessValue,
						text: permissionValueToText(accessValue),
					}))
				}
			})
		], [])

	domains.value = Object.keys(data).sort((a, b) => a.localeCompare(b)).map((value) => ({
		value,
		text: permissionValueToText(value)
	}))
}

function getDomainPermissions(domain) {
	return availablePermissions.value.filter(({ domain: value }) => domain === value)
}

function getAccessItems(access) {
	return [{
		text: 'All',
		value: true,
	}, {
		divider: true,
	}, ...access]
}

function buildFormPermissions() {
	formPermissions.value = availablePermissions.value.reduce((acc, { value: permission }) => {
		const access = (permissions.value && permissions.value[permission]) || {}
		if (access === true) {
			return { ...acc, [permission]: [true] }
		}
		return {
			...acc,
			[permission]: Object.keys(access).reduce((accessAcc, value) => {
				if (access[value]) { accessAcc.push(value) }
				return accessAcc
			}, [])
		}
	}, {})
}

function updateFormPermission(permission, value) {
	const oldValue = formPermissions.value[permission]
	if (oldValue[0] === true && value.length > 1) {
		value.shift()
	}
	if (value.includes(true) && value.length > 1) {
		value.splice(0, value.length - 1)
	}

	formPermissions.value = {
		...formPermissions.value,
		[permission]: value,
	}
}

function updatePermissions(updates) {
	permissions.value = Object.keys(updates).reduce((acc, permission) => {
		const access = updates[permission]
		if (!access.length) { return acc }
		return {
			...acc,
			[permission]: access.includes(true) ? true : access.reduce((accessAcc, value) => ({
				...accessAcc,
				[value]: true,
			}), {})
		}
	}, {})
}

onMounted(async () => {
	await fetchPermissions()
	if (!props.new) {
		fetchEntry(props.id)
	} else {
		buildFormPermissions()
	}
})
</script>

<style scoped>
	.role-form-section {
		width: 100%;
		position: sticky;
		bottom: 0;
		padding: 10px;
		background: white;
	}

	.role-form-content {
		width: 100%;
		max-width: 320px;
	}

	@media screen and (max-width: 959px) {
		.role-form-content {
			margin: 0 auto;
		}
	}
</style>