<template>
	<div class="view-complexes">
		<complexes-header :breadcrumb="breadcrumb" :political-area="politicalAreaId" @import="refresh = true"/>
		<complexes-table-filters v-model="filters" :political-area="politicalAreaId" />
		<complexes-table :political-area="politicalAreaId" :refresh.sync="refresh" :page.sync="page" :filters="filters">
			<template v-slot:actions="item">
				<base-button
					v-if="can(`location:complex:${item._id}.update`)"
					:to="{
						name: 'complex',
						params: { complex: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="can(`location:complex:${item._id}.delete`)"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, item.name)"
				>Delete</base-button>
			</template>
		</complexes-table>
		<complex-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useDataTableRouteState from '@/features/useDataTableRouteState'
import useSession from '@/features/useSession'

import ComplexesHeader from '@/components/fragments/complex/ComplexesHeader.vue'
import ComplexesTable from '@/components/fragments/complex/ComplexesTable.vue'
import ComplexesTableFilters from '@/components/fragments/complex/ComplexesTableFilters.vue'
import ComplexDeletionModal from '@/components/fragments/complex/ComplexDeletionModal.vue'

// Plugins
const route = useRoute()

// Data
const { page, filters } = useDataTableRouteState()
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

// Computed
const politicalAreaId = computed(() => route.params.location)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Complexes',
}])

// Methods
const { can } = useSession()
function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}
</script>
