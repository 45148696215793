<template>
	<div class="listing-rental-data-form">
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="price" label="Sales Price" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="revenue" label="Revenue" />
				</base-col>
			</base-row>
		</base-container>
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="occupancyRate" label="Occupancy Rate" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="adr" label="ADR" />
				</base-col>
			</base-row>
		</base-container>
		<div class="button-wrapper">
			<base-button
				block
				color="primary"
				:disabled="!hasChanges"
				@click="onSaveChanges(props.id)"
			>
				Save Changes
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const price = ref(null)
const revenue = ref(null)
const occupancyRate = ref(null)
const adr = ref(null)

const { changes, hasChanges, loadOriginalData } = useFormStates({
	price: computed({
		get: () => price.value && parseInt(price.value, 10),
		set: (value) => price.value = value,
	}),
	revenue: computed({
		get: () => revenue.value && parseInt(revenue.value, 10),
		set: (value) => revenue.value = value,
	}),
	occupancyRate: computed({
		get: () => occupancyRate.value && parseFloat(occupancyRate.value),
		set: (value) => occupancyRate.value = value,
	}),
	adr: computed({
		get: () => adr.value && parseInt(adr.value, 10),
		set: (value) => adr.value = value,
	}),
})

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateListing')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('price', 'revenue', 'occupancyRate', 'adr')
	
	const result = await request.exec()
	const { success, data } = result.get('updateListing')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getListing')
		.arg('id', id)
		.fields('price', 'revenue', 'occupancyRate', 'adr')

	const result = await request.exec()
	const { data } = result.get('getListing')
	loadOriginalData(data)
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.listing-rental-data-form {
		max-width: 600px;
	}

	.button-wrapper {
		max-width: 320px;
	}
</style>