<template>
	<div class="listing-basic-form">
		<base-text-field v-model="title" label="Title" />
		<base-text-field v-model="uriName" label="Website URL" />
		<base-text-field v-model="address" label="Address" />
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="lat" label="Latitude" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="long" label="Longitude" />
				</base-col>
			</base-row>
		</base-container>
		<base-autocomplete v-model="areaId" :loading="loadingAreas" :items="areas" label="Area" />
		<base-autocomplete v-model="complexId" :loading="loadingComplexes" :disabled="!areaId" :items="complexes" label="Complex" />
		<div class="button-wrapper">
			<base-button
				block
				color="primary"
				:disabled="!hasChanges"
				@click="onSaveChanges(props.id)"
			>
				Save Changes
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const title = ref(null)
const uriName = ref(null)
const address = ref(null)
const lat = ref(null)
const long = ref(null)
const areaId = ref(null)
const complexId = ref(null)

const { entries: areas, loading: loadingAreas } = useDataSource('getAreas', {
	query: { politicalAreaId: props.politicalArea },
})
const { entries: complexes, loading: loadingComplexes } = useDataSource('getComplexes', {
	query: { politicalAreaId: props.politicalArea },
})

const { changes, hasChanges, loadOriginalData } = useFormStates({
	title,
	uriName,
	areaId,
	address,
	lat: computed({
		get: () => lat.value && parseFloat(lat.value),
		set: (value) => lat.value = value,
	}),
	long: computed({
		get: () => long.value && parseFloat(long.value),
		set: (value) => long.value = value,
	}),
	complexId,
})

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateListing')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('title', 'uriName', 'address', 'lat', 'long', 'areaId', 'complexId')
	
	const result = await request.exec()
	const { success, data } = result.get('updateListing')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getListing')
		.arg('id', id)
		.fields('title', 'uriName', 'address', 'lat', 'long', 'areaId', 'complexId')

	const result = await request.exec()
	const { data } = result.get('getListing')
	loadOriginalData(data)
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.listing-basic-form {
		max-width: 600px;
	}
	.button-wrapper {
		max-width: 320px;
	}
</style>