const countries = [
	{
		id: 4,
		name: 'Afghanistan',
		code: 'af',
		flag: '/images/flags/af.png',
		alpha3: 'afg',
	},
	{
		id: 8,
		name: 'Albania',
		code: 'al',
		flag: '/images/flags/al.png',
		alpha3: 'alb',
	},
	{
		id: 12,
		name: 'Algeria',
		code: 'dz',
		flag: '/images/flags/dz.png',
		alpha3: 'dza',
	},
	{
		id: 20,
		name: 'Andorra',
		code: 'ad',
		flag: '/images/flags/ad.png',
		alpha3: 'and',
	},
	{
		id: 24,
		name: 'Angola',
		code: 'ao',
		flag: '/images/flags/ao.png',
		alpha3: 'ago',
	},
	{
		id: 28,
		name: 'Antigua and Barbuda',
		code: 'ag',
		flag: '/images/flags/ag.png',
		alpha3: 'atg',
	},
	{
		id: 32,
		name: 'Argentina',
		code: 'ar',
		flag: '/images/flags/ar.png',
		alpha3: 'arg',
	},
	{
		id: 51,
		name: 'Armenia',
		code: 'am',
		flag: '/images/flags/am.png',
		alpha3: 'arm',
	},
	{
		id: 36,
		name: 'Australia',
		code: 'au',
		flag: '/images/flags/au.png',
		alpha3: 'aus',
	},
	{
		id: 40,
		name: 'Austria',
		code: 'at',
		flag: '/images/flags/at.png',
		alpha3: 'aut',
	},
	{
		id: 31,
		name: 'Azerbaijan',
		code: 'az',
		flag: '/images/flags/az.png',
		alpha3: 'aze',
	},
	{
		id: 44,
		name: 'Bahamas',
		code: 'bs',
		flag: '/images/flags/bs.png',
		alpha3: 'bhs',
	},
	{
		id: 48,
		name: 'Bahrain',
		code: 'bh',
		flag: '/images/flags/bh.png',
		alpha3: 'bhr',
	},
	{
		id: 50,
		name: 'Bangladesh',
		code: 'bd',
		flag: '/images/flags/bd.png',
		alpha3: 'bgd',
	},
	{
		id: 52,
		name: 'Barbados',
		code: 'bb',
		flag: '/images/flags/bb.png',
		alpha3: 'brb',
	},
	{
		id: 112,
		name: 'Belarus',
		code: 'by',
		flag: '/images/flags/by.png',
		alpha3: 'blr',
	},
	{
		id: 56,
		name: 'Belgium',
		code: 'be',
		flag: '/images/flags/be.png',
		alpha3: 'bel',
	},
	{
		id: 84,
		name: 'Belize',
		code: 'bz',
		flag: '/images/flags/bz.png',
		alpha3: 'blz',
	},
	{
		id: 204,
		name: 'Benin',
		code: 'bj',
		flag: '/images/flags/bj.png',
		alpha3: 'ben',
	},
	{
		id: 64,
		name: 'Bhutan',
		code: 'bt',
		flag: '/images/flags/bt.png',
		alpha3: 'btn',
	},
	{
		id: 68,
		name: 'Bolivia (Plurinational State of)',
		code: 'bo',
		flag: '/images/flags/bo.png',
		alpha3: 'bol',
	},
	{
		id: 70,
		name: 'Bosnia and Herzegovina',
		code: 'ba',
		flag: '/images/flags/ba.png',
		alpha3: 'bih',
	},
	{
		id: 72,
		name: 'Botswana',
		code: 'bw',
		flag: '/images/flags/bw.png',
		alpha3: 'bwa',
	},
	{
		id: 76,
		name: 'Brazil',
		code: 'br',
		flag: '/images/flags/br.png',
		alpha3: 'bra',
	},
	{
		id: 96,
		name: 'Brunei Darussalam',
		code: 'bn',
		flag: '/images/flags/bn.png',
		alpha3: 'brn',
	},
	{
		id: 100,
		name: 'Bulgaria',
		code: 'bg',
		flag: '/images/flags/bg.png',
		alpha3: 'bgr',
	},
	{
		id: 854,
		name: 'Burkina Faso',
		code: 'bf',
		flag: '/images/flags/bf.png',
		alpha3: 'bfa',
	},
	{
		id: 108,
		name: 'Burundi',
		code: 'bi',
		flag: '/images/flags/bi.png',
		alpha3: 'bdi',
	},
	{
		id: 132,
		name: 'Cabo Verde',
		code: 'cv',
		flag: '/images/flags/cv.png',
		alpha3: 'cpv',
	},
	{
		id: 116,
		name: 'Cambodia',
		code: 'kh',
		flag: '/images/flags/kh.png',
		alpha3: 'khm',
	},
	{
		id: 120,
		name: 'Cameroon',
		code: 'cm',
		flag: '/images/flags/cm.png',
		alpha3: 'cmr',
	},
	{
		id: 124,
		name: 'Canada',
		code: 'ca',
		flag: '/images/flags/ca.png',
		alpha3: 'can',
	},
	{
		id: 140,
		name: 'Central African Republic',
		code: 'cf',
		flag: '/images/flags/cf.png',
		alpha3: 'caf',
	},
	{
		id: 148,
		name: 'Chad',
		code: 'td',
		flag: '/images/flags/td.png',
		alpha3: 'tcd',
	},
	{
		id: 152,
		name: 'Chile',
		code: 'cl',
		flag: '/images/flags/cl.png',
		alpha3: 'chl',
	},
	{
		id: 156,
		name: 'China',
		code: 'cn',
		flag: '/images/flags/cn.png',
		alpha3: 'chn',
	},
	{
		id: 170,
		name: 'Colombia',
		code: 'co',
		flag: '/images/flags/co.png',
		alpha3: 'col',
	},
	{
		id: 174,
		name: 'Comoros',
		code: 'km',
		flag: '/images/flags/km.png',
		alpha3: 'com',
	},
	{
		id: 178,
		name: 'Congo',
		code: 'cg',
		flag: '/images/flags/cg.png',
		alpha3: 'cog',
	},
	{
		id: 180,
		name: 'Congo, Democratic Republic of the',
		code: 'cd',
		flag: '/images/flags/cd.png',
		alpha3: 'cod',
	},
	{
		id: 188,
		name: 'Costa Rica',
		code: 'cr',
		flag: '/images/flags/cr.png',
		alpha3: 'cri',
	},
	{
		id: 384,
		name: 'Côte d\'Ivoire',
		code: 'ci',
		flag: '/images/flags/ci.png',
		alpha3: 'civ',
	},
	{
		id: 191,
		name: 'Croatia',
		code: 'hr',
		flag: '/images/flags/hr.png',
		alpha3: 'hrv',
	},
	{
		id: 192,
		name: 'Cuba',
		code: 'cu',
		flag: '/images/flags/cu.png',
		alpha3: 'cub',
	},
	{
		id: 196,
		name: 'Cyprus',
		code: 'cy',
		flag: '/images/flags/cy.png',
		alpha3: 'cyp',
	},
	{
		id: 203,
		name: 'Czechia',
		code: 'cz',
		flag: '/images/flags/cz.png',
		alpha3: 'cze',
	},
	{
		id: 208,
		name: 'Denmark',
		code: 'dk',
		flag: '/images/flags/dk.png',
		alpha3: 'dnk',
	},
	{
		id: 262,
		name: 'Djibouti',
		code: 'dj',
		flag: '/images/flags/dj.png',
		alpha3: 'dji',
	},
	{
		id: 212,
		name: 'Dominica',
		code: 'dm',
		flag: '/images/flags/dm.png',
		alpha3: 'dma',
	},
	{
		id: 214,
		name: 'Dominican Republic',
		code: 'do',
		flag: '/images/flags/do.png',
		alpha3: 'dom',
	},
	{
		id: 218,
		name: 'Ecuador',
		code: 'ec',
		flag: '/images/flags/ec.png',
		alpha3: 'ecu',
	},
	{
		id: 818,
		name: 'Egypt',
		code: 'eg',
		flag: '/images/flags/eg.png',
		alpha3: 'egy',
	},
	{
		id: 222,
		name: 'El Salvador',
		code: 'sv',
		flag: '/images/flags/sv.png',
		alpha3: 'slv',
	},
	{
		id: 226,
		name: 'Equatorial Guinea',
		code: 'gq',
		flag: '/images/flags/gq.png',
		alpha3: 'gnq',
	},
	{
		id: 232,
		name: 'Eritrea',
		code: 'er',
		flag: '/images/flags/er.png',
		alpha3: 'eri',
	},
	{
		id: 233,
		name: 'Estonia',
		code: 'ee',
		flag: '/images/flags/ee.png',
		alpha3: 'est',
	},
	{
		id: 748,
		name: 'Eswatini',
		code: 'sz',
		flag: '/images/flags/sz.png',
		alpha3: 'swz',
	},
	{
		id: 231,
		name: 'Ethiopia',
		code: 'et',
		flag: '/images/flags/et.png',
		alpha3: 'eth',
	},
	{
		id: 242,
		name: 'Fiji',
		code: 'fj',
		flag: '/images/flags/fj.png',
		alpha3: 'fji',
	},
	{
		id: 246,
		name: 'Finland',
		code: 'fi',
		flag: '/images/flags/fi.png',
		alpha3: 'fin',
	},
	{
		id: 250,
		name: 'France',
		code: 'fr',
		flag: '/images/flags/fr.png',
		alpha3: 'fra',
	},
	{
		id: 266,
		name: 'Gabon',
		code: 'ga',
		flag: '/images/flags/ga.png',
		alpha3: 'gab',
	},
	{
		id: 270,
		name: 'Gambia',
		code: 'gm',
		flag: '/images/flags/gm.png',
		alpha3: 'gmb',
	},
	{
		id: 268,
		name: 'Georgia',
		code: 'ge',
		flag: '/images/flags/ge.png',
		alpha3: 'geo',
	},
	{
		id: 276,
		name: 'Germany',
		code: 'de',
		flag: '/images/flags/de.png',
		alpha3: 'deu',
	},
	{
		id: 288,
		name: 'Ghana',
		code: 'gh',
		flag: '/images/flags/gh.png',
		alpha3: 'gha',
	},
	{
		id: 300,
		name: 'Greece',
		code: 'gr',
		flag: '/images/flags/gr.png',
		alpha3: 'grc',
	},
	{
		id: 308,
		name: 'Grenada',
		code: 'gd',
		flag: '/images/flags/gd.png',
		alpha3: 'grd',
	},
	{
		id: 320,
		name: 'Guatemala',
		code: 'gt',
		flag: '/images/flags/gt.png',
		alpha3: 'gtm',
	},
	{
		id: 324,
		name: 'Guinea',
		code: 'gn',
		flag: '/images/flags/gn.png',
		alpha3: 'gin',
	},
	{
		id: 624,
		name: 'Guinea-Bissau',
		code: 'gw',
		flag: '/images/flags/gw.png',
		alpha3: 'gnb',
	},
	{
		id: 328,
		name: 'Guyana',
		code: 'gy',
		flag: '/images/flags/gy.png',
		alpha3: 'guy',
	},
	{
		id: 332,
		name: 'Haiti',
		code: 'ht',
		flag: '/images/flags/ht.png',
		alpha3: 'hti',
	},
	{
		id: 340,
		name: 'Honduras',
		code: 'hn',
		flag: '/images/flags/hn.png',
		alpha3: 'hnd',
	},
	{
		id: 348,
		name: 'Hungary',
		code: 'hu',
		flag: '/images/flags/hu.png',
		alpha3: 'hun',
	},
	{
		id: 352,
		name: 'Iceland',
		code: 'is',
		flag: '/images/flags/is.png',
		alpha3: 'isl',
	},
	{
		id: 356,
		name: 'India',
		code: 'in',
		flag: '/images/flags/in.png',
		alpha3: 'ind',
	},
	{
		id: 360,
		name: 'Indonesia',
		code: 'id',
		flag: '/images/flags/id.png',
		alpha3: 'idn',
	},
	{
		id: 364,
		name: 'Iran (Islamic Republic of)',
		code: 'ir',
		flag: '/images/flags/ir.png',
		alpha3: 'irn',
	},
	{
		id: 368,
		name: 'Iraq',
		code: 'iq',
		flag: '/images/flags/iq.png',
		alpha3: 'irq',
	},
	{
		id: 372,
		name: 'Ireland',
		code: 'ie',
		flag: '/images/flags/ie.png',
		alpha3: 'irl',
	},
	{
		id: 376,
		name: 'Israel',
		code: 'il',
		flag: '/images/flags/il.png',
		alpha3: 'isr',
	},
	{
		id: 380,
		name: 'Italy',
		code: 'it',
		flag: '/images/flags/it.png',
		alpha3: 'ita',
	},
	{
		id: 388,
		name: 'Jamaica',
		code: 'jm',
		flag: '/images/flags/jm.png',
		alpha3: 'jam',
	},
	{
		id: 392,
		name: 'Japan',
		code: 'jp',
		flag: '/images/flags/jp.png',
		alpha3: 'jpn',
	},
	{
		id: 400,
		name: 'Jordan',
		code: 'jo',
		flag: '/images/flags/jo.png',
		alpha3: 'jor',
	},
	{
		id: 398,
		name: 'Kazakhstan',
		code: 'kz',
		flag: '/images/flags/kz.png',
		alpha3: 'kaz',
	},
	{
		id: 404,
		name: 'Kenya',
		code: 'ke',
		flag: '/images/flags/ke.png',
		alpha3: 'ken',
	},
	{
		id: 296,
		name: 'Kiribati',
		code: 'ki',
		flag: '/images/flags/ki.png',
		alpha3: 'kir',
	},
	{
		id: 408,
		name: 'Korea (Democratic People\'s Republic of)',
		code: 'kp',
		flag: '/images/flags/kp.png',
		alpha3: 'prk',
	},
	{
		id: 410,
		name: 'Korea, Republic of',
		code: 'kr',
		flag: '/images/flags/kr.png',
		alpha3: 'kor',
	},
	{
		id: 414,
		name: 'Kuwait',
		code: 'kw',
		flag: '/images/flags/kw.png',
		alpha3: 'kwt',
	},
	{
		id: 417,
		name: 'Kyrgyzstan',
		code: 'kg',
		flag: '/images/flags/kg.png',
		alpha3: 'kgz',
	},
	{
		id: 418,
		name: 'Lao People\'s Democratic Republic',
		code: 'la',
		flag: '/images/flags/la.png',
		alpha3: 'lao',
	},
	{
		id: 428,
		name: 'Latvia',
		code: 'lv',
		flag: '/images/flags/lv.png',
		alpha3: 'lva',
	},
	{
		id: 422,
		name: 'Lebanon',
		code: 'lb',
		flag: '/images/flags/lb.png',
		alpha3: 'lbn',
	},
	{
		id: 426,
		name: 'Lesotho',
		code: 'ls',
		flag: '/images/flags/ls.png',
		alpha3: 'lso',
	},
	{
		id: 430,
		name: 'Liberia',
		code: 'lr',
		flag: '/images/flags/lr.png',
		alpha3: 'lbr',
	},
	{
		id: 434,
		name: 'Libya',
		code: 'ly',
		flag: '/images/flags/ly.png',
		alpha3: 'lby',
	},
	{
		id: 438,
		name: 'Liechtenstein',
		code: 'li',
		flag: '/images/flags/li.png',
		alpha3: 'lie',
	},
	{
		id: 440,
		name: 'Lithuania',
		code: 'lt',
		flag: '/images/flags/lt.png',
		alpha3: 'ltu',
	},
	{
		id: 442,
		name: 'Luxembourg',
		code: 'lu',
		flag: '/images/flags/lu.png',
		alpha3: 'lux',
	},
	{
		id: 450,
		name: 'Madagascar',
		code: 'mg',
		flag: '/images/flags/mg.png',
		alpha3: 'mdg',
	},
	{
		id: 454,
		name: 'Malawi',
		code: 'mw',
		flag: '/images/flags/mw.png',
		alpha3: 'mwi',
	},
	{
		id: 458,
		name: 'Malaysia',
		code: 'my',
		flag: '/images/flags/my.png',
		alpha3: 'mys',
	},
	{
		id: 462,
		name: 'Maldives',
		code: 'mv',
		flag: '/images/flags/mv.png',
		alpha3: 'mdv',
	},
	{
		id: 466,
		name: 'Mali',
		code: 'ml',
		flag: '/images/flags/ml.png',
		alpha3: 'mli',
	},
	{
		id: 470,
		name: 'Malta',
		code: 'mt',
		flag: '/images/flags/mt.png',
		alpha3: 'mlt',
	},
	{
		id: 584,
		name: 'Marshall Islands',
		code: 'mh',
		flag: '/images/flags/mh.png',
		alpha3: 'mhl',
	},
	{
		id: 478,
		name: 'Mauritania',
		code: 'mr',
		flag: '/images/flags/mr.png',
		alpha3: 'mrt',
	},
	{
		id: 480,
		name: 'Mauritius',
		code: 'mu',
		flag: '/images/flags/mu.png',
		alpha3: 'mus',
	},
	{
		id: 484,
		name: 'Mexico',
		code: 'mx',
		flag: '/images/flags/mx.png',
		alpha3: 'mex',
	},
	{
		id: 583,
		name: 'Micronesia (Federated States of)',
		code: 'fm',
		flag: '/images/flags/fm.png',
		alpha3: 'fsm',
	},
	{
		id: 498,
		name: 'Moldova, Republic of',
		code: 'md',
		flag: '/images/flags/md.png',
		alpha3: 'mda',
	},
	{
		id: 492,
		name: 'Monaco',
		code: 'mc',
		flag: '/images/flags/mc.png',
		alpha3: 'mco',
	},
	{
		id: 496,
		name: 'Mongolia',
		code: 'mn',
		flag: '/images/flags/mn.png',
		alpha3: 'mng',
	},
	{
		id: 499,
		name: 'Montenegro',
		code: 'me',
		flag: '/images/flags/me.png',
		alpha3: 'mne',
	},
	{
		id: 504,
		name: 'Morocco',
		code: 'ma',
		flag: '/images/flags/ma.png',
		alpha3: 'mar',
	},
	{
		id: 508,
		name: 'Mozambique',
		code: 'mz',
		flag: '/images/flags/mz.png',
		alpha3: 'moz',
	},
	{
		id: 104,
		name: 'Myanmar',
		code: 'mm',
		flag: '/images/flags/mm.png',
		alpha3: 'mmr',
	},
	{
		id: 516,
		name: 'Namibia',
		code: 'na',
		flag: '/images/flags/na.png',
		alpha3: 'nam',
	},
	{
		id: 520,
		name: 'Nauru',
		code: 'nr',
		flag: '/images/flags/nr.png',
		alpha3: 'nru',
	},
	{
		id: 524,
		name: 'Nepal',
		code: 'np',
		flag: '/images/flags/np.png',
		alpha3: 'npl',
	},
	{
		id: 528,
		name: 'Netherlands',
		code: 'nl',
		flag: '/images/flags/nl.png',
		alpha3: 'nld',
	},
	{
		id: 554,
		name: 'New Zealand',
		code: 'nz',
		flag: '/images/flags/nz.png',
		alpha3: 'nzl',
	},
	{
		id: 558,
		name: 'Nicaragua',
		code: 'ni',
		flag: '/images/flags/ni.png',
		alpha3: 'nic',
	},
	{
		id: 562,
		name: 'Niger',
		code: 'ne',
		flag: '/images/flags/ne.png',
		alpha3: 'ner',
	},
	{
		id: 566,
		name: 'Nigeria',
		code: 'ng',
		flag: '/images/flags/ng.png',
		alpha3: 'nga',
	},
	{
		id: 807,
		name: 'North Macedonia',
		code: 'mk',
		flag: '/images/flags/mk.png',
		alpha3: 'mkd',
	},
	{
		id: 578,
		name: 'Norway',
		code: 'no',
		flag: '/images/flags/no.png',
		alpha3: 'nor',
	},
	{
		id: 512,
		name: 'Oman',
		code: 'om',
		flag: '/images/flags/om.png',
		alpha3: 'omn',
	},
	{
		id: 586,
		name: 'Pakistan',
		code: 'pk',
		flag: '/images/flags/pk.png',
		alpha3: 'pak',
	},
	{
		id: 585,
		name: 'Palau',
		code: 'pw',
		flag: '/images/flags/pw.png',
		alpha3: 'plw',
	},
	{
		id: 591,
		name: 'Panama',
		code: 'pa',
		flag: '/images/flags/pa.png',
		alpha3: 'pan',
	},
	{
		id: 598,
		name: 'Papua New Guinea',
		code: 'pg',
		flag: '/images/flags/pg.png',
		alpha3: 'png',
	},
	{
		id: 600,
		name: 'Paraguay',
		code: 'py',
		flag: '/images/flags/py.png',
		alpha3: 'pry',
	},
	{
		id: 604,
		name: 'Peru',
		code: 'pe',
		flag: '/images/flags/pe.png',
		alpha3: 'per',
	},
	{
		id: 608,
		name: 'Philippines',
		code: 'ph',
		flag: '/images/flags/ph.png',
		alpha3: 'phl',
	},
	{
		id: 616,
		name: 'Poland',
		code: 'pl',
		flag: '/images/flags/pl.png',
		alpha3: 'pol',
	},
	{
		id: 620,
		name: 'Portugal',
		code: 'pt',
		flag: '/images/flags/pt.png',
		alpha3: 'prt',
	},
	{
		id: 634,
		name: 'Qatar',
		code: 'qa',
		flag: '/images/flags/qa.png',
		alpha3: 'qat',
	},
	{
		id: 642,
		name: 'Romania',
		code: 'ro',
		flag: '/images/flags/ro.png',
		alpha3: 'rou',
	},
	{
		id: 643,
		name: 'Russian Federation',
		code: 'ru',
		flag: '/images/flags/ru.png',
		alpha3: 'rus',
	},
	{
		id: 646,
		name: 'Rwanda',
		code: 'rw',
		flag: '/images/flags/rw.png',
		alpha3: 'rwa',
	},
	{
		id: 659,
		name: 'Saint Kitts and Nevis',
		code: 'kn',
		flag: '/images/flags/kn.png',
		alpha3: 'kna',
	},
	{
		id: 662,
		name: 'Saint Lucia',
		code: 'lc',
		flag: '/images/flags/lc.png',
		alpha3: 'lca',
	},
	{
		id: 670,
		name: 'Saint Vincent and the Grenadines',
		code: 'vc',
		flag: '/images/flags/vc.png',
		alpha3: 'vct',
	},
	{
		id: 882,
		name: 'Samoa',
		code: 'ws',
		flag: '/images/flags/ws.png',
		alpha3: 'wsm',
	},
	{
		id: 674,
		name: 'San Marino',
		code: 'sm',
		flag: '/images/flags/sm.png',
		alpha3: 'smr',
	},
	{
		id: 678,
		name: 'Sao Tome and Principe',
		code: 'st',
		flag: '/images/flags/st.png',
		alpha3: 'stp',
	},
	{
		id: 682,
		name: 'Saudi Arabia',
		code: 'sa',
		flag: '/images/flags/sa.png',
		alpha3: 'sau',
	},
	{
		id: 686,
		name: 'Senegal',
		code: 'sn',
		flag: '/images/flags/sn.png',
		alpha3: 'sen',
	},
	{
		id: 688,
		name: 'Serbia',
		code: 'rs',
		flag: '/images/flags/rs.png',
		alpha3: 'srb',
	},
	{
		id: 690,
		name: 'Seychelles',
		code: 'sc',
		flag: '/images/flags/sc.png',
		alpha3: 'syc',
	},
	{
		id: 694,
		name: 'Sierra Leone',
		code: 'sl',
		flag: '/images/flags/sl.png',
		alpha3: 'sle',
	},
	{
		id: 702,
		name: 'Singapore',
		code: 'sg',
		flag: '/images/flags/sg.png',
		alpha3: 'sgp',
	},
	{
		id: 703,
		name: 'Slovakia',
		code: 'sk',
		flag: '/images/flags/sk.png',
		alpha3: 'svk',
	},
	{
		id: 705,
		name: 'Slovenia',
		code: 'si',
		flag: '/images/flags/si.png',
		alpha3: 'svn',
	},
	{
		id: 90,
		name: 'Solomon Islands',
		code: 'sb',
		flag: '/images/flags/sb.png',
		alpha3: 'slb',
	},
	{
		id: 706,
		name: 'Somalia',
		code: 'so',
		flag: '/images/flags/so.png',
		alpha3: 'som',
	},
	{
		id: 710,
		name: 'South Africa',
		code: 'za',
		flag: '/images/flags/za.png',
		alpha3: 'zaf',
	},
	{
		id: 728,
		name: 'South Sudan',
		code: 'ss',
		flag: '/images/flags/ss.png',
		alpha3: 'ssd',
	},
	{
		id: 724,
		name: 'Spain',
		code: 'es',
		flag: '/images/flags/es.png',
		alpha3: 'esp',
	},
	{
		id: 144,
		name: 'Sri Lanka',
		code: 'lk',
		flag: '/images/flags/lk.png',
		alpha3: 'lka',
	},
	{
		id: 729,
		name: 'Sudan',
		code: 'sd',
		flag: '/images/flags/sd.png',
		alpha3: 'sdn',
	},
	{
		id: 740,
		name: 'Suriname',
		code: 'sr',
		flag: '/images/flags/sr.png',
		alpha3: 'sur',
	},
	{
		id: 752,
		name: 'Sweden',
		code: 'se',
		flag: '/images/flags/se.png',
		alpha3: 'swe',
	},
	{
		id: 756,
		name: 'Switzerland',
		code: 'ch',
		flag: '/images/flags/ch.png',
		alpha3: 'che',
	},
	{
		id: 760,
		name: 'Syrian Arab Republic',
		code: 'sy',
		flag: '/images/flags/sy.png',
		alpha3: 'syr',
	},
	{
		id: 762,
		name: 'Tajikistan',
		code: 'tj',
		flag: '/images/flags/tj.png',
		alpha3: 'tjk',
	},
	{
		id: 834,
		name: 'Tanzania, United Republic of',
		code: 'tz',
		flag: '/images/flags/tz.png',
		alpha3: 'tza',
	},
	{
		id: 764,
		name: 'Thailand',
		code: 'th',
		flag: '/images/flags/th.png',
		alpha3: 'tha',
	},
	{
		id: 626,
		name: 'Timor-Leste',
		code: 'tl',
		flag: '/images/flags/tl.png',
		alpha3: 'tls',
	},
	{
		id: 768,
		name: 'Togo',
		code: 'tg',
		flag: '/images/flags/tg.png',
		alpha3: 'tgo',
	},
	{
		id: 776,
		name: 'Tonga',
		code: 'to',
		flag: '/images/flags/to.png',
		alpha3: 'ton',
	},
	{
		id: 780,
		name: 'Trinidad and Tobago',
		code: 'tt',
		flag: '/images/flags/tt.png',
		alpha3: 'tto',
	},
	{
		id: 788,
		name: 'Tunisia',
		code: 'tn',
		flag: '/images/flags/tn.png',
		alpha3: 'tun',
	},
	{
		id: 792,
		name: 'Turkey',
		code: 'tr',
		flag: '/images/flags/tr.png',
		alpha3: 'tur',
	},
	{
		id: 795,
		name: 'Turkmenistan',
		code: 'tm',
		flag: '/images/flags/tm.png',
		alpha3: 'tkm',
	},
	{
		id: 798,
		name: 'Tuvalu',
		code: 'tv',
		flag: '/images/flags/tv.png',
		alpha3: 'tuv',
	},
	{
		id: 800,
		name: 'Uganda',
		code: 'ug',
		flag: '/images/flags/ug.png',
		alpha3: 'uga',
	},
	{
		id: 804,
		name: 'Ukraine',
		code: 'ua',
		flag: '/images/flags/ua.png',
		alpha3: 'ukr',
	},
	{
		id: 784,
		name: 'United Arab Emirates',
		code: 'ae',
		flag: '/images/flags/ae.png',
		alpha3: 'are',
	},
	{
		id: 826,
		name: 'United Kingdom',
		code: 'gb',
		flag: '/images/flags/gb.png',
		alpha3: 'gbr',
	},
	{
		id: 840,
		name: 'United States',
		code: 'us',
		flag: '/images/flags/us.png',
		alpha3: 'usa',
	},
	{
		id: 858,
		name: 'Uruguay',
		code: 'uy',
		flag: '/images/flags/uy.png',
		alpha3: 'ury',
	},
	{
		id: 860,
		name: 'Uzbekistan',
		code: 'uz',
		flag: '/images/flags/uz.png',
		alpha3: 'uzb',
	},
	{
		id: 548,
		name: 'Vanuatu',
		code: 'vu',
		flag: '/images/flags/vu.png',
		alpha3: 'vut',
	},
	{
		id: 862,
		name: 'Venezuela (Bolivarian Republic of)',
		code: 've',
		flag: '/images/flags/ve.png',
		alpha3: 'ven',
	},
	{
		id: 704,
		name: 'Viet Nam',
		code: 'vn',
		flag: '/images/flags/vn.png',
		alpha3: 'vnm',
	},
	{
		id: 887,
		name: 'Yemen',
		code: 'ye',
		flag: '/images/flags/ye.png',
		alpha3: 'yem',
	},
	{
		id: 894,
		name: 'Zambia',
		code: 'zm',
		flag: '/images/flags/zm.png',
		alpha3: 'zmb',
	},
	{
		id: 716,
		name: 'Zimbabwe',
		code: 'zw',
		flag: '/images/flags/zw.png',
		alpha3: 'zwe',
	},
]

function findCountry(field, value) {
	const found = countries.find(country => country[field] === value)
	if (!found) { return null }
	return { ...found }
}

export function getCountryFromCode(code) {
	return findCountry('code', code)
}

export function getCountryFromId(id) {
	return findCountry('id', id)
}

export function getCountryFromAlpha3(alpha3) {
	return findCountry('alpha3', alpha3)
}

export function getCountries() {
	return countries.map(country => ({ ...country}))
}

export function getCountriesItems(text, value) {
	return countries.map(country => ({
		text: country[text],
		value: country[value],
	}))
}
