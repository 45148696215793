import { useApi } from '@/plugins/api'

const DEFAULT_MAX_ENTRIES = 10

export const STRATEGIES = {
	CREATE: 'create',
	UPDATE: 'update',
	UPSERT: 'upsert',
}

export const FORMATS = [
	'json', 'csv',
]

/**
 * Import feature
 * @param {object} settings - Settings
 * @param {string} settings.fn - GraphQL function name
 * @param {array<string>} settings.fields - Fields to use in the importation process
 * @param {string} [settings.strategy=create] - Import strategy [create,update,upsert]
 * @param {function} [settings.map] - GraphQL mapper
 * @param {function} [settings.transform] - GraphQL batch transform function
 * @param {string} [settings.upsertFn] - GraphQL update function name if the strategy is set to 'upsert'
 * @param {number} [max=10] - Maximum number of entries to run in parallel
 * @param {string} [api=default] - API name
 * @param {string} [format=default] - Data format
 */
export default function({
	fn,
	fields,
	strategy = 'create',
	map = (input) => input,
	transform = (arr) => arr,
	max = DEFAULT_MAX_ENTRIES,
	api: apiName = 'default',
	upsertFn,
	format = 'json',
}) {
	if (!Object.values(STRATEGIES).includes(strategy)) {
		throw new Error(`useImport - Strategy not recognized: ${strategy}`)
	}
	if (strategy === 'upsert' && !upsertFn) {
		throw new Error('Import - upsertFn setting needs to be specified with the upsert strategy')
	}

	// Data
	const api = useApi()

	// Methods
	async function createEntry(entryFn, input) {
		const request = api.graphql(apiName)
		request.mutation(entryFn)
			.arg('input', map(input))
			.fields('_id')

		const result = await request.exec()
		const { success } = result.get(entryFn)
		return success
	}

	// Methods
	async function updateEntry(entryFn, {_id: id, ...input}) {
		const request = api.graphql(apiName)
		request.mutation(entryFn)
			.arg('id', id)
			.arg('input', map(input))
			.fields('_id')

		const result = await request.exec()
		const { success } = result.get(entryFn)
		return success
	}

	function getAllowedFields() {
		if (fields.includes('_id')) { return fields }
		return ['_id', ...fields]
	}

	function filterEntryFields(entry, allowedFields) {
		return Object.keys(entry).filter((key) => allowedFields.includes(key)).reduce((acc, key) => ({ ...acc, [key]: entry[key] }), {})
	}

	async function importEntry(entry, allowedFields) {
		const curatedEntry = filterEntryFields(entry, allowedFields)
		
		switch (strategy) {
		case 'create':
			return curatedEntry._id ? false : createEntry(fn, curatedEntry)
		case 'update':
			return !curatedEntry._id ? false : updateEntry(fn, curatedEntry)
		case 'upsert':
			return curatedEntry._id ? updateEntry(upsertFn, curatedEntry) : createEntry(fn, curatedEntry)
		}
	}

	function csvRowToObject(csvFields, delimiter, row) {
		let tmpValue = false
		const values = row.split(delimiter).reduce((acc, value) => {
			if (!tmpValue && value[0] !== '"' ) {
				acc.push(value)
			} else if (tmpValue) {
				tmpValue += `${delimiter}${value.replace(/""/g, '###__QUOTE__###')}`
				if (tmpValue[tmpValue.length - 1] === '"') {
					acc.push(tmpValue.replace(/"$/, '').replace(/###__QUOTE__###/g, '"'))
					tmpValue = false
				}
			} else if (value[value.length - 1] === '"') {
				acc.push(value.substring(1, value.length -1 ).replace(/""/g, '"'))
			} else {
				tmpValue = value.substring(1).replace(/""/g, '###__QUOTE__###')
			}

			return acc
		}, [])

		return csvFields.reduce((acc, field, i) => {
			if (!values[i]) { return acc }
			acc[field] = values[i]
			return acc
		}, {})
	}

	function formatFromCsv(importData) {
		const lines = importData.split(/\r?\n/)
		const [header, ...rows] = lines
		const delimiter = header.split(';') > 1 ? ';' : ','
		const csvFields = header.split(delimiter)
		return rows.map((row) => csvRowToObject(csvFields, delimiter, row))
	}

	function formatData(importData) {
		switch (format) {
		case 'csv':
			return formatFromCsv(importData)
		case 'json':
			return [...importData]	
		}
	}

	async function processImport(importData) {
		const entries = formatData(importData)
		const allowedFields = getAllowedFields()

		let success = 0
		do {
			const batchEntries = entries.splice(0, max)
			const filteredEntries = await transform(batchEntries)
			const successes = await Promise.all(filteredEntries.map(async (entry) => importEntry(entry, allowedFields)))
			success = successes.reduce((acc, value) => acc + (value ? 1 : 0), success)
		} while (entries.length)

		return {
			success,
			total: importData.length,
		}
	}

	return processImport
}