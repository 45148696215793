<template>
	<v-card-actions v-bind="$attrs" v-on="$listeners">
		<v-spacer v-if="props.right" />
		<slot />
	</v-card-actions>
</template>

<script setup>
const props = defineProps({
	right: {
		type: Boolean,
		default: false,
	}
})
</script>

<script>
export default {
	inheritAttrs: false
}
</script>