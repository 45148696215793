<template>
	<base-modal
		color="primary"
		v-model="dialog"
		title="Add Note"
		:actions="[{
			text: 'Cancel',
			value: 'cancel',
		}, {
			color: 'secondary',
			text: 'Add',
			value: 'add',
			loading,
		}]"
		@click="onAddNote"
	>
		<base-text-field
			v-model="note"
			color="primary"
			label="Note"
		/>
	</base-modal>
</template>

<script setup>
import { ref, computed } from 'vue'

import { useApi } from '@/plugins/api'

// Props & Emit
const props = defineProps({
	value: {
		type: Boolean,
	},
	listing: {
		type: String,
		required: true,
	},
	representative: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['add', 'input'])

// Data
const api = useApi()
const note = ref()
const loading = ref(false)

// Computed
const dialog = computed({
	get: () => props.value,
	set: (value) => emit('input', value),
})

// Methods
async function addNote() {
	loading.value = true
	const request = api.graphql()
	request.mutation('createSentValidatedListingNote')
		.arg('id', props.listing)
		.arg('input', {
			note: note.value,
			representativeId: props.representative,
		})
		.fields('success')
	const result = await request.exec()
	const { success } = result.get('createSentValidatedListingNote')
	if (success) {
		emit('add')
	}
	loading.value = false
}

async function onAddNote(value) {
	if (value === 'add') {
		await addNote()
	}

	note.value = ''
	dialog.value = false
}
</script>