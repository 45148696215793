<template>
	<span class="base-input base-radio-group">
		<v-radio-group v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-radio-group>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	dense: true,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>