<template>
	<base-data-table
		:headers="headers"
		:items="items"
		:items-per-page="25"
		:loading="loading"
		:options.sync="options"
		:server-items-length="total"
		fixed-footer
		sort-by="name"
	>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</base-data-table>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import useDataTableGraphQLRequest from '@/features/useDataTableGraphQLRequest'

const props = defineProps({
	politicalArea: {
		type: String,
		required: true,
	},
	refresh: {
		type: Boolean,
		default: false,
	}
})
const emit = defineEmits(['update:refresh'])

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Website URL',
	value: 'uriName',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Data
const filters = ref({
	politicalAreaId: props.politicalArea,
})

const { items, loading, options, total, refreshData } = useDataTableGraphQLRequest({
	getFn: 'getCities',
	countFn: 'countCities',
	fields: ['_id', 'name', 'uriName'],
	filters,
})

// Computed
const needsRefresh = computed(() => props.refresh)

// Watchers
watch(needsRefresh, (value, oldValue) => {
	if (value !== oldValue && value === true) {
		refreshData(true)
		emit('update:refresh', false)
	}
})
</script>
