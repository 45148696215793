<template>
	<div class="view-me">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[0]">
			<base-expansion-panel title="Profile Information">
				<profile-form  />
			</base-expansion-panel>
			<base-expansion-panel title="Password">
				<update-password-form  />
			</base-expansion-panel>
			<base-expansion-panel title="Two-factor Authentication">
				<two-factor-form  />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>

import ProfileForm from '@/components/fragments/me/ProfileForm'
import UpdatePasswordForm from '@/components/fragments/me/UpdatePasswordForm'
import TwoFactorForm from '@/components/fragments/me/TwoFactorForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'My Account',
}])
</script>