export default class GraphQLFunction {
	#name			// Name
	#alias			// Alias
	#args = {}		// Arguments
	#fields = []	// Fields
	#children = []	// Graphql children functions

	/**
	 * @constructor
	 * @param {string} name - Function name
	 */
	constructor(name) {
		this.#name = name
	}

	/**
	 * Set alias
	 * @param {string} alias - Alias
	 * @returns {GraphQLFunction} - This
	 */
	alias(alias) {
		this.#alias = alias
		return this
	}

	/**
	 * Add argument
	 * @param {string} name - Name 
	 * @param {any} value - Value
	 * @returns {GraphQLFunction} - This
	 */
	arg(name, value) {
		this.#args[name] = value
		return this
	}

	/**
	 * Add field
	 * @param {...string} fields - Fields 
	 * @returns {GraphQLFunction} - This
	 */
	fields(...fields) {
		this.#fields.push(...fields)
		return this
	}

	/**
	 * Add child
	 * @param {string} name - Child function name
	 * @returns {GraphQLFunction} - Child
	 */
	child(name) {
		const child = new GraphQLFunction(name)
		this.#children.push(child)

		return child
	}

	/**
	 * Stringify object
	 * @param {object} obj - Object to be stringified as query
	 * @returns {string} - Stringified object
	 */
	#objectToString(obj) {
		const str = JSON.stringify(obj)
		return str.replace(/"([a-z0-9_]+)":/gi, '$1:')
	}

	/**
	 * Stringify arguments
	 * @returns {string} - Stringified arguments
	 */
	#argsToString() {
		return Object.keys(this.#args).length
			? `(${this.#objectToString(this.#args).replace(/^\{(.*)\}$/, '$1')})`
			: ''
	}

	/**
	 * Stringify children functions
	 * @returns {string} - Stringified children functions
	 */
	#childrenToString() {
		if (!this.#children.length) { return '' }

		const childrens = this.#children.map(child => child.toString())
		return ` ${childrens.join(' ')}`
	}

	/**
	 * Stringify fields requests
	 * @returns {string} - Stringified fields
	 */
	#fieldsToString() {
		if (!this.#fields.length && !this.#children.length) { return '' }
		return ` {${this.#fields.join(' ')}${this.#childrenToString()}}`
	}

	/**
	 * Stringify function
	 * @returns {string} - Stringified function
	 */
	toString() {
		const name = this.#alias ? `${this.#alias}: ${this.#name}` : this.#name
		return `${name}${this.#argsToString()}${this.#fieldsToString()}`
	}
}