<template>
	<div class="market-areas">
		<div class="text-right">
			<base-button
				color="primary"
				small
				:loading="additionDialog.show"
				@click="showAdditionDialog"
			>
				<base-icon small>mdi-plus-circle-outline</base-icon>
				<span class="ml-2">Add Area(s)</span>
			</base-button>
		</div>
		<base-data-table
			:headers="headers"
			:items="items"
			:options.sync="options"
			:items-per-page="25"
			:loading="loading"
			:server-items-length="total"
			sort-by="name"
		>
			<template v-slot:[`item.actions`]="{ item }">
				<base-button
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, item.name)"
				>Delete</base-button>
			</template>
		</base-data-table>
		<base-modal
			title="New Area(s)"
			:value="additionDialog.show"
			:actions="newAreaActions"
			@click="onAddArea"
		>
			<base-autocomplete v-model="additionDialog.politicalArea" :items="politicalAreas" label="Political Area" />
			<base-autocomplete v-model="additionDialog.city" :items="cities" :loading="loadingCities" label="City" :disabled="!additionDialog.politicalArea" />
			<base-autocomplete
				v-model="additionDialog.value"
				:items="filteredCityAreas"
				:loading="loadingCityAreas"
				label="Area(s)"
				:disabled="!additionDialog.city"
				multiple
			/>
		</base-modal>
		<base-modal
			title="Delete Area"
			:value="deletionDialog.show"
			:actions="deletionAreaActions"
			@click="onDeleteArea"
		>
			<span>Are you sure you want to remove area <b>{{deletionDialog.text}}</b>?</span>
		</base-modal>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from 'vue'

import { useApi } from '@/plugins/api'

import useDataSource from '@/features/useDataSource'
import useDataTableGraphQLRequest from '@/features/useDataTableGraphQLRequest'

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})

// Variables & Data
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

const loadingAddition = ref(false)
const loadingDeletion = ref(false)

const newAreaActions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	text: 'Add',
	value: 'add',
	color: 'primary',
	loading: loadingAddition,
	disabled: computed(() => additionDialog.text === ''),
})]

const deletionAreaActions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	loading: loadingDeletion,
	text: 'Delete',
	value: 'delete',
	color: 'error',
})]

const api = useApi()
const areas = ref([])
const deletionDialog = reactive({
	show: ref(false),
	id: ref(),
	text: ref(''),
})

const additionDialog = reactive({
	show: ref(false),
	value: ref([]),
	politicalArea: ref(),
	city: ref(),
})

const { entries: politicalAreas } = useDataSource('getPoliticalAreas')
const { entries: cities, loading: loadingCities, fetchEntries: fetchCities } = useDataSource('getCities', {
	manualOnly: true,
})
const { entries: cityAreas, loading: loadingCityAreas, fetchEntries: fetchCityAreas } = useDataSource('getAreas', {
	manualOnly: true,
})

const { items, loading, options, total, refreshData } = useDataTableGraphQLRequest({
	parentQuery: (request) => request.query('getMarket').arg('id', props.id),
	parentResult: (result, fn) => {
		const { data } = result.get('getMarket')
		return { data: data[fn] }
	},
	getFn: 'getAreas',
	countFn: 'countAreas',
	fields: ['_id', 'name'],
})

// Computed
const filteredCityAreas = computed(() => {
	return cityAreas.value.filter(({ value }) => !areas.value.includes(value))
})

// Watchers
watch(() => additionDialog.politicalArea, (value) => {
	if (!value) {
		return
	}
	fetchCities({
		politicalAreaId: value,
	})
})
watch(() => additionDialog.city, (value) => {
	if (!value) {
		return
	}
	fetchCityAreas({
		politicalAreaId: additionDialog.politicalArea,
		cityId: value,
	})
})

// Methods
async function fetchAreas() {
	loading.value = true
	const request = api.graphql()
	request.query('getMarket').arg('id', props.id).fields('areaIds')
	const result = await request.exec()
	const { data } = result.get('getMarket')
	if (data) {
		areas.value = data.areaIds
	}
	loading.value = false
}

async function updateAreas() {
	const request = api.graphql()

	request.mutation('updateMarket').arg('id', props.id).arg('input', {
		areaIds: areas.value,
	}).fields('areaIds')

	await request.exec()
}

function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}

function showAdditionDialog() {
	additionDialog.politicalArea = null
	additionDialog.city = null
	additionDialog.value = []
	additionDialog.show = true
}

async function onAddArea(value) {
	if (value == 'add') {
		loadingAddition.value = true
		areas.value = [...areas.value, ...additionDialog.value]
		await updateAreas()
	}
	additionDialog.show = false
	loadingAddition.value = false
	refreshData(true)
}

async function onDeleteArea(value) {
	if (value == 'delete') {
		loadingDeletion.value = true
		const i = areas.value.indexOf(deletionDialog.id)
		areas.value.splice(i, 1)
		await updateAreas()
	}
	deletionDialog.show = false
	loadingDeletion.value = false
	refreshData(true)
}

onMounted(() => {
	fetchAreas()
})
</script>
