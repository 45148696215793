<template>
	<div class="city-seasonality-form">
		<base-select v-model="bestMonth" :items="months" label="Best Month" />
		<base-text-field v-model="revPar" label="RevPAR" />
		<base-text-field v-model="weekendRevPar" label="Weekend RevPAR" />
		<div class="mt-4">
			<base-button
				block
				color="primary"
				:disabled="!hasChanges"
				@click="onSaveChanges(props.id)"
			>
				{{ 'Save Changes' }}
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const bestMonth = ref()
const revPar = ref()
const weekendRevPar = ref()

const { form, hasChanges, loadOriginalData } = useFormStates({
	bestMonth,
	revPar: computed({
		get: () => revPar.value && parseInt(revPar.value, 10),
		set: (value) => revPar.value = value,
	}),
	weekendRevPar: computed({
		get: () => weekendRevPar.value && parseInt(weekendRevPar.value, 10),
		set: (value) => weekendRevPar.value = value,
	}),
})

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateCity')
		.arg('id', id)
		.arg('input', {
			seasonality: form
		})
		.child('seasonality').fields('bestMonth', 'revPar', 'weekendRevPar')

	const result = await request.exec()
	const { success, data } = result.get('updateCity')
	if (success) {
		loadOriginalData(data.seasonality)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getCity')
		.arg('id', id)
		.child('seasonality').fields('bestMonth', 'revPar', 'weekendRevPar')

	const result = await request.exec()
	const { data } = result.get('getCity')

	loadOriginalData(data.seasonality)
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.city-seasonality-form {
		max-width: 320px;
	}
</style>