import ApiService from './service'

export const PluginSymbol = Symbol()

export default {
	install(app, { router, store, storeKey }) {
		const service = new ApiService()

		// Verify authentication status
		router.beforeEach((to, from, next) => {
			// Routes requiring authenticated user
			if (to.matched.some(record => {
				const { requiresAuth } = record.meta
				if (!requiresAuth) { return false }
				return storeKey && store && !store.getters[storeKey]
			})) {
				return next({
					path: '/login',
					query: { redirect: to.fullPath },
				})
			}

			return next()
		})

		// Vue 3
		if (app.provide) {
			app.config.globalProperties.$api = service
			app.provide(PluginSymbol, service)
		} else {
		// Vue 2
			app.$api = service
			app.prototype.$api = service
		}

		// Identify on startup
		// helper.identify()

		// Handle Ravello Service Axios errors
		/*
		RavelloService.responseInterceptor.use(response => response, (error) => {
			// Do something with response error
			if (error.response.status === 401 && helper.isAuth()) {
				helper.logout(false)
				router.replace({ name: 'login' })
			}
			return Promise.reject(error.response)
		}) */
	},
}
