<template>
	<span class="base-button">
		<v-btn v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-btn>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	tile: true,
	dense: true,
	elevation: 0,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>