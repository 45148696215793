<template>
	<div class="location-form">
		<base-autocomplete v-model="country" label="Country*" :items="countries" />
		<base-autocomplete v-model="code" :label="`${regionsName}*`" :items="regions" :disabled="!country"/>
		<base-autocomplete v-model="timezone" :items="timezones" label="Timezone*" />
		<base-autocomplete v-model="currency" :items="currencies" label="Currency*" />
		<base-text-field v-model="uriName" label="Website URL" />
		<base-image-upload
			v-model="featuredImage"
			:src="featuredImageUrl"
			width="350"
			height="233"
			label="Featured Image (350x233)"
			resize
		/>
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			:loading="loading"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'

import { useApi } from '@/plugins/api'
import { getCountriesItems } from '@/utils/country'
import { getRegionItems, getRegionTypeName, getRegionNameFromCode } from '@/utils/region'
import { getTimezones } from '@/utils/timezone'
import { getCurrencies } from '@/utils/currency'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['create', 'save'])

// Constants
const ALLOWED_COUNTRIES = ['us', 'ca']
const REQUIRED_FIELDS = ['country', 'name', 'code', 'timezone']

// Modules
const api = useApi()

// Data
const country = ref(null)
const name = ref(null)
const uriName = ref()
const code = ref(null)
const timezone = ref(null)
const currency = ref(null)
const featuredImage = ref(null)
const featuredImageUrl = ref(null)

const loading = ref(false)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	country,
	name,
	uriName,
	code,
	currency,
	timezone,
	featuredImage,
}, REQUIRED_FIELDS)

// Computed
const countries = computed(() => getCountriesItems('name', 'code').filter(({ value }) => ALLOWED_COUNTRIES.includes(value)))
const regions = computed(() => country.value ? getRegionItems(country.value, 'name', 'code') : [])
const regionsName = computed(() => country.value ? getRegionTypeName(country.value) : 'Political Area')
const timezones = computed(() => getTimezones())
const currencies = computed(() => getCurrencies())

// Watchers
watch(code, (value) => {
	name.value = getRegionNameFromCode(country.value, value)
	uriName.value = name.value.toLowerCase().replace(/[ -]+/g, '-')
})

// Methods
async function onCreate() {
	loading.value = true
	const request = api.graphql()

	request.mutation('createPoliticalArea')
		.arg('input', form)
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createPoliticalArea')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updatePoliticalArea')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('country', 'name', 'uriName', 'featuredImageUrl', 'code', 'timezone', 'currency')
	
	const result = await request.exec()
	const { success, data } = result.get('updatePoliticalArea')
	if (success) {
		const { featuredImageUrl: updatedFeaturedImageUrl, ...restData } = data
		featuredImageUrl.value = updatedFeaturedImageUrl
		featuredImage.value = null

		loadOriginalData({
			...restData,
			featuredImage: null,
		})
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getPoliticalArea')
		.arg('id', id)
		.fields('country', 'name', 'uriName', 'featuredImageUrl', 'code', 'timezone', 'currency')

	const result = await request.exec()
	const { data } = result.get('getPoliticalArea')

	const { featuredImageUrl: originalFeaturedImageUrl, ...restData } = data

	featuredImageUrl.value = originalFeaturedImageUrl
	loadOriginalData({
		...restData,
		featuredImage: null,
	})
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.location-form {
		max-width: 320px;
	}
</style>