import { computed } from 'vue'

export default function(form, filters) {
	const hasChanges = computed(() => {
		return Object.keys(form).some((field) => {
			const currentValue = form[field] === null ? undefined : form[field]
			const filterValue = (filters.value || {})[field]
			return currentValue !== filterValue
		})
	})
		
	// Methods
	function onApply() {
		filters.value = {
			...(Object.keys(form).reduce((acc, field) => {
				if (typeof form[field] !== 'undefined' && form[field] !== null) {
					acc[field] = form[field]
				}
				return acc
			}, {})),
		}
	}

	return {
		hasChanges,
		onApply,
	}
}