import { ref, watch } from 'vue'

import { useApi } from '@/plugins/api'

import useDebounce from "./useDebounce.js"

/**
 * Provide GraphQL requests logic and refs to use in data table
 * @param {string} fn - GraphQL function name
 * @param {object} settings - Settings
 * @param {number} [settings.queryCb] - Callback to update query
 * @param {number} [settings.debounce=500] - Debounce time in ms
 * @param {number} [settings.treshold=3] - Text treshold before triggering search
 * @param {string} [settings.value=_id] - Value field
 * @param {string} [settings.text=name] - Text field
 * @param {string} [settings.api=default] - API to use
 * @param {object} [settings.filters] - Reactive Filters
 */
export default function(fn, {
	queryCb = (q) => q,
	debounce = 500,
	treshold = 3,
	value: valueField = '_id',
	text: textField = 'name',
	api: apiName = 'default',
	query: settingsQuery,
	init: { fn: initFn, value: initValue } = {},
}) {
	// Variables
	const api = useApi()

	// Data
	const search = ref()
	const items = ref([])
	const loading = ref(false)
	const query = settingsQuery || ref({})

	// Watchers
	watch(search, (value) => {
		onSearch(value)
	})

	const onSearch = useDebounce(async (value) => {
		if (value && value.length >= treshold) {
			loading.value = true
			const request = api.graphql(apiName)
			const requestQuery = request.query(fn).arg('query', {
				search: value,
				...query.value,
			}).fields(`value: ${valueField}`, `text: ${textField}`)
			await queryCb(requestQuery)
			const result = await request.exec()
			const { data = [] } = result.get(fn)
			items.value = data
			loading.value = false
		}
	}, debounce)

	async function onInit() {
		loading.value = true
		const request = api.graphql(apiName)
		request.query(initFn)
			.arg('id', initValue)
			.fields(`value: ${valueField}`, `text: ${textField}`)

		const result = await request.exec()
		const { success, data } = result.get(initFn)
		if (success) {
			items.value = [data]
		}
		loading.value = false
	}

	if (initFn && initValue) { onInit() }

	return {
		search,
		items,
		loading,
	}
}