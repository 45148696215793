// Use in the same place where the navigation is handled.
// It can only be initalized once using init() and you can use the menu ref to get the proper menu

import { ref } from 'vue'

import { useRouter, useRoute } from '@/plugins/router'
import { useApi } from '@/plugins/api'
import { navigation } from '@/routes'

// Variables
let isInit = false

export default () => {
	// Data
	const currentContext = ref('main')
	const menu = ref(navigation.main.menu)
	const navButton = ref()

	const route = useRoute()
	const router = useRouter()
	const api = useApi()

	async function loadNavButtonText(currentRoute, apiName, fn, routeParamForId, field) {
		const id = currentRoute.params[routeParamForId]
		const request = api.graphql(apiName)
		request.query(fn).arg('id', id).fields(field)

		const result = await request.exec()
		const { data } = result.get(fn)
		if (data && data[field]) {
			navButton.value = {
				...navButton.value,
				loading: false,
				text: data[field]
			}
		}
	}

	function setNavButton(currentRoute, context) {
		if (context.navButton) {
			const { to, text, color, api: apiName, fn, id, field } = context.navButton

			navButton.value = {
				to: to,
				color: color,
				loading: !text,
				text,
			}
			if (api && fn && id) {
				loadNavButtonText(currentRoute, apiName, fn, id, field)
			}
		} else {
			navButton.value = undefined
		}
	}

	function updateContext(currentRoute) {
		const { menu: routeContext = 'main' } = currentRoute.meta
		const context = navigation[routeContext] || navigation['main']
		if (currentContext.value !== routeContext) {
			currentContext.value = routeContext
			menu.value = context.menu

			setNavButton(currentRoute, context)
		}
	}

	function init() {
		if (isInit) { return}
		isInit = true
		
		updateContext(route)
		router.beforeEach((to, from, next) => {
			updateContext(to)
			next()
		})
	}

	return {
		menu,
		init,
		navButton,
	}
}
