<template>
	<div class="view-locations">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
						<base-col v-if="can('location:political-area:*.create')" class="pa-0" align="right">
							<base-button
								class="ma-0"
								color="primary"
								to="/locations/new"
								height="46px"
							>
								<base-icon>mdi-plus-circle-outline</base-icon>
								<span class="ml-2">Add Location</span>
							</base-button>
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<locations-table>
			<template v-slot:actions="item">
				<base-button
					:to="{
						name: 'cities',
						params: { location: item._id }
					}"
					color="primary"
					text
					small
				>Manage</base-button>
			</template>
		</locations-table>
	</div>
</template>

<script setup>
import useSession from '@/features/useSession'
import LocationsTable from '@/components/fragments/location/LocationsTable.vue'

const { can } = useSession()
const breadcrumb = [{
	text: 'Locations',
}]

</script>
