<template>
	<div class="brand-form">
		<base-text-field v-model="name" label="Name*" />
		<base-text-field type="email" v-model="email" label="Main contact email address*" />
		<base-text-field type="phone" v-model="phone" label="Main contact phone number*" />
		<base-image-upload
			v-model="logo"
			:src="logoUrl"
			width="320"
			height="80"
			label="Logo* (320x80)"
			resize
		/>
		<base-button
			block
			color="primary"
			:loading="loading"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

const REQUIRED_FIELDS = ['name', 'email', 'phone', 'logo']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	politicalArea: {
		type: String,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const name = ref(null)
const email = ref(null)
const phone = ref(null)
const logo = ref(null)
const logoUrl = ref(null)
const loading = ref(false)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	email,
	phone,
	logo,
}, REQUIRED_FIELDS)

// Methods
async function onCreate() {
	loading.value = true
	const request = api.graphql()

	request.mutation('createBrand')
		.arg('input', {
			...form,
			politicalAreaId: props.politicalArea,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createBrand')
	if (success) {
		emit('create', data._id)
	}
	loading.value = false
}

async function onSaveChanges(id) {
	loading.value = true
	const request = api.graphql()

	request.mutation('updateBrand')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('name', 'email', 'phone', 'logoUrl')
	
	const result = await request.exec()
	const { success, data } = result.get('updateBrand')
	if (success) {
		const { logoUrl: updatedLogoUrl, ...rest } = data
		logoUrl.value = updatedLogoUrl
		logo.value = null
		loadOriginalData({
			...rest,
			logo: null,
		})
		emit('save', data)
	}
	loading.value = false
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getBrand')
		.arg('id', id)
		.fields('name', 'email', 'phone', 'logoUrl')

	const result = await request.exec()
	const { data } = result.get('getBrand')

	const { logoUrl: originalLogoUrl, ...rest } = data
	logoUrl.value = originalLogoUrl
	loadOriginalData({
		...rest,
		logo: null,
	})
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.brand-form {
		max-width: 320px;
	}
</style>