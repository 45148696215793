<template>
	<div class="view-representatives">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
						<base-col v-if="can('realestate:representative:*.create')" class="pa-0" align="right">
							<base-button class="ma-0" color="primary" height="46px" :to="{ name: 'newRepresentative' }">
								<base-icon>mdi-plus-circle-outline</base-icon>
								<span class="ml-2">Add Representative</span>
							</base-button>
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<representatives-table :market="marketId" :refresh.sync="refresh">
			<template v-slot:actions="item">
				<base-button
					target="_blank"
					:to="{
						name: 'listingsValidation',
						params: { representative: item._id }
					}"
					color="primary"
					text
					small
				>Access</base-button>
				<base-button
					v-if="can(`realestate:representative:${item._id}.update`)"
					:to="{
						name: 'representative',
						params: { representative: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="can(`realestate:representative:${item._id}.delete`)"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, `${item.firstName} ${item.lastName}`)"
				>Delete</base-button>
			</template>
		</representatives-table>
		<representative-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useSession from '@/features/useSession'

import RepresentativesTable from '@/components/fragments/representative/RepresentativesTable.vue'
import RepresentativeDeletionModal from '@/components/fragments/representative/RepresentativeDeletionModal.vue'

// Plugins
const route = useRoute()

// Data
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

// Computed
const marketId = computed(() => route.params.market)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Markets',
	to: { name: 'markets' },
}, {
	api: 'marketmath',
	fn: 'getMarket',
	id: marketId.value,
	field: 'name',
}, {
	text: 'Representatives',
}])

// Methods
const { can } = useSession()
function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}
</script>
