<template>
	<div class="user-form">
		<base-text-field v-model="username" label="Username" disabled />
		<base-text-field v-model="firstName" label="First Name" />
		<base-text-field v-model="lastName" label="Last Name" />
		<base-text-field v-model="email" type="email" label="Email" />
		<base-button
			block
			color="primary"
			:disabled="!hasChanges"
			@click="onSaveChanges()"
		>
			Save Changes
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'
import { useStore } from '@/plugins/store'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const emit = defineEmits(['save'])

// Modules
const api = useApi()
const store = useStore()

// Data
const username = ref()
const firstName = ref()
const lastName = ref()
const email = ref()

const { changes, hasChanges, loadOriginalData } = useFormStates({
	firstName,
	lastName,
	email,
}, [])

async function onSaveChanges() {
	const request = api.graphql()

	request.mutation('updateMe')
		.arg('input', changes.value)
		.fields('firstName', 'lastName', 'email')
	
	const result = await request.exec()
	const { success, data } = result.get('updateMe')
	if (success) {
		loadOriginalData(data)
		store.dispatch('session/updateProfileName', { firstName, lastName })
		emit('save', data)
	}
}

async function fetchEntry() {
	const request = api.graphql()
	request.query('getMe')
		.fields('username', 'firstName', 'lastName', 'email')

	const result = await request.exec()
	const { data } = result.get('getMe')

	const { username: loadedUsername, ...rest } = data
	username.value = loadedUsername
	loadOriginalData(rest)
}

onMounted(async () => {
	fetchEntry()
})
</script>

<style scoped>
	.user-form {
		max-width: 320px;
	}
</style>