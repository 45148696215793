<template>
	<div class="view-newuser">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<access-key-form new @create="onCreated" />
		<base-modal v-model="creationDialog" max-width="400px" title="Access Key" :actions="actions" @click="onDialogClick">
			<div class="mb-2">Please not the key and the secret as they will only be shown once</div>
			<div><b>Key:</b> {{key}}</div>
			<div><b>Secret:</b> {{secret}}</div>
		</base-modal>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import AccessKeyForm from '@/components/fragments/accessKey/AccessKeyForm'

import { useRouter } from '@/plugins/router'

// Modules
const router = useRouter()

// Data
const breadcrumb = [{
	text: 'Admin',
}, {
	text: 'Access Keys',
	to: {
		name: 'accessKeys',
	},
}, {
	text: 'New access key',
}]
const actions = [{
	text: 'Close',
}]

const creationDialog = ref(false)
const key = ref()
const secret = ref()

// Methods
async function onCreated({ key: newKey, secret: newSecret }) {
	key.value = newKey
	secret.value = newSecret
	creationDialog.value = true
}

async function onDialogClick() {
	router.push({
		name: 'accessKeys',
	})
}
</script>