<template>
	<span class="base-input base-address-autocomplete">
		<vuetify-google-autocomplete
			ref="autocomplete"
			v-bind="{ ...$attrs, ...defaultAttrs }"
			v-on="$listeners"
			@placechanged="onPlaceChanged"
		/>
	</span>
</template>

<script setup>
import { ref, computed } from 'vue'

const autocomplete = ref()
const props = defineProps({
	onlyName: {
		type: Boolean,
		default: false,
	}
})
const defaultAttrs = computed(() => ({
	outlined: true,
	dense: true,
	id: `${Date.now()}`,
}))

function onPlaceChanged(_, place) {
	if (props.onlyName) {
		autocomplete.value.autocompleteText = place.name
	}
}
</script>

<script>
export default {
	inheritAttrs: false
}
</script>