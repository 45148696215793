<template>
	<div class="listing-details-form">
		<base-container>
			<base-row>
				<base-col>
					<base-container>
						<base-row>
							<base-col class="pl-0">
								<base-text-field v-model="mls" label="MLS" readonly />
							</base-col>
							<base-col class="pr-0">
								<base-text-field v-model="externalId" label="External ID" readonly />
							</base-col>
						</base-row>
					</base-container>
					<base-text-field v-model="featuredImage" label="Featured Image URL" />
					<base-text-field v-model="propertyType" label="Property Type" />
					<base-container>
						<base-row>
							<base-col class="pl-0">
								<base-text-field v-model="bedrooms" label="Bedrooms" />
							</base-col>
							<base-col class="pr-0">
								<base-text-field v-model="bathrooms" label="Bathrooms" />
							</base-col>
						</base-row>
					</base-container>
					<base-container>
						<base-row>
							<base-col class="pl-0">
								<base-text-field v-model="listedSrc" label="Listed Since (from source)" readonly />
							</base-col>
							<base-col class="pr-0">
								<base-text-field v-model="listedSince" label="Listed Since" />
							</base-col>
						</base-row>
					</base-container>
					<div class="button-wrapper">
						<base-button
							block
							color="primary"
							:disabled="!hasChanges"
							@click="onSaveChanges(props.id)"
						>
							Save Changes
						</base-button>
					</div>
				</base-col>
				<base-col>
					<base-textarea v-model="description" label="Description" />
				</base-col>
			</base-row>
		</base-container>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const mls = ref(null)
const externalId = ref(null)
const featuredImage = ref(null)
const propertyType = ref(null)
const bathrooms = ref(null)
const bedrooms = ref(null)
const listedSrc = ref(null)
const listedSince = ref(null)
const description = ref(null)

const { changes, hasChanges, loadOriginalData } = useFormStates({
	mls,
	externalId,
	featuredImage,
	propertyType,
	bathrooms: computed({
		get: () => bathrooms.value && parseFloat(bathrooms.value),
		set: (value) => bathrooms.value = value,
	}),
	bedrooms: computed({
		get: () => bedrooms.value && parseInt(bedrooms.value, 10),
		set: (value) => bedrooms.value = value,
	}),
	listedSrc,
	listedSince,
	description,
})

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateListing')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('mls', 'externalId', 'featuredImage', 'propertyType',
			'bathrooms', 'bedrooms', 'listedSrc', 'listedSince', 'description')
	
	const result = await request.exec()
	const { success, data } = result.get('updateListing')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getListing')
		.arg('id', id)
		.fields('mls', 'externalId', 'featuredImage', 'propertyType',
			'bathrooms', 'bedrooms', 'listedSrc', 'listedSince', 'description')

	const result = await request.exec()
	const { data } = result.get('getListing')
	loadOriginalData(data)
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.listing-details-form {
		max-width: 1200px;
	}
	.button-wrapper {
		max-width: 320px;
	}
</style>