import { getCurrentInstance } from 'vue'
import * as Router from 'vue-router'

import routes from '@/routes'

export default function createRouter(app) {
	app.use(Router.default)
	return new Router.default({
		mode: 'history',
		routes,
	})
}

const keys = Object.keys(Router)

export function useRouter() {
	return keys.includes('useRouter') ? { ...Router }.useRouter() : getCurrentInstance().proxy.$router
}

export function useRoute() {
	return keys.includes('useRouter') ? { ...Router }.useRoute() : getCurrentInstance().proxy.$route
}
