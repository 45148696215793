<template>
	<div class="base-expansion-panels">
		<v-expansion-panels v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	tile: true,
	flat: true,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>

<style scoped>
	.base-expansion-panels :deep(.v-expansion-panel) {
		background-color: #fafafa;
	}

	.base-expansion-panels :deep(.v-expansion-panel-header) {
		border-bottom: 1px solid #eaeaea;
	}
</style>