import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

export default new VuexPersistence({
	key: 'session',
	restoreState: (key) => {
		const state = Cookies.get(key)
		if (state) {
			return JSON.parse(state)
		}
	},
	saveState: (key, state) =>
		Cookies.set(key, JSON.stringify(state), {
			expires: 30
		}),
	reducer: currentState => ({
		session: {
			refreshToken: currentState.session.refreshToken,
		},
	}),
}).plugin
