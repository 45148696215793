<template>
	<div class="user-form">
		<base-switch v-model="enabled" type="password" label="Is Enabled" :disabled="loading" />
		<div v-if="enabled && url">
			<div>Scan the QRCode using an Authenticator such as Google Authenticator or Microsoft Authenticator</div>
			<img :src="url" />
			<base-text-field v-model="verificationToken" label="Token" />
			<base-button
				block
				color="primary"
				:disabled="!isFilled"
				@click="onConfirm()"
			>
				Confirm
			</base-button>
		</div>
		<base-modal
			title="Deactivate 2FA"
			color="warning"
			:actions="[{
				text: 'Confirm',
				value: 'confirm',
				color: 'warning',
				loading: loading,
			}, {
				text: 'Cancel',
				value: 'cancel',
				disabled: loading,
			}]"
			:value="showDeactivateModal"
			@click="onDeactivate"
		>
			Are you sure that you want to deactivate the two-factor authentication?
		</base-modal>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import QRCode from 'qrcode'

import { useApi } from '@/plugins/api'

// Props & Emits
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const loading = ref(true)
const enabled = ref(false)
const current = ref(false)
const verificationToken = ref()
const showDeactivateModal = ref(false)
const url = ref()

// Computed
const isFilled = computed(() => verificationToken.value && verificationToken.value.length === 6)

// Watchers
watch(enabled, (value) => {
	if (current.value == value) {
		return
	}
	if (value) {
		onActivate()
	} else {
		showDeactivateModal.value = true
	}
})

// Methods
async function onConfirm() {
	const request = api.graphql()

	request.mutation('confirmTwoFactor')
		.arg('input', {
			verificationToken: verificationToken.value,
		})
		.fields('success')
	
	const result = await request.exec()
	const { success } = result.get('confirmTwoFactor')
	if (success) {
		url.value = undefined
		verificationToken.value = undefined
		current.value = true
		emit('save', true)
	}
}

async function onActivate() {
	loading.value = true

	const request = api.graphql()
	request.mutation('activateTwoFactor')
		.fields('url')
	
	const result = await request.exec()
	const { success, data } = result.get('activateTwoFactor')
	if (success) {
		url.value = await QRCode.toDataURL(data.url)
	}
	loading.value = false
}

async function onDeactivate(value) {
	if (value === 'cancel') {
		enabled.value = true
	} else {
		loading.value = true
		const request = api.graphql()
		request.mutation('deactivateTwoFactor')
			.fields('success')
		
		const result = await request.exec()
		const { success } = result.get('deactivateTwoFactor')
		if (success) {
			current.value = false
		}
		loading.value = false
	}

	showDeactivateModal.value = false
}

async function fetchEntry() {
	const request = api.graphql()
	request.query('getMe')
		.fields('twoFactor')

	const result = await request.exec()
	const { data } = result.get('getMe')

	current.value = data.twoFactor
	enabled.value = data.twoFactor
	loading.value = false
}

onMounted(async () => {
	fetchEntry()
})
</script>

<style scoped>
	.user-form {
		max-width: 320px;
	}
</style>