<template>
	<span class="base-icon">
		<v-icon v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-icon>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>