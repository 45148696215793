const state = {
	savedQueries: {},
}

const getters = {
	getSavedQueries(currentState) {
		return currentState.savedQueries
	},
}

const actions = {
	setQuery({ commit }, { name, query }) {
		commit('setQuery', { name, query })
	},
}

const mutations = {
	setQuery(currentState, { name, query }) {
		currentState.savedQueries = {
			...currentState.savedQueries,
			[name]: query
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
