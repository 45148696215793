const RADIUS_EARTH_KM = 6371
const RADIUS_EARTH_MILES = 3961
const EARTH_CIRCUMFERENCE_KM = 40075.04

export default function() {
	function geoJSONCoordinatesToPaths(coordinates) {
		return coordinates.map(linearRing =>
			linearRing.slice(0, linearRing.length - 1)
				.map(([lng, lat]) => ({lat, lng}))
		)
	}

	function googlePathsToPolygonPaths(mvcPaths) {
		let polygonPaths = []
		for (let i=0; i < mvcPaths.getLength(); i++) {
			let path = []
			for (let j=0; j<mvcPaths.getAt(i).getLength(); j++) {
				let point = mvcPaths.getAt(i).getAt(j)
				path.push({lat: point.lat(), lng: point.lng()})
			}
			polygonPaths.push(path)
		}

		return polygonPaths
	}

	function closeLoop(path) {
		return path.concat(path.slice(0, 1))
	}

	function polygonPathsToGeoJSONCoordinates(polygonPaths) {
		return polygonPaths.map(path => closeLoop(path.map(({ lat, lng }) => [lng, lat])))
	}

	function toRadians(value) {
		if (typeof value === 'object' && typeof value.lng !== 'undefined' && typeof value.lat !== 'undefined') {
			return {
				lng: toRadians(value.lng),
				lat: toRadians(value.lat),
			}
		}

		return value * Math.PI / 180
	}

	/**
	 * Convert kilometers to latitude coordinate
	 * @param {number} km - Kilometers to convert
	 * @returns {number} - Latitude coordinate
	 */
	function kmToLat(km) {
		return (360 * km) / EARTH_CIRCUMFERENCE_KM
	}

	/**
	 * Convert kilometers to longitude coordinate
	 * @param {number} km - Kilometers to convert
	 * @param {number} lat - Latitude coordinate
	 * @returns {number} - Longitude coordinate
	 */
	function kmToLng(km, latitude) {
		return (360 * km) / Math.cos(latitude)
	}


	function calculateDistance(coordA, coordB, {
		useMiles =false,
	} = {}) {
		const radA = toRadians(coordA)
		const radB = toRadians(coordB)

		// Haversine formula
		const dLng = radB.lng - radA.lng
		const dLat = radB.lat - radA.lat
		const a = Math.pow(Math.sin(dLat / 2), 2)
					+ Math.cos(radA.lat) * Math.cos(radB.lat)
					* Math.pow(Math.sin(dLng / 2),2)
		let c = 2 * Math.asin(Math.sqrt(a))
		let r = useMiles ? RADIUS_EARTH_MILES : RADIUS_EARTH_KM

		// calculate the result
		return(c * r)
	}

	return {
		geoJSONCoordinatesToPaths,
		googlePathsToPolygonPaths,
		polygonPathsToGeoJSONCoordinates,
		calculateDistance,
		kmToLat,
		kmToLng,
	}
}