<template>
	<div class="custom-rpa-report-data">
		<base-data-table
			:headers="headers"
			:items="items"
			:items-per-page="25"
			:loading="loading"
		>
		</base-data-table>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})

// Variables & Data
const headers = [{
	text: 'Service Name',
	value: 'service',
	width: '40%'
}, {
	text: 'Revenue',
	value: 'revenue',
	width: '20%'
}, {
	text: 'Occupancy rate',
	value: 'occupancyRate',
	width: '20%'
}, {
	text: 'ADR',
	value: 'adr',
	width: '20%'
}]

const serviceNames = {
	airdna: 'AirDNA',
	aggregated: 'Combined & Adjusted',
}

const api = useApi()
const reports = ref([])
const loading = ref(true)

// Computed
const items = computed(() => reports.value.map((report) => ({
	service: serviceNames[report.service],
	revenue: `$${report.revenue.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}`,
	occupancyRate: `${report.occupancyRate.toFixed(2)}%`,
	adr: `$${report.adr.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}`,
})))

// Methods
async function fetchReports() {
	loading.value = true
	const request = api.graphql()
	request.query('getCustomRentalProjectionAnalysis').arg('id', props.id)
		.child('reports')
		.fields('service', 'revenue', 'occupancyRate', 'adr')
	const result = await request.exec()
	const { data } = result.get('getCustomRentalProjectionAnalysis')
	if (data) {
		reports.value = data.reports
	}
	loading.value = false
}

onMounted(() => {
	fetchReports()
})
</script>

<style scoped>
	.custom-rpa-report-data {
		max-width: 600px;
	}
</style>