import { render, staticRenderFns } from "./BaseImageUpload.vue?vue&type=template&id=1f136312&scoped=true&"
import script from "./BaseImageUpload.vue?vue&type=script&setup=true&lang=js&"
export * from "./BaseImageUpload.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./BaseImageUpload.vue?vue&type=style&index=0&id=1f136312&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f136312",
  null
  
)

export default component.exports