<template>
	<span class="base-link">
		<router-link v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</router-link>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>