<template>
	<div class="brokerage-aliases">
		<div class="text-right">
			<base-button
				color="primary"
				small
				:loading="additionDialog.show"
				@click="showAdditionDialog"
			>
				<base-icon small>mdi-plus-circle-outline</base-icon>
				<span class="ml-2">Add Alias</span>
			</base-button>
		</div>
		<base-data-table
			:headers="headers"
			:items="items"
			:options.sync="options"
			:items-per-page="25"
			:loading="loading"
		>
			<template v-slot:[`item.actions`]="{ item, index }">
				<base-button
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.index === index"
					@click="showDeletionDialog(index, item.name)"
				>Delete</base-button>
			</template>
		</base-data-table>
		<base-modal
			title="New Alias"
			:value="additionDialog.show"
			:actions="newAliasActions"
			@click="onAddAlias"
		>
			<base-text-field v-model="additionDialog.text" label="Alias" />
		</base-modal>
		<base-modal
			title="Delete Alias"
			:value="deletionDialog.show"
			:actions="deletionAliasActions"
			@click="onDeleteAlias"
		>
			<span>Are you sure you want to remove alias <b>{{deletionDialog.text}}</b>?</span>
		</base-modal>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, reactive } from 'vue'

import { useApi } from '@/plugins/api'

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	refresh: {
		type: Boolean,
		default: false,
	}
})
const emit = defineEmits(['update:refresh'])

// Variables & Data
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

const loadingAddition = ref(false)
const loadingDeletion = ref(false)

const newAliasActions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	text: 'Create',
	value: 'create',
	color: 'primary',
	loading: loadingAddition,
	disabled: computed(() => additionDialog.text === ''),
})]

const deletionAliasActions = [{
	text: 'Cancel',
	value: 'cancel',
}, reactive({
	loading: loadingDeletion,
	text: 'Delete',
	value: 'delete',
	color: 'error',
})]

const api = useApi()
const aliases = ref([])
const loading = ref(true)
const options = ref({})
const deletionDialog = reactive({
	show: ref(false),
	index: ref(null),
	text: ref(''),
})
const additionDialog = reactive({
	show: ref(false),
	text: ref(''),
})

// Computed
const needsRefresh = computed(() => props.refresh)
const items = computed(() => aliases.value.map((alias) => ({ name: alias })))

// Watchers
watch(needsRefresh, (value, oldValue) => {
	if (value !== oldValue && value === true) {
		fetchAliases()
		emit('update:refresh', false)
	}
})

// Methods
async function fetchAliases() {
	loading.value = true
	const request = api.graphql()
	request.query('getBrokerage').arg('id', props.id).fields('aliases')
	const result = await request.exec()
	const { data } = result.get('getBrokerage')
	if (data) {
		aliases.value = data.aliases
	}
	loading.value = false
}

async function updateAliases() {
	const request = api.graphql()

	request.mutation('updateBrokerage').arg('id', props.id).arg('input', {
		aliases: aliases.value,
	}).fields('aliases')

	await request.exec()
}

function showDeletionDialog(index, text) {
	deletionDialog.index = index
	deletionDialog.text = text
	deletionDialog.show = true
}

function showAdditionDialog() {
	additionDialog.text = ''
	additionDialog.show = true
}



async function onAddAlias(value) {
	if (value == 'create') {
		loadingAddition.value = true
		aliases.value = [...aliases.value, additionDialog.text]
		await updateAliases()
	}
	additionDialog.show = false
	loadingAddition.value = false
}

async function onDeleteAlias(value) {
	if (value == 'delete') {
		loadingDeletion.value = true
		const i = deletionDialog.index + (options.value.itemsPerPage * (options.value.page - 1))
		aliases.value.splice(i, 1)
		await updateAliases()
	}
	deletionDialog.show = false
	loadingDeletion.value = false
}

onMounted(() => {
	fetchAliases()
})
</script>
