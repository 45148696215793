<template>
	<div class="user-form">
		<base-text-field v-model="username" label="Username*" />
		<base-text-field v-if="props.new" v-model="password" label="Password*" />
		<base-autocomplete v-model="roleId" label="Role*" :loading="loadingRoles" :items="roles" />
		<base-text-field v-model="firstName" label="First Name*" />
		<base-text-field v-model="lastName" label="Last Name*" />
		<base-text-field v-model="email" type="email" label="Email" />
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['create', 'save'])

// Constants
const REQUIRED_FIELDS = ['username', 'firstName', 'lastName', 'roleId']

// Modules
const api = useApi()

// Data
const username = ref()
const firstName = ref()
const lastName = ref()
const email = ref()
const password = ref()
const roleId = ref(null)
const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	username,
	firstName,
	lastName,
	email,
	roleId,
	...(props.new ? { password } : {}),
}, props.new ? [...REQUIRED_FIELDS, 'password'] : REQUIRED_FIELDS)

// Computed
const { entries: roles, loading: loadingRoles } = useDataSource('getRoles')

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createUser')
		.arg('input', form)
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createUser')
	if (success) {
		emit('create', data._id)
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateUser')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('username', 'firstName', 'lastName', 'email', 'roleId')
	
	const result = await request.exec()
	const { success, data } = result.get('updateUser')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getUser')
		.arg('id', id)
		.fields('username', 'firstName', 'lastName', 'email', 'roleId')

	const result = await request.exec()
	const { data } = result.get('getUser')

	loadOriginalData(data)
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.user-form {
		max-width: 320px;
	}
</style>