<template>
	<div class="listings-sent-list">
		<base-container>
			<base-row>
				<base-col cols="12" v-for="listing in listings" :key="`listing-${listing._id}`" align="center">
					<listing-sent-card
						:listing="listing"
						:representative="props.representative"
					/>
				</base-col>
			</base-row>
		</base-container>
		<div class="mb-10" />
		<div class="bottom-pagination">
			<base-pagination
				v-model="page"
				:length="totalPages"
				:disabled="loading"
			/>
		</div>
	</div>
</template>

<script setup>
import { toRef, ref, computed, watch, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import ListingSentCard from './ListingSentCard.vue'

// Constants
const LIMIT_PER_PAGE = 25
const MAX_ENTRIES = 100

// Props & Emit
const props = defineProps({
	representative: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => ({}),
	},
})

const emit = defineEmits(['failed'])

// Data
const api = useApi()
const listings = ref([])
const count = ref(0)
const page = ref(1)
const loading = ref(false)
const filters = toRef(props, 'filters')

// Computed
const totalPages = computed(() => Math.ceil(count.value / LIMIT_PER_PAGE))

// Watchers
watch(page, (value) => {
	fetchListings(value)
	window.scrollTo(0, 0)
})

watch(filters, () => {
	page.value = 1
	fetchListings(page.value, true)
})

// Methods
async function fetchListings(page, includesCount = false) {
	loading.value = true

	const request = api.graphql()
	const query = request.query('getSentValidatedListings').arg('query', {
		...filters.value,
		representativeId: props.representative,
		sort: ['-_id'],
		page,
		limit: LIMIT_PER_PAGE,
	}).fields([
		'_id',
		'area',
		'title',
		'uriName',
		'featuredImage',
		'bedrooms',
		'bathrooms',
		'propertyType',
		'address',
		'url',
		'occupancyRate',
		'revenue',
		'adr',
	])
	query.child('realtor').fields('name', 'email', 'phone')
	query.child('metrics').fields('delivered', 'open', 'click')
	
	if (includesCount && !filters.value.search) {
		request.query('countSentValidatedListings').arg('query', {
			...filters.value,
			representativeId: props.representative,
		}).fields('count')
	}
	const result = await request.exec()
	const { data, success } = result.get('getSentValidatedListings')
	if (!success) {
		emit('failed')
	} else {
		listings.value = data
		if (includesCount && !filters.value.search) {
			const { data } = result.get('countSentValidatedListings')
			const limitedCount = data.count || 0
			if (limitedCount > MAX_ENTRIES) {
				count.value = MAX_ENTRIES
			} else {
				count.value = limitedCount
			}
		} else if (includesCount && filters.value.search) {
			count.value = listings.value.length
		}
	}

	loading.value = false
}

onMounted(() => {
	fetchListings(1, true)
})
</script>

<style scoped>
	.bottom-pagination {
		position: fixed;
		display: block;
		background: #e6e6e6;
		border-top: #c6c6c6;
		bottom: 0;
		z-index: 1;
		left: 0;
		right: 0;
		width: 100%;
	}
</style>