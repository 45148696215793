const MAX_WAIT_CALLSTACK = 10

export const useMap = function(mapRef) {
	async function generatePolygonPaths(degree = 1, callstack = 0) {
		if (!mapRef.value || !mapRef.value.$mapObject) {
			await new Promise((resolve) => {
				setTimeout(() => resolve(), 50)	
			})
			if (callstack === MAX_WAIT_CALLSTACK) { return [] }
			return generatePolygonPaths(degree, callstack + 1)
		}
	
		// obtain the bounds, so we can guess how big the polygon should be
		const bounds = mapRef.value.$mapObject.getBounds()
		const northEast = bounds.getNorthEast()
		const southWest = bounds.getSouthWest()
		const center = bounds.getCenter()
		const f = Math.pow(0.66, degree)
	
		// Draw a triangle. Use f to control the size of the triangle.
		// i.e., every time we add a path, we reduce the size of the triangle
		return [
			{ lng: center.lng(), lat: (1-f) * center.lat() + (f) * northEast.lat() },
			{ lng: (1-f) * center.lng() + (f) * southWest.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
			{ lng: (1-f) * center.lng() + (f) * northEast.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
		]
	}

	return {
		generatePolygonPaths
	}
}

export const usePolygon = function(polygonRef) {
	function handleClickForDelete($event) {
		if ($event.vertex) {
			polygonRef.value.$polygonObject.getPaths()
				.getAt($event.path)
				.removeAt($event.vertex)
		}
	}

	return {
		handleClickForDelete,
	}
}