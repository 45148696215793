<template>
	<base-expansion-panels>
		<base-expansion-panel class="white">
			<template v-slot:header>
				<base-row no-gutters>
					<base-col cols="6" md="8" align-self="center">Filters</base-col>
					<base-col cols="6" md="4" align="right">
						<base-text-field
							v-model="searchInput"
							no-borders
							prepend-inner-icon="mdi-magnify"
							hide-details
							placeholder="Search"
							clearable
							width="400"
							@click.stop
							@keyup.space.prevent
						/>
					</base-col>
				</base-row>
			</template>
			<base-card>
				<base-card-text>
					<base-row>
						<base-col cols="12" sm="4" md="3" lg="2">
							<base-autocomplete
								v-model="politicalAreaId"
								:items="politicalAreas"
								:loading="loadingPoliticalAreas"
								:search-input.sync="searchPoliticalAreas"
								hide-no-data
								cache-items
								label="Location"
								clearable
							/>
						</base-col>
						<base-col cols="12" sm="4" md="3" lg="2">
							<base-autocomplete
								v-model="cityId"
								:items="cities"
								:loading="loadingCities"
								:search-input.sync="searchCities"
								hide-no-data
								label="City"
								:disabled="!politicalAreaId"
								clearable
							/>
						</base-col>
					</base-row>
				</base-card-text>
				<base-card-actions>
					<base-button color="primary" :disabled="!hasChanges" @click="onApply">Apply</base-button>
				</base-card-actions>
			</base-card>
		</base-expansion-panel>
	</base-expansion-panels>
</template>

<script setup>
import { watch, computed, ref, reactive } from 'vue'

import useAutocompleteSearch from '@/features/useAutocompleteSearch'
import useFilters from '@/features/useFilters'
import useDebounce from '@/features/useDebounce'

// Props & Emits
const props = defineProps({
	value: {
		type: Object,
		default: () => {},
	}
})
const emit = defineEmits(['input'])

const searchInput = ref(props.value.search)
const search = ref(props.value.search)
const politicalAreaId = ref(props.value.politicalAreaId)
const cityId = ref(props.value.cityId)
const form = reactive({
	politicalAreaId,
	cityId,
	search,
})

const { items: politicalAreas, loading: loadingPoliticalAreas, search: searchPoliticalAreas } = useAutocompleteSearch('getPoliticalAreas', {
	init: {
		fn: 'getPoliticalArea',
		value: politicalAreaId.value,
	}
})
const { items: cities, loading: loadingCities, search: searchCities } = useAutocompleteSearch('getCities', {
	query: computed(() => ({
		politicalAreaId: politicalAreaId.value,
	})),
	init: {
		fn: 'getCity',
		value: cityId.value,
	}
})

// Computed
const filters = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

// Watchers
watch(searchInput, (value) => {
	onSearch(value)
})

watch(politicalAreaId, (value) => {
	if (!value) {
		cityId.value = undefined
		cities.value = []
		return
	}
})

const { hasChanges, onApply } = useFilters(form, filters)

// Methods
const onSearch = useDebounce((value) => {
	search.value = value
	onApply()
}, 500)
</script>