<template>
	<base-data-table
		:headers="headers"
		:items="items"
		:items-per-page="25"
		:loading="loading"
		:options.sync="options"
		:server-items-length="total"
		fixed-footer
		sort-by="name"
	>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</base-data-table>
</template>

<script setup>
import useDataTableGraphQLRequest from '@/features/useDataTableGraphQLRequest'

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Timezone',
	value: 'timezone',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Data
const { items, loading, options, total } = useDataTableGraphQLRequest({
	getFn: 'getMarkets',
	countFn: 'countMarkets',
	fields: ['_id', 'name', 'timezone'],
})

</script>
