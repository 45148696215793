<template>
	<div class="view-listing">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[0]">
			<base-expansion-panel title="Title &amp; Location">
				<listing-basic-form :id="listingId" :political-area="politicalAreaId" />
			</base-expansion-panel>
			<base-expansion-panel title="Details">
				<listing-details-form :id="listingId" />
			</base-expansion-panel>
			<base-expansion-panel title="Realtor">
				<listing-realtor-form :id="listingId" :political-area="politicalAreaId" />
			</base-expansion-panel>
			<base-expansion-panel title="Rental Data">
				<h3>Reporting Data</h3>
				<listing-reporting-data class="mb-4" :id="listingId" />
				<h3>Validation Data</h3>
				<listing-validation-data class="mb-4" :id="listingId" />
				<listing-rental-data-form :id="listingId" />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import ListingBasicForm from '@/components/fragments/listing/ListingBasicForm'
import ListingDetailsForm from '@/components/fragments/listing/ListingDetailsForm'
import ListingRealtorForm from '@/components/fragments/listing/ListingRealtorForm'
import ListingReportingData from '@/components/fragments/listing/ListingReportingData'
import ListingRentalDataForm from '@/components/fragments/listing/ListingRentalDataForm'
import ListingValidationData from '@/components/fragments/listing/ListingValidationData'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'

import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const savedListingQuery = useSavedDataTableRouteState('listings')
const politicalAreaId = computed(() => route.params.location)
const listingId = computed(() => route.params.listing)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Listings',
	to: {
		name: 'listings',
		query: savedListingQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getListing',
	id: listingId.value,
	field: 'title',
}])
</script>