<template>
	<div class="city-form">
		<base-text-field v-model="name" label="Name*" />
		<base-text-field v-model="address" label="Address" />
		<base-text-field v-model="phone" label="Phone Number" />
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

const REQUIRED_FIELDS = ['name']

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
	politicalArea: {
		type: String,
	}
})
const emit = defineEmits(['create', 'save'])

// Modules
const api = useApi()

// Data
const name = ref(null)
const address = ref(null)
const phone = ref(null)

const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({ name, address, phone }, REQUIRED_FIELDS)

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createBrokerage')
		.arg('input', {
			...form,
			politicalAreaId: props.politicalArea,
		})
		.fields('_id')
	
	const result = await request.exec()
	const { success, data } = result.get('createBrokerage')
	if (success) {
		emit('create', data._id)
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateBrokerage')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('name', 'address', 'phone')
	
	const result = await request.exec()
	const { success, data } = result.get('updateBrokerage')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getBrokerage')
		.arg('id', id)
		.fields('name', 'address', 'phone')

	const result = await request.exec()
	const { data } = result.get('getBrokerage')
	loadOriginalData(data)
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.city-form {
		max-width: 320px;
	}
</style>