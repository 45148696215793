<template>
	<base-card class="mb-4">
		<base-card-text class="pa-0">
			<base-container fluid>
				<base-row>
					<base-col align-self="center">
						<base-breadcrumb :items="props.breadcrumb" />
					</base-col>
					<base-col v-if="props.action || $slots.actions" class="pa-0" align="right">
						<base-button
							v-if="props.action && (!props.permission || can(props.permission))"
							class="ma-0"
							:color="props.color"
							height="46px"
							:to="props.to"
							:loading="props.loading"
							@click="onClick"
						>
							<base-icon v-if="props.actionIcon">mdi-plus-circle-outline</base-icon>
							<span class="ml-2">{{ props.action }}</span>
						</base-button>
						<base-menu v-if="$slots.actions" offset-y nudge-bottom="15">
							<template v-slot:activator="{ on, attrs }">
								<base-button :loading="props.actionsLoading" v-bind="attrs" :color="props.color" small height="46px" style="margin-left: 1px" v-on="on">
									<base-icon>mdi-chevron-down</base-icon>
								</base-button>
							</template>
							<base-list>
								<slot name="actions" />
							</base-list>
						</base-menu>
					</base-col>
				</base-row>
			</base-container>
		</base-card-text>
	</base-card>
</template>

<script setup>
import useSession from '@/features/useSession'

const props = defineProps({
	breadcrumb: {
		type: Array,
		required: true,
	},
	action: {
		type: String,
	},
	actionIcon: {
		type: String,
	},
	permission: {
		type: String,
	},
	to: {
		type: [String, Object],
	},
	loading: {
		type: Boolean,
		default: false,
	},
	actionsLoading: {
		type: Boolean,
		default: false,
	},
	color: {
		type: String,
	},
})

const emit = defineEmits(['click'])

const { can } = useSession()
function onClick(event) {
	emit('click', event)
}
</script>