<template>
	<base-container class="listings-validation-list">
		<base-row>
			<base-col cols="12" v-for="listing in listings" :key="`listing-${listing._id}`" align="center">
				<listing-validation-card
					:listing="listing"
					:representative="props.representative"
					@reject="removeListing"
				/>
			</base-col>
		</base-row>
	</base-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import ListingValidationCard from './ListingValidationCard.vue'

// Props & Emit
const props = defineProps({
	representative: {
		type: String,
		required: true,
	},
})

const emit = defineEmits(['failed'])

// Data
const api = useApi()
const listings = ref([])

// Methods
async function fetchListings() {
	const request = api.graphql()
	request.query('getListingsToValidate').arg('query', {
		representativeId: props.representative,
	}).fields([
		'_id',
		'area',
		'title',
		'featuredImage',
		'bedrooms',
		'bathrooms',
		'propertyType',
		'address',
		'url',
		'occupancyRate',
		'revenue',
		'adr',
	])

	const result = await request.exec()
	const { data, success } = result.get('getListingsToValidate')
	if (!success) {
		emit('failed')
	} else {
		listings.value = data
	}
}

onMounted(() => {
	fetchListings()
})

function removeListing(id) {
	const i = listings.value.findIndex(({ _id }) => id === _id)
	if (i !== -1) {
		listings.value.splice(i, 1)
	}
}
</script>
