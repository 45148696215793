<template>
	<div class="access-key-form">
		<base-text-field v-model="name" label="Name*" />
		<base-autocomplete v-model="userId" label="User*" :loading="loadingUsers" :items="users" />
		<base-button
			block
			color="primary"
			:disabled="props.new ? !isFilled : !hasChanges"
			@click="props.new ? onCreate() : onSaveChanges(props.id)"
		>
			{{ props.new ? 'Create' : 'Save Changes' }}
		</base-button>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'
import useDataSource from '@/features/useDataSource'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
	},
	new: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['create', 'save'])

// Constants
const REQUIRED_FIELDS = ['name', 'userId']

// Modules
const api = useApi()

// Data
const name = ref()
const userId = ref(null)
const { form, changes, isFilled, hasChanges, loadOriginalData } = useFormStates({
	name,
	userId,
}, REQUIRED_FIELDS)

// Computed
const { entries: users, loading: loadingUsers } = useDataSource('getUsers', {
	text: 'username'
})

// Methods
async function onCreate() {
	const request = api.graphql()

	request.mutation('createAccessKey')
		.arg('input', form)
		.fields('_id', 'key', 'secret')
	
	const result = await request.exec()
	const { success, data } = result.get('createAccessKey')
	if (success) {
		emit('create', { _id: data._id, key: data.key, secret: data.secret })
	}
}

async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateAccessKey')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('name', 'userId')
	
	const result = await request.exec()
	const { success, data } = result.get('updateAccessKey')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getAccessKey')
		.arg('id', id)
		.fields('name', 'userId')

	const result = await request.exec()
	const { data } = result.get('getAccessKey')

	loadOriginalData(data)
}

onMounted(async () => {
	if (!props.new) {
		fetchEntry(props.id)
	}
})
</script>

<style scoped>
	.access-key-form {
		max-width: 320px;
	}
</style>