<template>
	<div class="view-brand">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[0]">
			<base-expansion-panel title="Brand Information">
				<brand-form :id="brandId" :political-brand="politicalAreaId" />
			</base-expansion-panel>
			<base-expansion-panel title="Additional images">
				<brand-images-form :id="brandId" :political-brand="politicalAreaId" />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import BrandForm from '@/components/fragments/brand/BrandForm'
import BrandImagesForm from '@/components/fragments/brand/BrandImagesForm'

import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const savedBrandQuery = useSavedDataTableRouteState('brands')
const politicalAreaId = computed(() => route.params.location)
const brandId = computed(() => route.params.brand)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Brands',
	to: {
		name: 'brands',
		query: savedBrandQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getBrand',
	id: brandId.value,
	field: 'name',
}])
</script>