<template>
	<div class="public-custom-rpa">
		<base-container v-if="!loading">
			<base-button class="print-button" color="primary" icon @click="onPrint" large><base-icon>mdi-file-pdf-box</base-icon></base-button>
			<base-button
				class="share-button"
				color="primary"
				icon
				@click="onCopy"
				large
			>
				<base-icon>mdi-share-variant</base-icon>
			</base-button>
			<div class="page">
				<base-row class="rpa-header" no-gutters>
					<base-col
						class="text-center pt-sm-8 pt-0"
						cols="12"
					>
						<img class="brand-logo" :src="analysis.brandLogoUrl" />
					</base-col>
					<base-col
						class="text-center"
						cols="12"
						align-self="center"
					>
						<h1>
							<span class="h1-text">Vacation Rental Projection Analysis</span>
						</h1>
					</base-col>
				</base-row>
				<base-row class="rpa-details">
					<base-col cols="12" sm="5" class="text-center">
						<img class="analysis-featured-img" :src="analysis.featuredImageUrl" style="width: 80%" />
					</base-col>
					<base-col cols="12" sm="7" align-self="center">
						<h2 class="analysis-title text-center text-sm-left">{{analysis.title}}</h2>
						<h3 class="analysis-subtitle text-center text-sm-left">{{analysis.subtitle}}</h3>
						<div class="analysis-divider"></div>
						<base-row class="analysis-detail-wrapper">
							<base-col cols="12" class="pb-1 analysis-detail analysis-bedrooms">
								<base-icon color="grey" class="mr-2">mdi-bed</base-icon>Bedrooms: <b>{{ analysis.bedrooms || 'Studio' }}</b>
							</base-col>
							<base-col cols="12" class="pt-1 analysis-detail analysis-bathrooms">
							<base-icon color="grey" class="mr-2">mdi-bathtub</base-icon>Bathrooms: <b>{{analysis.bathrooms}}</b>
							</base-col>
						</base-row>
					</base-col>
				</base-row>
				<div class="text-center mt-4">
					<img :src="require('@/assets/logo.png')" />
				</div>
				<div class="text-center mb-4">
					<div class="section-title primary--text">Annual Revenue Forecast</div>
				</div>
				<base-row class="rpa-rental-data" no-gutters justify="center" align-content="center">
					<base-col cols="12" sm="3" align-self="center" class="pr-0 pr-sm-2">
						<div class="analysis-data-container analysis-data-border-yellow">
							<div class="analysis-data-heading">Low Revenue</div>
							<div class="analysis-data-value">${{(analysis.revenueLow || analysis.revenue).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}}</div>
						</div>
					</base-col>
					<base-col cols="12" sm="4" class="pr-0 pr-sm-2">
						<div class="analysis-data-container-highlight analysis-data-border-green">
							<div class="analysis-data-value ">${{analysis.revenue.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}}</div>
						</div>
					</base-col>
					<base-col cols="12" sm="3" align-self="center">
						<div class="analysis-data-container analysis-data-border-blue">
							<div class="analysis-data-heading">High Revenue</div>
							<div class="analysis-data-value">${{(analysis.revenueHigh || analysis.revenue).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}}</div>
						</div>
					</base-col>
				</base-row>
				<base-row class="rpa-rental-data">
					<base-col class="analysis-monthly-metric text-center">
						<img :src="`https://www.marketmath.com/rental-analysis/custom-analysis/none/${props.id}/revenue-forecast.png`" />
					</base-col>
				</base-row>
				<div class="analysis-divider mx-auto"></div>
				<base-row class="rpa-contact pt-4 pb-2">
					<base-col cols="12" sm="6" class="text-left">
							<div class="rpa-contact-icon-wrapper mr-2">
								<base-icon large color="primary">mdi-phone-message</base-icon>
							</div>
							<div class="rpa-contact-info-wrapper">
								<div class="rpa-contact-name">{{ analysis.contactName }}</div>
								<div class="rpa-contact-phone">{{ analysis.contactPhone }}</div>
							</div>
					</base-col>
					<base-col cols="12" sm="6" class="text-left text-sm-right" align-self="center">
						<div class="rpa-contact-email">
							<base-chip color="primary px-4">{{ analysis.contactEmail }}</base-chip>
						</div>
					</base-col>
				</base-row>
				<div class="footnote py-2">We base our revenue projections on the historic performance of comparable units in the market. These projections
assume normal market conditions, 365 days of availability for the property, and complete control over our dynamic pricing strategy</div>
			</div>
			<div class="page">
				<base-row class="rpa-header" no-gutters>
					<base-col
						class="text-center pt-sm-8 pt-0"
						cols="12"
					>
						<img class="brand-logo" :src="analysis.brandLogoUrl" />
					</base-col>
					<base-col
						class="text-center"
						cols="12"
						align-self="center"
					>
						<h2 class="analysis-title text-center">{{analysis.title}}</h2>
						<h3 class="analysis-subtitle text-center">{{analysis.subtitle}}</h3>
					</base-col>
				</base-row>
				<base-row>
					<base-col cols="12" sm="6" class="analysis-monthly-metric text-center">
						<div class="analysis-monthly-metric-value adr">${{analysis.adr.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}}</div>
						<h2>Average Daily Rate Forecast</h2>
						<img :src="`https://www.marketmath.com/rental-analysis/custom-analysis/none/${props.id}/adr-forecast.png`" width="80%" />
					</base-col>
					<base-col cols="12" sm="6" class="analysis-monthly-metric text-center">
						<div class="analysis-monthly-metric-value occ">{{Math.round(analysis.occupancyRate)}}%</div>
						<h2>Occupancy Forecast</h2>
						<img :src="`https://www.marketmath.com/rental-analysis/custom-analysis/none/${props.id}/occ-forecast.png`" width="80%" />
					</base-col>
				</base-row>
				<div class="text-center mt-4">
					<img :src="require('@/assets/logo.png')" />
				</div>
				<div class="text-center mb-4">
					<div class="section-title primary--text">Data Partners</div>
				</div>
				<div class="text-center">
					<img :src="analysis.brandDataProvidersUrl" width="80%" />
				</div>
				<div class="analysis-divider mx-auto"></div>
				<base-row class="rpa-contact pt-4 pb-2">
					<base-col cols="12" sm="6" class="text-left">
							<div class="rpa-contact-icon-wrapper mr-2">
								<base-icon large color="primary">mdi-phone-message</base-icon>
							</div>
							<div class="rpa-contact-info-wrapper">
								<div class="rpa-contact-name">{{ analysis.contactName }}</div>
								<div class="rpa-contact-phone">{{ analysis.contactPhone }}</div>
							</div>
					</base-col>
					<base-col cols="12" sm="6" class="text-left text-sm-right" align-self="center">
						<div class="rpa-contact-email">
							<base-chip color="primary px-4">{{ analysis.contactEmail }}</base-chip>
						</div>
					</base-col>
				</base-row>
				<div class="footnote py-2">We base our revenue projections on the historic performance of comparable units in the market. These projections
assume normal market conditions, 365 days of availability for the property, and complete control over our dynamic pricing strategy</div>
			</div>
		</base-container>
		<base-snackbar v-model="contentCopied" clearable top>
			URL copied to clipboard!
		</base-snackbar>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

// Props & Emit
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})

const emit = defineEmits(['failed'])

// Data
const api = useApi()
const analysis = ref()
const loading = ref(true)
const contentCopied = ref(false)

// Methods
async function fetchAnalysis() {
	const request = api.graphql()
	request.query('getPublicCustomRentalProjectionAnalysis').arg('id', props.id).fields([
		'title',
		'subtitle',
		'brand',
		'brandLogoUrl',
		'brandDataProvidersUrl',
		'contactName',
		'contactPhone',
		'contactEmail',
		'city',
		'featuredImageUrl',
		'bedrooms',
		'bathrooms',
		'occupancy',
		'occupancyRate',
		'revenue',
		'revenueLow',
		'revenueHigh',
		'adr',
		'politicalAreaCode',
	])

	const result = await request.exec()
	const { data, success } = result.get('getPublicCustomRentalProjectionAnalysis')
	if (!success) {
		emit('failed')
	} else {
		analysis.value = data
	}

	loading.value = false
}

onMounted(() => {
	fetchAnalysis()
})

function onPrint() {
	window.print()
}

async function onCopy() {
	await navigator.clipboard.writeText(window.location.href)
	contentCopied.value = true
}
</script>

<style scoped>
	.public-custom-rpa { 
    -webkit-print-color-adjust:exact !important;
		print-color-adjust:exact !important;
	}

	.public-custom-rpa >>> .base-container {
		max-width: 1024px;
		margin: 0 auto;
	}

	.page {
		background: white;
		margin-bottom: 20px;
	}

	.rpa-header {
		background-color: #ededef;
		padding-bottom: 30px;
	}

	.rpa-details, .rpa-rental-data, .footnote, .rpa-contact {
		padding-left: 50px;
		padding-right: 50px;
	}

	.page .rpa-details {
		margin-top: -40px;
	}

	.section-title {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 500;
		font-family: "Times New Roman", Times, serif;
	}

	.analysis-divider {
		border-top: 1px solid #c1c1c1;
		margin-top: 5px;
		margin-bottom: 5px;
		width: 90%;
	}

	.analysis-detail-wrapper {
    font-size:15px;
  }

	h1 {
		color: #000;
		font-size: 25px;
		text-transform: uppercase;
		font-family: "Times New Roman", Times, serif;
		letter-spacing: 1px;
		padding-bottom: 17px;
	}

	.share-button {
		margin-right: 10px;
	}

	.rpa-subtitle {
		color: 333;
		font-style: italic;
		font-size: 18px;
	}

	.brand-logo {
		max-width: 320px;
		width: calc(100% - 24px);
        border-bottom: 1px solid #333;
	}

	.analysis-title {
		font-size: 20px;
		line-height: 24px;
		font-family: "Times New Roman", Times, serif;
		text-transform: uppercase;
	}

	.rpa-details .analysis-title {
		padding-top: 30px;
	}

	.analysis-subtitle {
		font-weight: normal;
		font-size: 15px;
	}

	.analysis-featured-img {
		max-width: 450px;
		width: calc(100%);
	}

	.analysis-data-container {
		max-width: 320px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		background-color: #e1e1e1;
		print-color-adjust: exact;
	}

	.analysis-data-border-yellow {
		border-left: 4px solid #ffd245;
	}

	.analysis-data-border-blue {
		border-left: 4px solid #5a9bd6;
	}

	.analysis-data-border-green {
		border-left: 4px solid #76c043;
	}

	.analysis-data-container-highlight {
		max-width: 320px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		print-color-adjust: exact;
		background-color: #e1e1e1;
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.analysis-data-heading {
		text-transform: uppercase;
		font-size: 16px;
		color: #666;
		padding-top: 10px;
	}

	.analysis-data-value {
		font-size: 32px;
		color: #666;
		padding-bottom: 10px;
	}

	.analysis-monthly-metric-value {
		font-size: 32px;
	}

	.analysis-monthly-metric-value.adr {
		color: #4372c4;
	}

	.analysis-monthly-metric-value.occ {
		color: #ffc000;
	}

	.footnote {
		text-align: center;
		font-size: 11px;
		color: #999;
		font-style: italic;
		max-width: 80%;
		margin: 0 auto;
	}

	.rpa-contact-name {
		font-size: 18px;
		font-weight: bold;
	}

	.rpa-contact-email:deep(.v-chip__content) {
		font-size: 16px;
	}

	.rpa-contact-icon-wrapper, .rpa-contact-info-wrapper {
		display: inline-block;
	}

	.analysis-data-container-highlight .analysis-data-value {
		padding-bottom: 0;
		font-size: 42px;
		font-weight: bold;
	}

	.rpa-contact-icon-wrapper:deep(.v-icon) {
		margin-top: -25px;
	}

	.analysis-monthly-metric h2 {
		font-weight: 600;
		color: #666;
		margin-bottom: 10px;
		font-family: "Times New Roman", Times, serif;
		text-transform: uppercase;
		font-size: 17px;
	}

	.analysis-monthly-metric img {
		max-width: 100%;
		padding-top: 15px;
	}

	.page-intro-wrapper {
		padding-top: 50px;
	}

	.page-intro h3 {
		text-transform: uppercase;
		font-size: 20px;
		color: #666;
		font-weight: normal;
	}

	.page-intro h2 {
		text-transform: uppercase;
		font-size: 28px;
		color: #666;
		margin-bottom: 10px;
	}

	.page-intro div {
		color: #666;
		font-style: italic;
		font-size: 16px;
	}

	.analysis-section-heading {
		font-size: 24px;
		font-weight: normal;
		color: #666;
		text-transform: uppercase;
		margin-top: 20px;
		margin-bottom: 10px;
		text-align: left;
	}

	.analysis-section-img {
		max-width: 100%;
	}
	

	@media screen and (max-width: 959px) {
		h1 {
			font-size: 24px;
		}

		.page {
			text-align: center;
		}

		h1 span.h1-text {
			display: block;
		}

		.analysis-title {
			font-size: 20px;
			line-height: 24px;
		}
	}

	@media print {
		.page {
			height: 1060px;
			padding: 0;
			margin-bottom: 0;
			margin-top: -22px;
			margin-left: -22px;
			margin-right: -22px;
		}

		.page:last-child {
			height: auto;
		}

		.analysis-monthly-metric img {
			max-height: 230px;
		}

		.print-button, .share-button {
			display: none;
		}

		.public-custom-rpa >>> .v-snack {
			display: none;
		}

		.analysis-monthly-metric h2 {
			font-size: 18px;
		}
		.footnote {
			font-size: 10px;
			color: #777777;
			max-width: 100%;
		}

		@page {
			size: portrait;
			/* No margin, hide the header and footer. */
			margin: 0 !important;
		}
	}
</style>