<template>
	<div class="view-contacts">
		<contacts-header :breadcrumb="breadcrumb" />
		<contacts-table-filters v-model="filters" />
		<contacts-table :refresh.sync="refresh" :page.sync="page" :filters="filters">
			<template v-slot:actions="item">
				<base-button
					v-if="can(`realestate:contact:${item._id}.update`)"
					:to="{
						name: 'contact',
						params: { contact: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="can(`realestate:contact:${item._id}.delete`)"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, `${item.firstName} ${item.lastName}`)"
				>Delete</base-button>
			</template>
		</contacts-table>
		<contact-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useDataTableRouteState from '@/features/useDataTableRouteState'
import useSession from '@/features/useSession'

import ContactsHeader from '@/components/fragments/contact/ContactsHeader.vue'
import ContactsTable from '@/components/fragments/contact/ContactsTable.vue'
import ContactsTableFilters from '@/components/fragments/contact/ContactsTableFilters.vue'
import ContactDeletionModal from '@/components/fragments/contact/ContactDeletionModal.vue'

// Data
const { page, filters } = useDataTableRouteState()
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

// Computed
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Contacts',
}])

// Methods
const { can } = useSession()
function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}
</script>
