import { ref, watch } from 'vue'

import { useStore } from '@/plugins/store'
import { useRoute, useRouter } from '@/plugins/router'

export default function () {
	// Plugins
	const store = useStore()
	const route = useRoute()
	const router = useRouter()

	// Data
	const { page: pageQuery, ...rest } = route.query
	const page = ref(pageQuery ? parseInt(pageQuery, 10) : undefined)
	const filters = ref(rest)

	// Watchers
	watch(filters, (value) => {
		store.dispatch('routeState/setQuery', { name: route.name, query: value })
		router.push({ query: value })
	}, {
		deep: true,
	})
	watch(page, (value) => {
		const query = {
			...(value > 1 ? { page: value } : {}),
			...filters.value,
		}

		store.dispatch('routeState/setQuery', { name: route.name, query })
		router.push({ query })
	})

	store.dispatch('routeState/setQuery', { name: route.name, query: {
		...(page.value && page.value > 1 ? { page: page.value } : {}),
		...filters.value,
	}})

	return {
		page,
		filters,
	}
}