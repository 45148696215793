<template>
	<div class="view-city">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<base-expansion-panels multiple :value="[0]">
			<base-expansion-panel title="Basic Information">
				<city-form :id="cityId" />
			</base-expansion-panel>
			<base-expansion-panel title="Seasonality">
				<city-seasonality-form :id="cityId" />
			</base-expansion-panel>
			<base-expansion-panel title="Market Images">
				<city-images-form :id="cityId" />
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import CityForm from '@/components/fragments/city/CityForm'
import CityImagesForm from '@/components/fragments/city/CityImagesForm'
import CitySeasonalityForm from '@/components/fragments/city/CitySeasonalityForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const politicalAreaId = computed(() => route.params.location)
const cityId = computed(() => route.params.city)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Cities',
	to: {
		name: 'cities',
	},
}, {
	api: 'marketmath',
	fn: 'getCity',
	id: cityId.value,
	field: 'name',
}])
</script>