<template>
	<v-radio v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners" />
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>