<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.twoFactor`]="{ item }">
			<div v-if="item.twoFactor.enabled" class="success--text">Yes</div>
			<div v-else class="error--text">No</div>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Username',
	value: 'username',
}, {
	text: 'First Name',
	value: 'firstName',
}, {
	text: 'Last Name',
	value: 'lastName',
}, {
	text: 'Role',
	value: 'getRole.name',
}, {
	text: '2FA',
	value: 'twoFactor',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'username',
	headers,
	filters,
	getFn: 'getUsers',
	getCb: (query) => {
		query.child('getRole').fields('name')
		query.child('twoFactor').fields('enabled')
	},
	countFn: 'countUsers',
	fields: ['_id', 'username', 'firstName', 'lastName'],
}))

</script>

<script>
export default {
	inheritAttrs: false
}
</script>
