import { render, staticRenderFns } from "./CustomRpaRentalDataForm.vue?vue&type=template&id=68527ef4&scoped=true&"
import script from "./CustomRpaRentalDataForm.vue?vue&type=script&setup=true&lang=js&"
export * from "./CustomRpaRentalDataForm.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CustomRpaRentalDataForm.vue?vue&type=style&index=0&id=68527ef4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68527ef4",
  null
  
)

export default component.exports