<template>
	<div class="view-user">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<user-form :id="userId" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import UserForm from '@/components/fragments/user/UserForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute } from '@/plugins/router'

// Modules
const route = useRoute()

// Computed
const userId = computed(() => route.params.user)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Admin',
}, {
	text: 'Users',
	to: {
		name: 'users',
	},
}, {
	api: 'marketmath',
	fn: 'getUser',
	id: userId.value,
	field: 'username',
}])
</script>