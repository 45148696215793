<template>
	<base-card class="listing-validation-card" max-width="1200">
		<base-card-text>
			<base-row>
				<base-col cols="12" md="4" class="pa-0 pa-sm-4">
					<v-img :src="listing.featuredImage" :alt="`${listing.title} Featured Image`" />
				</base-col>
				<base-col cols="12" md="8" align="left">
					<base-row>
						<base-col cols="12" md="8">
							<a v-if="$vuetify.breakpoint.xs" :href="listing.url" target="_blank">
								<h2 class="text-subtitle-2 primary--text">
									{{listing.title}}
									<base-icon size="20" color="primary" class="ml-1">mdi-open-in-new</base-icon>
								</h2>
							</a>
							<h2 v-else class="primary--text">
								{{listing.title}}
							</h2>
							<div>{{listing.area}}</div>
						</base-col>
						<base-col cols="12" md="4" class="text-right d-none d-sm-block">
							<base-button
								:href="listing.url"
								target="_blank"
								color="primary"
								text
							>
								View MLS Listing
							</base-button>
						</base-col>
					</base-row>
					<div class="mt-4">
						<b>Bedrooms:&nbsp;</b>
						<span>{{listing.bedrooms}}</span>
					</div>
					<div>
						<b>Property Type:&nbsp;</b>
						<span>{{listing.propertyType}}</span>
					</div>
					<div>
						<b>Address:&nbsp;</b> 
						<span>{{listing.address}}</span>
					</div>
					<v-divider class="my-2 my-sm-4" />
					<base-row>
						<base-col class="pt-4 pb-1 pb-sm-4" cols="12" sm="4" align-self="end">
							<div class="mb-2"><b>Estimated Gross Annual Rental Revenue:</b></div>
							<base-text-field
								v-model="revenue"
								:label="revenuePlaceholder"
								hide-details
								v-mask="currencyMask"
							/>
						</base-col>
						<base-col class="py-1 py-sm-4" cols="6" sm="4" align-self="end">
							<div class="mb-2"><b>Occupancy rate:</b></div>
							<base-text-field
								v-model="occupancyRate"
								:label="occupancyPlaceholder"
								hide-details
								v-mask="percentMask"
							/>
						</base-col>
						<base-col class="py-1 py-sm-4" cols="6" sm="4" align-self="end">
							<div class="mb-2"><b>ADR:</b></div>
							<base-text-field v-model="adr" :label="adrPlaceholder" hide-details disabled />
						</base-col>
					</base-row>
					<div class="text-right mt-sm-8 mt-4">
						<base-button
							v-if="!validated"
							class="mr-4"
							color="secondary"
							:loading="skipDialog"
							:disabled="loading"
							@click="skipDialog = true"
						>Skip</base-button>
						<base-button
							v-if="!validated"
							color="primary"
							:loading="loading"
							:disabled="skipDialog"
							@click="onValidate">
								Validate
						</base-button>
						<div class="success--text validated-text" v-else>Validated!</div>
					</div>
					<base-modal
						color="secondary"
						v-model="skipDialog"
						title="Skip Listing"
						:actions="[{
							text: 'Cancel',
							value: 'cancel',
						}, {
							color: 'secondary',
							text: 'Confirm',
							value: 'submit',
						}]"
						@click="onSubmitSkip"
					>
						<div>
							Are you sure you want to skip <b>{{ listing.title }}</b>?
						</div>
					</base-modal>
				</base-col>
			</base-row>
		</base-card-text>
	</base-card>
</template>

<script setup>
import { ref, toRef, computed } from 'vue'

import { useApi } from '@/plugins/api'

import useMask from '@/features/useMask'

// Props
const api = useApi()
const props = defineProps({
	representative: {
		type: String,
		required: true,
	},
	listing: {
		type: Object,
		required: true,
	}
})
const emit = defineEmits(['reject'])

const listing = toRef(props, 'listing')
const revenue = ref()
const occupancyRate = ref()
const complexId = ref()
const loading = ref(false)
const skipDialog = ref(false)
const validated = ref(false)

// Computed
const revenuePlaceholder = computed(() => `$${listing.value.revenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`)
const adrPlaceholder = computed(() => `$${listing.value.adr.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`)
const occupancyPlaceholder = computed(() => `${listing.value.occupancyRate.toFixed(2)}%`)
const adr = computed(() => {
	if (occupancyRate.value || revenue.value) {
		const { occupancyRate: adrOccupancy, revenue: adrRevenue } = getInputData()
		const adrDays = Math.ceil(365 * (adrOccupancy / 100))
		const computedAdr = parseFloat((adrRevenue / adrDays).toFixed(2))
		return `$${computedAdr.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`
	}
	return null
})

function getInputData() {
	const { revenue: defaultRevenue, occupancyRate: defaultOccupancyRate } = listing.value
	return {
		representativeId: props.representative,
		revenue: revenue.value && revenue.value !== ''
			? parseInt(revenue.value.replace(/[^0-9.]+/g, ''), 10)
			: defaultRevenue,
		occupancyRate: occupancyRate.value && occupancyRate.value !== ''
			? parseFloat(occupancyRate.value.replace(/[^0-9.]+/g, ''))
			: defaultOccupancyRate,
		complexId: complexId.value,
	}
}

// Function
const currencyMask = useMask('currency')
const percentMask = useMask('percentage')

async function onValidate() {
	loading.value = true
	const input = getInputData()
	const request = api.graphql()
	request.mutation('addListingValidationReport')
		.arg('id', listing.value._id)
		.arg('input', input)
		.fields('success')

	const result = await request.exec()
	const { success } = result.get('addListingValidationReport')
	if (success) {
		validated.value = true
	}
	loading.value = false
}

async function onReject(reason) {
	const request = api.graphql()
	request.mutation('rejectListingValidation')
		.arg('id', listing.value._id)
		.arg('input', {
			representativeId: props.representative,
			reason,
		})
		.fields('success')
	const result = await request.exec()
	const { success } = result.get('rejectListingValidation')
	if (success) {
		emit('reject', listing.value._id)
	}
	skipDialog.value = false
}

function onSubmitSkip(value) {
	if (value === 'submit') {
		onReject('Skipped')
	} else {
		skipDialog.value = false
	}
}
</script>

<style scoped>
	.validated-text {
		padding: 0px 16px;
		text-transform: uppercase;
	}
</style>