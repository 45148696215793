<template>
	<base-card>
		<base-card-text>
			<base-row no-gutters>
				<v-spacer />
				<base-col cols="12" sm="6" md="4" align="right">
					<base-text-field
						v-model="searchInput"
						no-borders
						prepend-inner-icon="mdi-magnify"
						hide-details
						placeholder="Search"
						clearable
						width="400"
						@click.stop
						@keyup.space.prevent
					/>
				</base-col>
			</base-row>
		</base-card-text>
	</base-card>
</template>

<script setup>
import { computed, ref, reactive, watch } from 'vue'

import useFilters from '@/features/useFilters'
import useDebounce from '@/features/useDebounce'

// Props & Emits
const props = defineProps({
	value: {
		type: Object,
		default: () => {},
	},
})
const emit = defineEmits(['input'])

// Data
const searchInput = ref(props.value.search)
const search = ref(props.value.search)
const form = reactive({
	search,
})

// Computed
const filters = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

const { onApply } = useFilters(form, filters)

// Watchers
watch(searchInput, (value) => {
	onSearch(value)
})

// Methods
const onSearch = useDebounce((value) => {
	search.value = value
	onApply()
}, 500)
</script>