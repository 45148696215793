<template>
	<div
		:class="wrapperClasses"
		:style="wrapperStyle"
		@click="showUpload = true"
		@dragenter.stop="draggingWithin = true"
		@dragleave.stop="draggingWithin = false"
		@drop="onDrop($event.dataTransfer.files)"
	>
		<fieldset class="base-image-upload-fieldset">
			<legend class="base-image-upload-label">{{props.label}}</legend>
		</fieldset>
		<base-file-reader ref="reader" :show.sync="showUpload" :files.sync="dropFiles" type="image" @load="onUpload" />
		<img v-if="image" class="base-image-upload" :src="image" />
		<img v-else-if="props.src" class="base-image-upload" :src="props.src" />
		<div v-else class="base-image-upload-placeholder">
			<base-icon>mdi-image</base-icon>
			<div>Click Or Drop Image To Upload</div>
		</div>
		<div class="base-image-upload-overlay"></div>
		<div class="base-image-upload-droppable">
			<base-icon v-if="draggingWithin" color="primary">mdi-check-circle</base-icon>
			<base-icon v-else>mdi-download</base-icon>
			<div>Drop Here</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'

// Props & Emits
const props = defineProps({
	width: {
		type: [String, Number],
		required: true,
	},
	height: {
		type: [String, Number],
		required: true,
	},
	label: {
		type: String,
	},
	src: {
		type: String,
	},
	value: {
		type: String,
	},
	resize: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(['input'])

// Data
const showUpload = ref(false)
const dragging = ref(false)
const draggingWithin = ref(false)
const reader = ref()
const dropFiles = ref([])

// Computed
const wrapperStyle = computed(() => ({
	width: typeof props.width === 'number' || /^[0-9]+$/.test(props.width) ? `${props.width}px` : props.width,
	height: typeof props.height === 'number' || /^[0-9]+$/.test(props.height) ? `${props.height}px` : props.height,
}))

const wrapperClasses = computed(() => ({
	'base-image-upload-wrapper': true,
	'filled': !!image.value || !!props.src,
	'droppable': dragging.value,
	'dragging-within': draggingWithin.value,
}))

const image = computed({
	get: () => props.value,
	set: (value) => emit('input', value),
})

// Methods
function resize(value) {
	const mimetype = value.replace(/^data:/, '').split(';')[0]
	const imageElement = new Image()
	const canvasElement = document.createElement('canvas')

	imageElement.onload = () => {
		/*
		let { width, height } = image

		if (width > height) {
			if (width > props.width) {
				height *= props.width / width
				width = props.width
			}
		} else if (height > props.height) {
			width *= props.height / height
			height = props.height
		} */

		canvasElement.width = props.width
		canvasElement.height = props.height
		canvasElement.getContext('2d').drawImage(imageElement, 0, 0, props.width, props.height)

		const dataUrl = canvasElement.toDataURL(mimetype)
		image.value = dataUrl
		canvasElement.remove()
	}
	imageElement.src = value
}

function onUpload(value) {
	if (props.resize) {
		resize(value)
	} else {
		image.value = value
	}
}

function onDrop(files) {
	if (!files.length) { return }
	dropFiles.value = files
}

const dragenter = () => {
	if (dragging.value === true) { return }
	dragging.value = true
}
const dragleave = (event) => {
	if (!event.clientX && !event.clientY) {
		dragging.value = false
	}
}

const dragstop = (event) => {
	event.preventDefault()
	dragging.value = false
	draggingWithin.value = false
}

const preventDefault = function(event) {
	event.preventDefault()
}

onMounted(() => {
	window.addEventListener('dragenter', dragenter)
	window.addEventListener('dragleave', dragleave)
	window.addEventListener('drop', dragstop)
	window.addEventListener("dragover", preventDefault)
})

onUnmounted(() => {
	window.removeEventListener('dragenter', dragenter)
	window.removeEventListener('dragleave', dragleave)
	window.removeEventListener('drop', dragstop)
	window.removeEventListener('dragover', preventDefault)
})
</script>

<style scoped>
	.base-image-upload-wrapper {
		cursor: pointer;
		position: relative;
		margin-bottom: 20px;
		max-width: 100%;
	}

	.base-image-upload-wrapper.droppable * {
		pointer-events: none;
	}

	.base-image-upload-fieldset {
		position: absolute;
		border-collapse: collapse;
		border-style: solid;
		border-color: rgba(0, 0, 0, 0.3);
		border-width: 1px;
		padding-top: 10px;
		transition: border-color 0.15s;
		width: 100%;
		height: 100%;
	}

	.base-image-upload-wrapper:hover .base-image-upload-fieldset {
		border-color: rgba(0, 0, 0);
	}

	.base-image-upload-wrapper.droppable .base-image-upload-fieldset, .base-image-upload-wrapper.droppable .base-image-upload-overlay {
		border-width: 2px;
		border-style: dashed;
	}

	.base-image-upload-wrapper.droppable.dragging-within .base-image-upload-fieldset, .base-image-upload-wrapper.droppable.dragging-within .base-image-upload-overlay {
		border-color: var(--v-primary-base);
	}

	.base-image-upload-label {
		margin-left: 12px;
		font-size: 12px;
		padding-left: 2px;
		padding-right: 6px;
		display: block;
		color: rgba(0, 0, 0, 0.6);
		transition: top 0.15s, left 0.15s;
	}

	.base-image-upload-wrapper.droppable.dragging-within .base-image-upload-label {
		color: var(--v-primary-base);
	}

	.base-image-upload-wrapper.filled .base-image-upload-label {
		position: absolute;
		top: -18px;
		left: -12px;
	}

	.base-image-upload {
		width: 100%;
		height: 100%;
	}

	.base-image-upload-placeholder, .base-image-upload-droppable {
		text-align: center;
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		margin-top: -15px;
		height: 40px;
		font-size: 10px;
		right: 0;
	}

	.base-image-upload-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);

		border-style: solid;
		border-color: rgba(0, 0, 0, 0.3);
		border-width: 1px;
	}

	.base-image-upload-droppable, .base-image-upload-overlay {
		display: none;
	}

	.base-image-upload-wrapper.droppable .base-image-upload-placeholder {
		display: none;
	}

	.base-image-upload-wrapper.droppable .base-image-upload-droppable {
		display: block;
	}

	.base-image-upload-wrapper.droppable.filled .base-image-upload-overlay {
		display: block;
	}

	.base-image-upload-wrapper.droppable.dragging-within .base-image-upload-droppable {
		color: var(--v-primary-base);
	}

	.base-image-upload-wrapper.droppable.dragging-within .base-image-upload-overlay {
		background-color: rgba(255, 255, 255, 0.9);
	}
</style>