<template>
	<div class="listing-report-data">
		<base-data-table
			:headers="headers"
			:items="items"
			:items-per-page="25"
			:loading="loading"
		>
		</base-data-table>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})

// Variables & Data
const headers = [{
	text: 'Representative',
	value: 'name',
	width: '40%'
}, {
	text: 'Revenue',
	value: 'revenue',
	width: '20%'
}, {
	text: 'Occupancy rate',
	value: 'occupancyRate',
	width: '20%'
}, {
	text: 'ADR',
	value: 'adr',
	width: '20%'
}]

const api = useApi()
const validation = ref([])
const loading = ref(true)

// Computed
const items = computed(() => validation.value.map((report) => ({
	name: report.name,
	revenue: `$${report.revenue}`,
	occupancyRate: `${report.occupancyRate.toFixed(2)}%`,
	adr: `$${report.adr.toFixed(2)}`,
})))

// Methods
async function fetchValidations() {
	loading.value = true
	const request = api.graphql()
	request.query('getListing').arg('id', props.id)
		.child('validation')
		.fields('name', 'revenue', 'occupancyRate', 'adr')
	const result = await request.exec()
	const { data } = result.get('getListing')
	if (data) {
		validation.value = data.validation
	}
	loading.value = false
}

onMounted(() => {
	fetchValidations()
})
</script>
