<template>
	<span class="base-input base-text-field">
		<v-text-field
			:style="inputStyle" v-bind="{ ...$attrs, ...defaultAttrs }"
			v-on="$listeners"
		/>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	noBorders: {
		type: Boolean,
		default: false,
	},
	width: {
		type: [String, Number],
	}
})

const defaultAttrs = computed(() => ({
	outlined: !props.noBorders,
	dense: true,
}))
const inputStyle = computed(() => ({
	...(props.width ? { 'max-width': `${props.width}px` } : {}),
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>