<template>
	<div class="view-new-brand">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<contact-form new  @create="onCreated" />
	</div>
</template>

<script setup>

import ContactForm from '@/components/fragments/contact/ContactForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRouter } from '@/plugins/router'

// Modules
const router = useRouter()

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Contacts',
	to: { name: 'contacts' },
}, {
	text: 'New Contact',
}])

function onCreated() {
	router.push({ name: 'contacts' })
}
</script>