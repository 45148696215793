<template>
	<div class="view-listings">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<listings-legend class="mb-4" />
		<market-listings-table :market="marketId" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import ListingsLegend from '@/components/fragments/listing/ListingsLegend.vue'
import MarketListingsTable from '@/components/fragments/market/MarketListingsTable.vue'

// Plugins
const route = useRoute()

// Computed
const marketId = computed(() => route.params.market)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Markets',
	to: { name: 'markets' },
}, {
	api: 'marketmath',
	fn: 'getMarket',
	id: marketId.value,
	field: 'name',
}, {
	text: 'Listings',
}])

</script>
