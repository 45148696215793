<template>
	<div class="view-complexes">
		<listing-notes-header :breadcrumb="breadcrumb" />
		<listing-notes-table :listing="listingId" :refresh.sync="refresh" :page.sync="page" :filters="filters" />
	</div>
</template>

<script setup>
import { computed, ref } from 'vue'

import { useRoute } from '@/plugins/router'
import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import useDataTableRouteState from '@/features/useDataTableRouteState'
import useSavedDataTableRouteState from '@/features/useSavedDataTableRouteState'

import ListingNotesHeader from '@/components/fragments/listingNote/ListingNotesHeader.vue'
import ListingNotesTable from '@/components/fragments/listingNote/ListingNotesTable.vue'

// Plugins
const route = useRoute()

// Data
const { page, filters } = useDataTableRouteState()
const refresh = ref(false)

// Computed
const savedListingQuery = useSavedDataTableRouteState('listings')
const politicalAreaId = computed(() => route.params.location)
const listingId = computed(() => route.params.listing)

const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Locations',
	to: { name: 'locations' },
}, {
	api: 'marketmath',
	fn: 'getPoliticalArea',
	id: politicalAreaId.value,
	field: 'name',
}, {
	text: 'Listings',
	to: {
		name: 'listings',
		query: savedListingQuery.value,
	},
}, {
	api: 'marketmath',
	fn: 'getListing',
	id: listingId.value,
	field: 'title',
}, {
	text: 'Notes',
}])
</script>
