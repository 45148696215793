<template>
	<generic-app-header
		:breadcrumb="props.breadcrumb"
		color="primary"
		action="Add Brokerage"
		action-icon="mdi-plus-circle-outline"
		permission="realestate:brokerage:*.create"
		:actions-loading="importing"
		:to="{ name: 'newBrokerage' }"
	>
		<template v-slot:actions>
			<!--<base-list-item
				v-if="can('realestate:brokerage:*.create')"
				prepend-icon="mdi-import"
				@click="showFileImport = true"
			>Import</base-list-item>-->
			<base-list-item prepend-icon="mdi-export" @click="onExport">Export</base-list-item>
			<base-file-reader :show.sync="showFileImport" type="json" @load="onImport" />
		</template>
	</generic-app-header>
</template>

<script setup>
import { ref } from 'vue'

import GenericAppHeader from '../GenericAppHeader.vue'

import useExport from '@/features/useExport'
import useImport from '@/features/useImport'
// import useSession from '@/features/useSession'

// props & Emits
const props = defineProps({
	breadcrumb: {
		type: Array,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['import'])

// Data
const importing = ref(false)
const showFileImport = ref(false)

// Methods
// const { can } = useSession()
const exportBrokerages = useExport({
	fn: 'getBrokerages',
	fields: ['_id', 'name', 'address', 'phone', 'aliases'],
})
const importBrokerages = useImport({
	fn: 'createBrokerage',
	fields: ['name', 'address', 'phone', 'aliases'],
	map: (input) => ({
		...input,
		politicalAreaId: props.politicalArea,
	}),
})

function onExport() {
	exportBrokerages('brokerages', {
		filters: {
			politicalAreaId: props.politicalArea,
		}
	})
}

async function onImport(data) {
	importing.value = true
	const dataMap = data.filter(({ name }) => name !== '').reduce((acc, { name, ...rest }) => ({ ...acc, [name]: rest }), {})
	const filteredData = Object.keys(dataMap).reduce((acc, name) => [...acc, { name, ...dataMap[name] }], [])
	const result = await importBrokerages(filteredData)
	importing.value = false
	emit('import', result)
}
</script>