<template>
	<div class="view-users">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
						<base-col v-if="can('auth:user:*.create')" class="pa-0" align="right">
							<base-button
								class="ma-0"
								color="primary"
								:to="{ name: 'newUser' }"
								height="46px"
							>
								<base-icon>mdi-plus-circle-outline</base-icon>
								<span class="ml-2">Add User</span>
							</base-button>
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<users-table :filters="filters" :page.sync="page" :refresh.sync="refresh">
			<template v-slot:actions="item">
				<base-button
					v-if="can(`auth:user:${item._id}.update`)"
					:to="{
						name: 'user',
						params: { user: item._id }
					}"
					color="primary"
					text
					small
				>Edit</base-button>
				<base-button
					v-if="can(`auth:user:${item._id}.delete`)"
					color="error"
					text
					small
					:loading="deletionDialog.show && deletionDialog.id === item._id"
					@click="showDeletionDialog(item._id, item.username)"
				>Delete</base-button>
			</template>
		</users-table>
		<user-deletion-modal
			:text="deletionDialog.text"
			:id="deletionDialog.id"
			v-model="deletionDialog.show"
			@delete="refresh = true"
		/>
	</div>
</template>

<script setup>
import { reactive, ref} from 'vue'

import UsersTable from '@/components/fragments/user/UsersTable.vue'
import UserDeletionModal from '@/components/fragments/user/UserDeletionModal.vue'

import useSession from '@/features/useSession'
import useDataTableRouteState from '@/features/useDataTableRouteState'

// Data
const breadcrumb = [{
	text: 'Admin',
}, {
	text: 'Users',
}]

const { page, filters } = useDataTableRouteState()
const refresh = ref(false)
const deletionDialog = reactive({
	show: ref(false),
	id: ref(null),
	text: ref(''),
})

// Methods
const { can } = useSession()

function showDeletionDialog(id, text) {
	deletionDialog.id = id
	deletionDialog.text = text
	deletionDialog.show = true
}

</script>
