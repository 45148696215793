<template>
	<div class="custom-rpa-rental-data-form">
		<base-container>
			<base-row>
				<base-col class="pl-0">
					<base-text-field v-model="revenue" label="Revenue" />
				</base-col>
				<base-col>
					<base-text-field v-model="occupancyRate" label="Occupancy Rate" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="adr" label="ADR" disabled />
				</base-col>
			</base-row>
			<base-row>
			<base-col class="pl-0">
					<base-text-field v-model="revenueLow" label="Revenue Low" />
				</base-col>
				<base-col class="pr-0">
					<base-text-field v-model="revenueHigh" label="Revenue High" />
				</base-col>
			</base-row>
		</base-container>
		<div class="button-wrapper">
			<base-button
				block
				color="primary"
				:disabled="!hasChanges"
				@click="onSaveChanges(props.id)"
			>
				Save Changes
			</base-button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'

import { useApi } from '@/plugins/api'

import useFormStates from '@/features/useFormStates'

// Props & Emits
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
})
const emit = defineEmits(['save'])

// Modules
const api = useApi()

// Data
const price = ref(null)
const revenue = ref(null)
const revenueHigh = ref(null)
const revenueLow = ref(null)
const occupancyRate = ref(null)
const adr = ref(null)

const { changes, hasChanges, loadOriginalData } = useFormStates({
	price: computed({
		get: () => price.value && parseInt(price.value, 10),
		set: (value) => price.value = value,
	}),
	revenue: computed({
		get: () => revenue.value && parseInt(revenue.value, 10),
		set: (value) => revenue.value = value,
	}),
	revenueHigh: computed({
		get: () => revenueHigh.value && parseInt(revenueHigh.value, 10),
		set: (value) => revenueHigh.value = value,
	}),
	revenueLow: computed({
		get: () => revenueLow.value && parseInt(revenueLow.value, 10),
		set: (value) => revenueLow.value = value,
	}),
	occupancyRate: computed({
		get: () => occupancyRate.value && parseFloat(occupancyRate.value),
		set: (value) => occupancyRate.value = value,
	}),
	adr: computed({
		get: () => adr.value && parseInt(adr.value, 10),
		set: (value) => adr.value = value,
	}),
})

// Watchers
watch(revenue, (value) => {
	updateAdr(value, occupancyRate.value)
})

watch(occupancyRate, (value) => {
	updateAdr(revenue.value, value)
})

// Methods
async function onSaveChanges(id) {
	const request = api.graphql()

	request.mutation('updateCustomRentalProjectionAnalysis')
		.arg('id', id)
		.arg('input', changes.value)
		.fields('revenue', 'revenueHigh', 'revenueLow', 'occupancyRate', 'adr')
	
	const result = await request.exec()
	const { success, data } = result.get('updateCustomRentalProjectionAnalysis')
	if (success) {
		loadOriginalData(data)
		emit('save', data)
	}
}

async function fetchEntry(id) {
	const request = api.graphql()
	request.query('getCustomRentalProjectionAnalysis')
		.arg('id', id)
		.fields('revenue', 'revenueHigh', 'revenueLow', 'occupancyRate', 'adr')

	const result = await request.exec()
	const { data } = result.get('getCustomRentalProjectionAnalysis')
	loadOriginalData(data)
}

function updateAdr(revenueValue, occupancyValue) {
	if (occupancyRate.value || revenue.value) {
		const adrDays = Math.ceil(365 * (occupancyValue / 100))
		adr.value = Math.round(parseFloat((revenueValue / adrDays)))
	} else {
		adr.value = 0
	}
}

onMounted(async () => {
	fetchEntry(props.id)
})
</script>

<style scoped>
	.custom-rpa-rental-data-form {
		max-width: 600px;
	}

	.button-wrapper {
		max-width: 320px;
	}
</style>