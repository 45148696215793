<template>
	<span class="base-menu">
		<v-menu v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
				<slot :name="scopedSlotName" v-bind="slotData" />
			</template>
			<template v-for="(_, slotName) in $slots" #[slotName]>
				<slot :name="slotName" />
			</template>
		</v-menu>
	</span>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	tile: true,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>







