<template>
	<div class="base-container">
		<v-container v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-container>
	</div>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>