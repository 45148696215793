export default function(fn, delay) {
	let timeout

	function debounce(...args) {
		if (timeout) {
			clearTimeout(timeout)
		}
	
		timeout = setTimeout(() => {
			fn(...args)
		}, delay)
	}

	return debounce
}