<template>
	<div class="public-custom-rental-projection-analysis">
		<public-custom-rpa :id="rpaId" @failed="notFound" />
	</div>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount } from 'vue'

import { useRouter, useRoute } from '@/plugins/router'

import PublicCustomRpa from '@/components/fragments/customRPA/PublicCustomRpa'

// Data
const router = useRouter()
const route = useRoute()

// Computed
const rpaId = computed(() => route.params.rpa)

function notFound() {
	router.replace('/404')
}

onMounted(() => {
	document.body.classList.add('public')
	if (!rpaId.value) {
		notFound()
	}
})

onBeforeUnmount(() => {
	document.body.classList.remove('public')
})
</script>