<template>
	<base-data-table
		:headers="headers"
		:items="items"
		:items-per-page="25"
		:loading="loading"
		:options.sync="options"
		fixed-footer
		:server-items-length="total"
	>
		<template v-slot:[`item.status`]="{ item }">
			<div :title="statusMap[item.status].text">
				<base-icon :color="statusMap[item.status].color">{{ statusMap[item.status].icon }}</base-icon>
			</div>
		</template>
		<template v-slot:[`item.address`]="{ item }">
			<div>{{item.address}}</div>
			<div v-if="item.getComplex">{{item.getComplex.name}}</div>
			<div v-else class="warning--text"><i>No Complex Assigned</i></div>
		</template>
		<template v-slot:[`item.area`]="{ item }">
			<div v-if="item.getArea">
				<div>{{item.getArea.name}}</div>
				<div>{{item.getArea.getCity.name}}</div>
			</div>
			<div v-else class="error--text">No Area assigned</div>
		</template>
		<template v-slot:[`item.source`]="{ item }">
			<div>{{ item.source }}</div>
			<a :href="item.url" target="_blank">{{item.externalId}}</a>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</base-data-table>
</template>

<script setup>
import { computed, watch } from 'vue'
import useDataTableGraphQLRequest from '@/features/useDataTableGraphQLRequest'

const props = defineProps({
	market: {
		type: String,
		required: true,
	},
})
const emit = defineEmits(['update:refresh'])

// Variables
const headers = [{
	text: '',
	value: 'status',
	sortable: false,
	width: '60px',
}, {
	text: 'Address/Complex',
	value: 'address',
}, {
	text: 'Area/City',
	value: 'area',
}, {
	text: 'Source',
	value: 'source',
}, {
	text: 'Alerts',
	value: 'alerts',
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

const statusMap = {
	new: {
		text: 'New',
		icon: 'mdi-new-box',
		color: 'purple',
	},
	inprogress: {
		text: 'In Progress',
		icon: 'mdi-dots-horizontal-circle',
		color: 'purple',
	},
	validated: {
		text: 'Validated',
		icon: 'mdi-check-circle',
		color: 'success',
	},
	sent: {
		text: 'Sent',
		icon: 'mdi-send',
		color: 'success',
	},
	discarded: {
		text: 'Discarded',
		icon: 'mdi-cancel',
		color: 'error',
	},
}

// Data
const { items, loading, options, total, refreshData } = useDataTableGraphQLRequest({
	parentQuery: (request) => request.query('getMarket').arg('id', props.market),
	parentResult: (result, fn) => {
		const { data } = result.get('getMarket')
		return { data: data[fn] }
	},
	getFn: 'getListings',
	getCb: (query) => {
		query.child('alerts').fields('type', 'message')
		query.child('getArea').fields('name').child('getCity').fields('name')
		query.child('getComplex').fields('name')
	},
	countFn: 'countListings',
	fields: ['_id', 'status', 'address', 'title', 'source', 'externalId', 'url'],
	filters: computed(() => ({
		sort: ['-_id'],
		status: ['inprogress', 'validated', 'sent'],
	}))
})

// Computed
const needsRefresh = computed(() => props.refresh)

// Watchers
watch(needsRefresh, (value, oldValue) => {
	if (value !== oldValue && value === true) {
		refreshData(true)
		emit('update:refresh', false)
	}
})
</script>
