<template>
	<div class="base-card">
		<v-card v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
			<slot />
		</v-card>
	</div>
</template>

<script setup>
import { computed } from 'vue'

const defaultAttrs = computed(() => ({
	tile: true,
	elevation: 0,
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>