<template>
	<generic-app-header
		:breadcrumb="props.breadcrumb"
		color="primary"
		action="Add Realtor"
		action-icon="mdi-plus-circle-outline"
		permission="realestate:realtor:*.create"
		:actions-loading="importing"
		:to="{ name: 'newRealtor' }"
	>
		<template v-slot:actions>
			<!--<base-list-item
				v-if="can('realestate:realtor:*.create')"
				prepend-icon="mdi-import"
				@click="showFileImport = true"
			>Import</base-list-item>-->
			<base-list-item prepend-icon="mdi-export" @click="onExport">Export</base-list-item>
			<base-file-reader :show.sync="showFileImport" type="json" @load="onImport" />
		</template>
	</generic-app-header>
</template>

<script setup>
import { ref } from 'vue'

import { useApi } from '@/plugins/api'

import GenericAppHeader from '../GenericAppHeader.vue'

import useExport from '@/features/useExport'
import useImport from '@/features/useImport'
// import useSession from '@/features/useSession'

// props & Emits
const props = defineProps({
	breadcrumb: {
		type: Array,
		required: true,
	},
	politicalArea: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['import'])

// Data
const api = useApi()
const importing = ref(false)
const showFileImport = ref(false)
const FIELDS = ['name', 'phone', 'officePhone', 'email', 'aliases', 'alternateEmails', 'yearsOfExperience', 'avatar', 'subscribed', 'brokerageId']

// Methods
// const { can } = useSession()
async function getBrokeragesMapByNames(names) {
	if (!names.length) { return {} }
	const request = api.graphql()

	request.query('getBrokerages').arg('query', {
		name: Object.keys(names.reduce((acc, name) => ({ ...acc, [name]: true}), {})),
		politicalAreaId: props.politicalArea,
		limit: names.length,
	}).fields('_id', 'name')
	const result = await request.exec()
	const { data = [] } = result.get('getBrokerages')
	
	return data.reduce((acc, value) => ({
		...acc,
		[value.name]: value._id
	}), {})
}

const exportRealtors = useExport({
	fn: 'getRealtors',
	fields: ['_id', ...FIELDS],
	format: 'csv',
})

const importRealtors = useImport({
	fn: 'createRealtor',
	fields: [...FIELDS, 'legacyId'],
	map: (input) => ({
		...input,
		politicalAreaId: props.politicalArea,
	}),
	transform: async (inputs) => {
		const filteredInputs = inputs.filter(({ brokerageName }) => brokerageName !== '')
		if (!filteredInputs.length) { return [] }
		const brokeragesMap = await getBrokeragesMapByNames(filteredInputs.map(({ brokerageName }) => brokerageName))
		return filteredInputs.map((input) =>({
			...input,
			brokerageId: brokeragesMap[input.brokerageName],
		})).filter(({ brokerageId }) => brokerageId)
	}
})

function onExport() {
	exportRealtors('realtors', {
		filters: {
			politicalAreaId: props.politicalArea,
		}
	})
}

async function onImport(data) {
	importing.value = true
	const result = await importRealtors(data)
	importing.value = false
	emit('import', result)
}
</script>