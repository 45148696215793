<template>
	<div class="listings-sent-filters">
		<base-expansion-panels>
			<base-expansion-panel class="white">
				<template v-slot:header>
					<base-row no-gutters>
						<base-col cols="6" md="8" align-self="center">Filters</base-col>
						<base-col cols="6" md="4" align="right">
							<base-text-field
								v-model="searchInput"
								no-borders
								prepend-inner-icon="mdi-magnify"
								hide-details
								placeholder="Search"
								clearable
								width="400"
								@click.stop
								@keyup.space.prevent
							/>
						</base-col>
					</base-row>
				</template>
				<base-card>
					<base-card-text>
						<base-row>
							<base-col cols="12" sm="4">
								<base-autocomplete
									v-model="areaId"
									:items="sortedAreas"
									:loading="loadingAreas"
									label="Area"
									clearable
								/>
							</base-col>
						</base-row>
					</base-card-text>
					<base-card-actions>
						<base-button color="primary" :disabled="!hasChanges" @click="onApply">Apply</base-button>
					</base-card-actions>
				</base-card>
			</base-expansion-panel>
		</base-expansion-panels>
	</div>
</template>

<script setup>
import { watch, computed, ref, reactive } from 'vue'

import useDataSource from '@/features/useDataSource'
import useFilters from '@/features/useFilters'
import useDebounce from '@/features/useDebounce'

// Props & Emits
const props = defineProps({
	value: {
		type: Object,
		default: () => {},
	},
	representative: {
		type: String,
		required: true,
	}
})
const emit = defineEmits(['input'])

// Data
const searchInput = ref(props.value.search)
const search = ref(props.value.search)
const areaId = ref(props.value.areaId)
const form = reactive({
	areaId,
	search,
})

const { entries: areas, loading: loadingAreas } = useDataSource('getAreasForValidationListing', {
	query: {
		representativeId: props.representative,
	},
	usePagination: false,
})

// Computed
const filters = computed({
	get: () => props.value,
	set: (value) => emit('input', value)
})

const sortedAreas = computed(() => {
	// eslint-disable-next-line vue/no-side-effects-in-computed-properties
	return areas.value.sort((a, b) => a.text.localeCompare(b.text))
})

// Watchers
watch(searchInput, (value) => {
	onSearch(value)
})

const { hasChanges, onApply } = useFilters(form, filters)

// Methods
const onSearch = useDebounce((value) => {
	search.value = value
	onApply()
}, 500)
</script>

<style scoped>
	.listings-sent-filters {
		max-width: 1200px;
		margin: 0 auto;
	}
</style>