<template>
	<div class="view-new-custom-rpa">
		<base-card class="mb-4">
			<base-card-text class="pa-0">
				<base-container fluid>
					<base-row>
						<base-col align-self="center">
							<base-breadcrumb :items="breadcrumb" />
						</base-col>
					</base-row>
				</base-container>
			</base-card-text>
		</base-card>
		<custom-rpa-form new :political-area="politicalAreaId" @create="onCreated" />
	</div>
</template>

<script setup>
import { computed } from 'vue'

import CustomRpaForm from '@/components/fragments/customRPA/CustomRpaForm'

import useDynamicBreadcrumb from '@/features/useDynamicBreadcrumb'
import { useRoute, useRouter } from '@/plugins/router'

// Modules
const route = useRoute()
const router = useRouter()

// Computed
const politicalAreaId = computed(() => route.params.location)

// Data
const { breadcrumb } = useDynamicBreadcrumb([{
	text: 'Custom Rental Projection Analyses',
	to: {
		name: 'customRPAs',
	},
}, {
	text: 'New Custom Rental Projection Analysis',
}])

function onCreated() {
	router.push({ name: 'customRPAs' })
}
</script>