import VuexPersistence from 'vuex-persist'

export default new VuexPersistence({
	key: 'session',
	storage: window.localStorage,
	reducer: currentState => ({
		session: {
			accessToken: currentState.session.accessToken,
			accessExpires: currentState.session.accessExpires,
		},
	}),
}).plugin
