import GraphQL from './graphql'
import Rest from './rest'
import Repository from './repository'

export default class ApiService {
	#repositories = {}	// Api repositories

	/**
	 * Register new API
	 * @param {string} name - API name
	 * @param {string} baseUrl - Base URL
	 * @param {object} [options] - Options 
	 * @param {boolean} [options.isDefault=false] - Whether to use this API if no one is provided for GraphQL or Restful calls 
	 * @param {string} [options.graphql] - GraphQL endpoint
	 * @returns {ApiService} - This
	 */
	register(name, baseUrl, { isDefault = false, graphql } = {}) {
		const repository = new Repository(baseUrl, { graphql })
		this.#repositories[name] = repository
		if (isDefault) {
			this.#repositories.default = repository
		}

		return this
	}

	/**
	 * Set headers to API
	 * @param {string} name - Name of the API
	 * @param {string} headers - Headers to pass on the repository
	 */
	setHeaders(name, headers) {
		const repository = this.#repositories[name]
		if (!repository) {
			throw new Error(`API plugin [authorize]: API ${name} not registered`)
		}

		Object.keys(headers).forEach((header) => {
			const value = headers[header]
			repository.setDefaultHeader(header, value)
		})
	}

	/**
	 * Return new Rest builder instance
	 * @param {string} [name] - API name
	 * @returns {Rest} - Rest instance
	 */
	rest(name) {
		const repository = this.#repositories[name || 'default']
		if (!repository) {
			throw new Error(`API plugin [graphql]: API ${name} not registered`)
		}

		return new Rest(repository)
	}

	/**
	 * Return new GraphQL builder instance
	 * @param {string} [name] - API name
	 * @returns {GraphQL} - GraphQL instance
	 */
	graphql(name) {
		const repository = this.#repositories[name || 'default']
		if (!repository) {
			throw new Error(`API plugin [graphql]: API ${name} not registered`)
		}

		return new GraphQL(repository)
	}
}
