<template>
	<generic-data-table v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<template v-slot:[`item.logoUrl`]="{ item }">
			<a :href="item.logoUrl" target="_blank">
				<img width="320" height="80" :src="item.logoUrl" />
			</a>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<slot name="actions" v-bind="item" />
		</template>
	</generic-data-table>
</template>

<script setup>
import { computed } from 'vue'

import GenericDataTable from '../GenericDataTable.vue'

const props = defineProps({
	politicalArea: {
		type: String,
		required: true,
	},
	filters: {
		type: Object,
		default: () => {},
	},
})

// Variables
const headers = [{
	text: 'Name',
	value: 'name',
}, {
	text: 'Email',
	value: 'email',
}, {
	text: 'Phone number',
	value: 'phone',
}, {
	text: 'Logo URL',
	value: 'logoUrl',
	sortable: false,
}, {
	text: 'Actions',
	value: 'actions',
	align: 'right',
}]

// Computed
const filters = computed(() => ({
	politicalAreaId: props.politicalArea,
	...props.filters,
}))

const defaultAttrs = computed(() => ({
	sortBy: 'name',
	headers,
	filters,
	getFn: 'getBrands',
	countFn: 'countBrands',
	fields: ['_id', 'name', 'email', 'phone', 'logoUrl'],
}))
</script>

<script>
export default {
	inheritAttrs: false
}
</script>