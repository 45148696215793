<template>
	<v-snackbar v-model="show" v-bind="{ ...$attrs, ...defaultAttrs }" v-on="$listeners">
		<slot />
		<template v-if="clearable" v-slot:action="{ attrs }">
			<v-btn
				:color="closeColor"
				text
				v-bind="attrs"
				@click="show = false"
			>
				Close
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	value: {
		type: Boolean,
	},
	closeColor: {
		type: String,
		default: 'primary',
	},
	clearable: {
		type: Boolean,
	}
})
const emit = defineEmits('input')

const defaultAttrs = computed(() => ({
	tile: true,
}))
const show = computed({
	get: () => props.value,
	set: (value) => emit('input', value),
})
</script>

<script>
export default {
	inheritAttrs: false
}
</script>